.inside_app_form {
    padding:70px 0;
    border-bottom: 1px solid #A1AEC7;
}
.opn_lst_hd {
    margin-bottom: 20px;
}
h5.opn_lst_ds {
    margin-top: 30px;
    font-size: 25px;
    font-weight: 300;
}
.opn_lst_ds h4 {
    color: #212529;
    margin-top: 30px;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 300;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .inside_app_form {
        padding:50px 0 30px 0;
    }
    
}
