.ae_glance {

    /* background-image: url('../img/glance_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    height: 470px;
    padding-top: 115px;
    padding-bottom: 83px;
}

.glance_hd {
    font-weight: 300;
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 30px;
}

.glance_list {
    width: 100%;
    float: left;
}

.glance_list li {
    color: #fff;
    font-size: 18px;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .glance_list li {
        color: #fff;
        font-size: 16px;
    }

    .ae_glance {

        height: 370px;
        padding-top: 50px;

    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .glance_list {
        width: 100%;
        float: left;
    }

    .glance_list li {
        color: #fff;
        font-size: 16px;
    }

    .ae_glance {

        height: auto;
        padding-top: 50px;

    }


}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .glance_hd {
        font-size: 24px;
        line-height: 37px;
    }
    .ae_glance {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .glance_list {
        width: 100%;
        float: left;
    }

}