.counter {
    background: #101021;
    padding-top:58px;
    padding-bottom: 58px;
}
.count_col {
    width: 100%;
    max-width: 100%;
    height: 115px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    border-left: 3px solid #A1AEC7;
}
.cc_inner {
    height: fit-content;
}
.cc_ttl {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}
.cc_num {
    font-size: 44px;
    line-height: 26px;
    color: #FF7900;
    font-family: 'oms';
    font-weight: 200;
}
.cc_inner p:last-child {
    margin-bottom: 0px;
}

.count_col.cc_1.count_warehouse{
    margin: auto;
}
.count_col.cc_1.count_warehouse_1{
    margin-right: 0;
    margin-left: auto;
}

/* Infra_counter_ware */
.cc_num_1 {
    font-weight: 250;
    font-size: 34px;
    line-height: 34px;
    color: #FF7900;
    font-family: 'oms';
}


/* end -Infra_counter_ware */


/* ----------land transport */

.cc_num_1.infra_num.land_num{

    font-size: 41px;
   
}
/* ------end land transport */



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .cc_num_1.infra_num.land_num {
        font-size: 36px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    
    .count_col { 
        padding-left: 15px;
    }

    .cc_ttl {
        font-size: 16px;
    }
    .cc_num {
        font-size: 40px;
    }

    .cc_ttl.infra_title{
        font-size: 12px;
        line-height: 16px;
    }

    .cc_num_1.infra_num{
        font-size: 30px;
       
    }
    .cc_num_1 {
        font-size: 23px !important;
        line-height: 25px;
    }
   

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .count_col {
        max-width: 300px;
        height: auto;
        margin: auto !important;
        padding-bottom: 20px;
        margin-bottom: 30px !important;
        padding-left:0px;
        border-left: 0px;
        border-bottom: 3px solid #A1AEC7;
    }
    .cc_ttl {
        font-size: 16px;
    }
    .cc_num {
        font-size: 40px;
    }
    .cc_num_1 {
        font-size: 30px !important;
        line-height: 14px;
    }
}



