.home_spotlight {
    width: 100%;
    height: 100vh;
    /* background: #2727A3; */
    /* background: linear-gradient(103.56deg, #9642B3 0.97%, #232394 76.77%); */
    position: relative;
}

.home_spotlight::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/home_spotlight_old1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.text_slider {
    margin: unset;
    width: 100%;
}

.HS_inner {
    max-width: 83.5%;
    width: 100%;
    float: right;
    margin-right: 0;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.home_spotlight_slider {
    position: relative;
    width: 100%;
    height: 100%;
}

.home_spotlight_slider .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 200px 0px;
}

.hm_sld_parallax {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    background-color: #2727A3;
    background-image: url('../img/home_spotlight.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.hs_col1 {
    width: 44%;
    float: left;
    /* background-color: aqua; */
    height: auto;
}

.hm_sld_txt_dv h1 {
    color: #fff;
}

.sldr_crl_bnt .swiper-slide.swiper-slide-active .hm_sld_txt {
    transform: translateY(0%);
}

.sldr_crl_bnt .swiper-slide.swiper-slide-active .fade_animate {
    opacity: 1;
}

.hm_sld_txt {
    transform: translateY(100%);
    transition: all 0.6s;
    transition-delay: 0.6s;
}

.fade_animate {
    opacity: 0;
    transition: opacity 0.8s !important;
    transition-delay: 0.7s !important;
}

.hm_sld_txt_dv p {
    width: 100%;
    max-width: 430px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 40px;
}

.hs_col2 {
    width: 56%;
    /* background-color: antiquewhite; */
    height: auto;
    float: left;
    overflow: hidden;
    position: relative;
}

.hs_col2 .img_slider {
    transform: scale(1.3);
    transition: transform linear 1.4s;
    visibility: hidden;
}

.hs_col2 .img_slider_animate {
    transform: scale(1);
}

.hm_sld_overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101041;
    transform: translateY(101%);
    transition: all 0.5s;
}

.overly_animate1 {
    transform: translateY(0%);
}

.overly_animate2 {
    transform: translateX(101%);
}

.sld_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101041;
    /* background: RED; */
    transform: translateY(101%);
    /* transition: all cubic-bezier(.17, .67, .7, .98) 0.3s; */
    transition: all  0.8s;
    z-index: 1;
}

.bg_animate {
    transform: translateY(0%);
}

.hm_sld_img_dv {
    text-align: end;
}

.sld_img2 {}

.sld_img3 {}

.img_slider {
    position: unset;
    /* perspective:none; */
}

.hm_sld_img_dv img {
    width: 100%;
}

.text_slider .swiper-button-prev {
    left: 295px;
    bottom: 0;
    top: auto;
    background:transparent;
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.text_slider .swiper-button-next {
    left: 370px;
    bottom: 0;
    top: auto;
    background: transparent;
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.text_slider .swiper-slide {
    opacity: 0 !important;
}

.text_slider .swiper-slide.swiper-slide-active {
    opacity: 1 !important;
}

.home_mob_spot {
    display: none;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .HS_inner {
        max-width: 96%;
        padding-top: 70px;
    }

    .hm_sld_txt_dv .spot_heading {
        font-size: 36px;
        line-height: 43px;
    }

    /* .img_slider .swiper-button-prev {
        top: 79.8%;
    }
    .img_slider .swiper-button-next {
        top: 79.8%;
    } */

}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {

    .HS_inner {
        max-width: 96%;
        padding-top: 95px;
    }

    .hm_sld_txt_dv .spot_heading {
        font-size: 38px;
        line-height: 45px;
    }

    .hm_sld_txt_dv p {
        /* margin-top: 52px;
        margin-bottom: 30px; */
    }

    /* .img_slider .swiper-button-prev {
        top: 81.8%;
    }
    .img_slider .swiper-button-next {
        top: 81.8%;
    } */

}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {

    .HS_inner {
        max-width: 94%;
        padding-top: 45px;
    }

    /* .img_slider .swiper-button-prev {
        top: 79.8%;
    }
    .img_slider .swiper-button-next {
        top: 79.8%;
    } */
    .hm_sld_txt_dv .spot_heading {
        font-size: 40px;
        line-height: 50px;
    }


}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {

    .HS_inner {
        max-width: 91%;
    }

    .home_spotlight {
        padding-top: 80px;
    }
    .hm_sld_txt_dv .spot_heading {
        font-size: 45px;
        line-height: 53px;
    }
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {

    .home_spotlight {
        padding-top: 80px;
    }

    .HS_inner {
        max-width: 89%;
    }

    .hm_sld_txt {
        transform: translateY(150px);
    }

    .hm_sld_txt_dv .spot_heading {
        font-size: 45px;
        line-height: 53px;
    }



}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .HS_inner {
        max-width: 95%;
    }

    .hm_sld_txt_dv p {
        margin-top: 50px;
    }

    .text_slider .swiper-button-prev {
        left: 270px;
        right: auto;
        /* top: 295px; */
    }

    .text_slider .swiper-button-next {
        left: 332px;
        right: auto;
        /* top: 295px; */
    }

    .logisticpara{
        padding-right: 100px;
    }
 
    .intersection_head{
        padding-right: 80px;
    }


}

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {

    .home_desktop_spot {
        display: none !important;
    }
    .home_mob_spot {
        display: block;
    }
    .hs_col1 {
        margin-top: 25px;
    }
    
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    /* .home_spotlight {
        padding-top: 150px;
        padding-bottom:80px;
        display: flex;
        align-items: center;
        height:auto;
    }

    .HS_inner {
        max-width: 90%;
        height: auto;
        margin: auto;
    }
    .hs_col1 {
        width:100%;
        height: auto;
        margin-bottom: 30px;
    }
    .hs_col2 {
        width:100%;
        height: auto;
    }
    .hm_sld_txt_dv p {
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .text_slider .swiper-button-prev {
        left: 245px;
        right: auto;
    }
    .text_slider .swiper-button-next {
        left: 310px;
        right: auto;
    } */

    .home_spotlight {
        padding-top: 150px;
        padding-bottom: 80px;
        display: flex;
        align-items: center;
        height: auto;
    }

    .HS_inner {
        max-width: 85%;
        height: auto;
        margin: auto;
        display: block;
    }

    .hs_col1 {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }

    .hs_col2 {
        width: 100%;
        height: auto;
    }

    .hm_sld_txt_dv p {
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .text_slider .swiper-button-prev {
        left: auto;
        right: 60px;
        /* top: 250px; */
    }

    .text_slider .swiper-button-next {
        left: auto;
        /* top: 250px; */
        right: 3px;
    }


}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .home_spotlight {
        padding-top: 50px;
        display: flex;
        align-items: center;
    }

    .HS_inner {
        max-width: 85%;
        height: auto;
        margin: auto;
        display: block;
    }

    .hs_col1 {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }

    .hs_col2 {
        width: 100%;
        height: auto;
    }

    .hm_sld_txt_dv p {
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .text_slider .swiper-button-prev {
        left: auto;
        right: 60px;
        /* top: 250px; */
    }

    .text_slider .swiper-button-next {
        left: auto;
        /* top: 250px; */
        right: 3px;
    }

}