.sector_info {
    background: #fff;
    padding:85px 0px;
    border-bottom: 1px solid #00000030;
}
.sec_info_para {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021 ;
}

/*------------- Apollo Edge Info CSS */
.ae_img_slider .img_scroll_dv {
    margin: 0;
}
.ae_img_slider {
    margin-top: -50px;
}
/*------------- Apollo Edge Info CSS */

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .sec_info_para {
        font-size: 22px;
        line-height: 35px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .sec_info_para {
        font-size: 18px;
        line-height: 31px;
    }

    .sector_info {
        padding: 50px 0px;
    }

}


