.apollo_edge_service {
    padding-top:75px;
    padding-bottom:89px;
    border-bottom: 1px solid #A1AEC7;
}
.aes_main_col {
    text-align: center;
    margin-bottom: 52px;
}
.aes_row2 {
    height: 438px;
    margin: 0;
}
.state_row {
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 40px;
}
.state_edge_hd {
    margin-bottom: 20px;
}
.land_aplo_edge .aes_row2{
    height: 470px;
}
.aes_sec2_row {
    margin-top: 0 !important;
}
.aes_row3 {
    height: 545px;
    margin: 0;
    margin-top: 25px;
}
.land_aplo_edge1 .aes_row3 .aes_col2 {
    display: flex;
    align-items: center;
}
.aes_row2 div , .aes_row3 div{
    height:100%;
}
.aes_col1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 49% 26%;
}
.aes_bg {
    background: #E7EAF0;
}
.aes_row3 .aes_bg ,.aes_row3 .aes_col2{
    background: #101021;
}
.aes_col2 {
    background: #E7EAF0;
    padding:65px;
}
.aes_hd {
    font-weight:250;
    font-size: 24px;
    line-height: 33px;
    color: #101021;
}
.aes_row3 .aes_hd ,.aes_row3 .aes_list li{
    color: #fff;
}
.aes_desc {

}
.aes_list {
    list-style: none;
    padding: 0px;
}
.aes_list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    font-family: 'Red Hat Display';
    color: #101021;
    border-bottom: 1px solid hsl(0deg 0% 85% / 40%);
    padding-top: 10px;

}

.aes_row3 .aes_list li  {
    height: auto;
    padding: 10px 0px;
}
.aes_row2 .aes_list li  {
    height: auto;
    padding: 10px 0px;
}
.aes_list li img {
    margin-right: 13px;
}

.aes_list li:last-child {
    border: none;
  }

.grwth_catalyst {
    display: flex;
    align-items: center;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .aes_col2 {
        padding: 35px;
    }
    .aes_hd {
        font-size: 24px;
        line-height: 30px;
    }
    .aes_list li {
        font-size: 16px;
        line-height: 27px;
    }
    .land_aplo_edge .aes_row2 {
        height: 500px;
    }
    .land_aplo_edge1 .aes_row3 {
        height: 400px;
    }
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .aes_col2 {
        padding: 25px;
    }
    .aes_hd {
        font-size: 18px;
        line-height: 26px;
    }
    .aes_list li {
        font-size: 16px;
        line-height: 27px;
    }
    .aes_main_col {
        margin-bottom: 25px;
    }
    .apollo_edge_service  .aes_row3 {
        margin-top: 0px;
    }
    .land_aplo_edge .aes_row2 {
        height: 500px;
    }
    .land_aplo_edge1 .aes_row3 {
        height: 400px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
   
    .apollo_edge_service {
        padding-top: 50px;
        padding-bottom: 50px;
        
    }
    .aes_main_col {
        margin-bottom: 15px;
    }
    .aes_row2  , .aes_row3 {
        height: auto;
    }
    .land_aplo_edge .aes_row2 {
        height: auto;
    }
    .land_aplo_edge1 .aes_row3 {
        height: auto;
    }
    .apollo_edge_service .aes_row3 {
        margin-top: 0;
    }
    .aes_col2 {
        padding: 30px;
    }
    .aes_hd {
        /* font-size: 22px;
        line-height: 32px; */
        font-size: 17px;
        line-height: 27px;
    }
    .aes_list li {
        font-size: 14px;
        line-height:20px;
    }

    .asc_order{
        order: 2;
    }
    .heading.techo_head.ap_edge_ware_hd {
        text-align: center;
    }
}