/* 
0- Image Reavel CSS
1- Search Input Overlay CSS  
2- Content Width
3- Font Style
4- CTA Button Style
5- Slider Button Style
6- Ul-li icon Style
7- Mobile CSS
8- Tab1 CSS
9- Tab2 CSS
10- Connect Now Button Style & Popup Style
11- Form validation style
*/



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: 'oms';
  src: local('oms_250'), url(./font/oms_250.otf) format('opentype');
  font-weight: 250;
}

@font-face {
  font-family: 'oms';
  src: local('oms_300'), url(./font/oms_300.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'oms';
  src: local('oms_400'), url(./font/oms_400.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'oms';
  src: local('oms_500'), url(./font/oms_500.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'oms';
  src: local('oms_700'), url(./font/oms_700.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'oms';
  src: local('oms_900'), url(./font/oms_900.otf) format('opentype');
  font-weight: 900;
}



/*----------------------------------------- Global CSS Start Here */

/* Image Reavel CSS */
.img_dv {
  position: relative;
  overflow: hidden;
}
.connect_form_msg {
  margin-top: 50px;
}
.img_dv .img_oly {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.blue_oly {
  background-color: #101021 !important;
}

.grd_blue_oly {
  background-color: #2c2192 !important;
}

.grd_blue_oly1 {
  background-color: #6134a5 !important;
}

/* Search Input Overlay CSS */
.search_div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: -2;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: all 0.3s;
}

.srch-box img {
  padding-left: 8px;
}

.srch-box .txt:hover {
  color: rgb(255 255 255 / 60%);
}

input {
  border-radius: 0px;
}

.search_div>.srch-row {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 60%;
}

.search_div .srch-row .col-12 {
  width: 100%;
  background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 55px 48px;
}

.srch_form {
  height: 76px;
  position: relative;
}

.srch_form::before {
  content: '';
  position: absolute;
  left: 30px;
  top: 29px;
  background-image: url('./assets/img/svg/blue_search_icon.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

input.srch-input {
  border: 0px;
  background-color: #fff;
  width: 86%;
  font-size: 25px;
  font-weight: 100;
  height: 100%;
  float: left;
  padding-left: 75px;
  border-radius: 0px;
}

input.srch-input:focus {
  outline: none;
}

input.srch-input::placeholder {
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #101021;
  opacity: 0.4;
}

.srch-btn {
  background-color: #FF7900;
  border: none;
  height: 76px;
  width: 14%;
  float: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #2727A3;
}

.srch-btn img {
  width: 100%;
}

.cls-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 50px;
  top: 50px;
  padding-top: 14px;
  cursor: pointer;
}

span.line1 {
  width: 100%;
  height: 2px;
  background: #2727A3;
  float: left;
  transform: rotate(45deg);
}

span.line2 {
  width: 100%;
  height: 2px;
  background: #2727A3;
  float: left;
  transform: rotate(-45deg);
  margin-top: -2px;

}

/*------------------------------------------------------------------------------------------------- Content Width */
.cstm_container {
  max-width: 1264px;
  margin: auto;
  width: 100%;
  position: relative;
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1280px) {
  .cstm_container {
    padding: 0 40px !important;
  }
}

/*------------------------------------------------------------------------------------------------- Font Style */
.wht_txt {
  color: #fff !important;
}

.txt_cntr {
  text-align: center;
}

.m_0 {
  margin: 0px;
}

.p_0 {
  padding: 0px;
}

.pr_0 {
  padding-right: 0;
}

.pl_0 {
  padding-left: 0 !important;
}

input,
p,
li,
button {
  /* font-family: 'Marcellus', serif; */
  font-family: 'Red Hat Display', sans-serif;
}

h1, h2, h3, h4, h5, h6 , a{
  font-family: 'oms';
}


p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #757575;
}

.hd_dv {
  overflow: hidden;
  width: fit-content;
  height: auto !important;
}

.heading {
  font-weight: 400;
  font-size: 39px;
  line-height: 50px;
  text-transform: capitalize;
  color: #101041;

}

.spot_heading {
  font-weight: 400;
  font-size: 48px;
  line-height: 57.4px;
  text-transform: capitalize;
  color: #101041;
}

.strk_hd {
  font-weight: 700;
  font-size: 168px;
  line-height: 200px;
  text-transform: capitalize;
  border: 1px solid #27276A;
}

/*------------------------------------------------------------------------------------------------- CTA Button Style */
.read_more_btn {
  color: #FF7900 ;
  cursor: pointer;
  position: relative;
  font-weight: 250;
}

/* .read_more_btn::after {
  content: '';
  position: absolute;
  right: -16px;
  top: -1px;
  width: 10px;
  height: 10px;
  background-color: #2727A3;
  mask: url('./assets/img/svg/dropdown_icon.svg') no-repeat center / contain;
  -webkit-mask: url('./assets/img/svg/dropdown_icon.svg') no-repeat center / contain;
  transform: rotate(0deg);
  transition: all 0.8s;
} */

.read_more_btn:hover {
  color: #FF7900 ;
}

.rm_blue {
  color: #2727A3;
  text-decoration: underline;
  font-weight: 250;
}

.rm_blue:hover {
  color: #2727A3;
}

a.btn {
  font-weight: 250;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding: 0;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  text-decoration: none;
  position: relative;
  transition: color 0.7s;
  border-radius: 0;
  float: left;
}

.cnt_col .input_btn span.btn::before {
  height: 99.9%;
}

a.btn::before,
span.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 99.8%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  background-color: #101041;
}

a.btn:hover::before,
span.btn:hover::before {
  transform: scale(1);
  transform-origin: 0 50%;
}

a.btn:hover span.btn_txt {
  color: #FF7900;
}

span.btn_txt {
  padding: 20px;
  display: block;
  float: left;
  transition: color .2s;
  position: relative;
}

span.arrow_btn {
  width: 58px;
  height: 60px;
  display: block;
  float: right;
  position: relative;
}

span.arrow_btn::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 50%;
  width: 24px;
  height: 24px;
  transform: translateX(50%) translateY(-50%);
  background-repeat: no-repeat;
  background-size: contain;

}

span.arrow_btn::before {
  content: '';
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 24px;
  height: 24px;
  transform: translateX(50%) translateY(-50%);
  background-repeat: no-repeat;
  background-size: contain;
}


.down_arrow_btn::after {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-image: url('./assets/img/svg/down_arrow.svg');
}

/*-------------------------------------- Button Before Arrow */
a.btn:hover span.arrow_btn::before,
span.btn:hover span.arrow_btn::before {
  animation-name: before_arrwo_1;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity: 1;
}

a.btn:not( :hover) span.arrow_btn::before,
span.btn:not( :hover) span.arrow_btn::before {
  animation-name: before_arrwo_2;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;

}

@keyframes before_arrwo_1 {

  0% {
    opacity: 0;
    background-position-x: -50px;
  }

  100% {
    opacity: 1;
    background-position-x: 0px;
  }

}

@keyframes before_arrwo_2 {

  0% {
    opacity: 1;
    background-position-x: 0px;
  }

  100% {
    opacity: 0;
    background-position-x: 50px;
  }

}

/*-------------------------------------- Button After Arrow */
a.btn:hover span.arrow_btn::after,
span.btn:hover span.arrow_btn::after {
  animation-name: after_arrwo_1;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  opacity: 0;
}

a.btn:not( :hover) span.arrow_btn::after,
span.btn:not( :hover) span.arrow_btn::after {
  animation-name: after_arrwo_2;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes after_arrwo_1 {

  0% {
    opacity: 1;
    background-position-x: 0px;
  }

  100% {
    opacity: 0;
    background-position-x: 50px;
  }

}

@keyframes after_arrwo_2 {

  0% {
    opacity: 0;
    background-position-x: -50px;
  }

  100% {
    opacity: 1;
    background-position-x: 0px;
  }

}


/*-------------------------------------- Blue Button Code Start */
.blue_btn {
  color: #2727A3;
  border: 1px solid #A1AEC7;
}

.blue_arrow {
  color: #2727A3;
  border-left: 1px solid #A1AEC7;
}

.blue_arrow::after {
  background-image: url('./assets/img/svg/blue_arrow_btn.svg');
}

.blue_arrow::before {
  background-image: url('./assets/img/svg/yellow_right_arrow.svg');
}


/*-------------------------------------- Blue Button Code End */



/*-------------------------------------- White Button Code Start */
.wht_btn {
  color: #FFFFFF;
  border: 1.2px solid rgba(255, 255, 255, 0.2);
}

.wht_arrow {
  border-left: 1.5px solid rgba(255, 255, 255, 0.2);
}

.wht_arrow::after {
  background-image: url('./assets/img/svg/wht_right_arrow.svg');
}

.wht_arrow::before {
  background-image: url('./assets/img/svg/yellow_right_arrow.svg');
}


/*-------------------------------------- White Button Code End */













/*------------------------------------------------------------------------------------------------- Slider Button Style */
.swiper-button-prev,
.swiper-button-next {
  width: 58px !important;
  height: 58px !important;
  border-radius: 50px;
}

/*---------  Slider blue color code */
.sldr_crl_bnt .swiper-button-prev,
.sldr_crl_bnt .swiper-button-next {
  background-color: #101041;
}
.sldr_crl_bnt .swiper-button-prev:hover , .sldr_crl_bnt .swiper-button-next:hover {
  background: rgba(47, 47, 109, 0.4);
}
.sldr_crl_bnt .swiper-button-prev::after {
  content: '';
  background-image: url('./assets/img/svg/wht_left_arrow.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  width: 24px;
  height: 24px;
}

.sldr_crl_bnt .swiper-button-next::after {
  content: '';
  background-image: url('./assets/img/svg/wht_right_arrow.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  width: 24px;
  height: 24px;
}

/*--------- Slider white color code */
.sldr_wht_bnt .swiper-button-prev,
.sldr_wht_bnt .swiper-button-next {
  background-color: transparent;
  border: 1px solid #A1AEC7;
}
.sldr_wht_bnt .swiper-button-prev:hover , .sldr_wht_bnt .swiper-button-next:hover {
  background: rgba(47, 47, 109, 0.4);
}

.sldr_wht_bnt .swiper-button-prev::after {
  content: '';
  background-image: url('./assets/img/svg/crl_left_arrow.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  width: 24px;
  height: 24px;
}

.sldr_wht_bnt .swiper-button-next::after {
  content: '';
  background-image: url('./assets/img/svg/crl_right_arrow.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  width: 24px;
  height: 24px;
}



/*------------------------------------------------------------------------------------------------- Ul-li icon Style */
ul.global_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.global_list li {
  position: relative;
  padding-left: 30px !important;
}

ul.global_list li::before {
  content: '';
  position: absolute;
  top: 19px;
  left: 0;
  background-image: url('./assets/img/svg/green_tick.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}


/*------------------------------------------------------------------------------------------------- Connect Now Button Style & Popup Style*/
.enq_sticky_btn {
  background: #FF7900;
  color: #101021 !important;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  height: 220px !important;
  letter-spacing: .08em;
  line-height: 0;
  line-height: 0px !important;
  padding-right: 0;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  top: 33%;
  transform: rotate(-180deg) !important;
  width: 45px !important;
  writing-mode: tb-rl !important;
  z-index: 1;
}

.enq_sticky_btn:hover {
  text-decoration: none;
}

.enq_sticky_btn img {
  transform: rotate(180deg);
  margin-top: 5px;
  margin-right: 12px;
  width: 22px;
}

.enquire_overlay {
  background: rgba(49, 61, 71, .8);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.enquire_from_div {
  background: rgba(16, 16, 33, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(6px);
  height: 100vh;
  padding: 80px 30px 30px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transform: translateX(101%);
  transform: translateX(101%);
  transition: all .6s;
  width: 30%;
  z-index: 9;
  overflow: auto;
}

select {
  border-radius: 0px;

}
_::-webkit-full-page-media, _:future, :root select{
  -webkit-appearance: none;
  appearance: none;
}

select option {
  color: #000;
}

.enq_close_btn {
  cursor: pointer;
  margin-top: -90px;
  width: 35px;
}

.enquire_from {
  margin-top: 50px;
}

.enquire_from .input_fld {
  background-color: transparent;
  border-bottom: 1px solid rgb(255 255 255 / 34%);
  color: #fff;
  padding-bottom: 5px;
  height: 35px;
}

.enquire_from .input_fld::placeholder {
  color: #fff;
}

.enquire_from .btn_txt {
  color: #fff;
}

/*------------------------------------------------------------------------------------------------- Form validation style */
.frm_inr_dv {
  position: relative;
}

.error_line {
  border-bottom: 1px solid red !important;
}

.show_error {
  display: block !important;
}

.error_msg {
  color: red;
  display: none;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0;
  bottom: -5px;
}

/*----------------------------------------- Global CSS End Here */



/*----------------------------------------- Mobile CSS */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

  .heading {
    font-size: 24px;
    line-height: 37px;
  }
  .error_msg {
    bottom: -12px;
    font-size: 13px;
  }
  .enquire_from {
    margin-top: 30px;
  }
  .enquire_from .input_fld{
    margin-bottom:27px;
  }
  .spot_heading {
    font-size: 25px;
    line-height: 31px;
  }

  p {
    font-size: 14px;
    line-height: 23.6px;
  }

  a.btn {
    font-size: 11px;
    padding: 0px;
  }

  span.btn_txt {
    padding: 10px 15px;
    width: fit-content;
  }

  span.arrow_btn {
    width: 40px;
    height: 40px;
  }
  
  .swiper-button-prev,
  .swiper-button-next {
    width: 43px !important;
    height: 43px !important;
    border-radius: 50px;
  }

  .mob_padding_LR {
    padding-left: 25px !important;
    padding-right: 25px !important;

  }

  ul.global_list li::before {
    top: 13px;
  }


  /*-------------- Search popup css */
  .search_div>.srch-row {
    width: 90%;
  }

  .search_div .srch-row .col-12 {
    padding: 20px;
  }

  .srch_form::before {
    left: 10px;
    background-size: 15px;
    width: 15px;
    height: 15px;
    top: 20px;
  }

  input.srch-input {
    font-size: 15px;
    padding-left: 32px;
    width: 75%;
  }

  input.srch-input::placeholder {
    font-size: 15px;
  }

  .srch-btn {
    font-size: 15px;
    width: 25%;
    height: 55px;
  }

  .srch_form {
    height: 55px;
  }

  .cls-btn {
    right: 23px;
    top: 100px;
  }

  /*-------------- Search popup css */

  .enq_sticky_btn {
    background: #FF7900;
    color: #101021 !important;
    cursor: pointer;
    font-weight: 300;
    letter-spacing: .08em;
    line-height: 0;
    line-height: 0px !important;
    position: fixed;
    right: 0px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transform: rotate(-180deg) !important;
    writing-mode: tb-rl !important;
    z-index: 3;
    font-size: 10px;
    height: 115px !important;
    padding-right: 15px;
    top: 28%;
    width: 30px !important;

  }

  .enq_sticky_btn img {
    width: 19px;
    display: none;
  }

  .enquire_from_div {
    width: 100%;
  }
}

/*----------------------------------------- Tab1 CSS */
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

  p {
    font-size: 15px;
    line-height: 23.6px;
  }

  .search_div>.srch-row {
    width: 70%;
  }

  .search_div .srch-row .col-12 {
    padding: 30px;
  }

  .srch_form::before {
    left: 15px;
  }

  input.srch-input {
    width: 80%;
    padding-left: 50px;
  }

  .srch-btn {
    width: 20%;
  }

  .enq_sticky_btn {
    background: #FF7900;
    color: #101021 !important;
    cursor: pointer;
    font-size: 11px;
    font-weight: 300;
    height: 160px !important;
    letter-spacing: .08em;
    line-height: 0;
    line-height: 0px !important;
    padding-right: 0px;
    position: fixed;
    right: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    top: 33%;
    transform: rotate(-180deg) !important;
    width: 45px !important;
    writing-mode: tb-rl !important;
    z-index: 1;
  }

  .enq_sticky_btn img {
    width: 19px;
  }

}


/*----------------------------------------- Tab2 CSS */
@media only screen and (min-device-width: 768px) and (max-device-width: 1100px) {

  .enquire_from_div {
    width: 45%;
  }

  .heading {
    font-size: 30px;
    line-height: 40px;
  }

  .spot_heading {
    font-size: 31px;
    line-height: 39.4px;
    margin-bottom: 2px;
  }

  .search_div>.srch-row {
    width: 70%;
  }

  .tab_padding_LR {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }


  a.btn {
    font-size: 13px;
    padding: 0px;
  }

  span.btn_txt {
    padding: 15px;
    width: fit-content;
  }

  span.arrow_btn {
    width: 45px;
    height: 50px;
  }


  .swiper-button-prev,
  .swiper-button-next {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50px;
  }

  .sldr_crl_bnt .swiper-button-prev::after,
  .sldr_crl_bnt .swiper-button-next::after {
    background-size: 20px;
  }
}



@media only screen and (min-device-width: 1080px) and (max-device-width: 1366px) {

  .heading {
    font-size: 32px;
  }
  .enquire_from {
      margin-top: 20px;
  }
  .enquire_from_div {
    padding: 40px 30px 30px;
  }
  .enq_close_btn {
    margin-top: -40px;
  }
}

.recap_div {
  float: left;
  width: 100%;
  height: 45px;
}