.info_ware_sec {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}
.ware_info_1 {
    font-size: 22px !important;
}
.info_para_cust {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: -80px;

}

.warehousing_heading {
    padding-top: 23px;
}

.info_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: 30px;

}

.state_tech_content p {
    color: #101021;
}

.state_tech_dv {
    width: 100% !important;
}

.inside_dv_img {
    padding-top: 30px;
}

.info_para_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: 55px;
}


.info_title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36.4px;
    color: #2727A3;
    /* margin-left: 82px; */

}

.ware_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
    padding-bottom: 8px;
}


.ware_sub_paragraph {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
    padding-bottom: 8px;
}

.asc_control {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #757575 !important;

}

/* Supply chain section */

.supply_control {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    color: #757575;
    opacity: 0.8;
}


/* end supply chain */



.warehouse_bdr {
    border-bottom: 0px solid #A1AEC7 !important;
    margin-bottom: 16px;
}

.info_title_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36.4px;
    color: #2727A3;
    padding-top: 8px;

}

.ware_para_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
}

.info_ware_col_one {
    width: 78%;


}

.tech_sub_para {
    color: #101021 !important;
}


/* ----------land transport */

.info_ware_sec.land_trans_sec {
    padding-bottom: 130px;
}


/* -------end land transport */



/* ----------Asc_liquid */

.asc_liquid_sec {
    padding-top: 116px;
    padding-bottom: 116px;
    position: relative;
    /* background-image: url("../img/services/land-transport/asc-liquid/asc_liquid_bg_img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
    width: 100%;
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
}

.liq_p_dv p {
    font-weight: 400;

    color: #FFFFFF;
}

.heading.wht_txt.asc_heading {
    line-height: 18.4px;
}


.asc_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.asc_head {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 25%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.asc_main_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 270%;
    display: flex;
    align-items: center;
    color: #FFFFFF;

}




/* ---------end  Asc_liquid*/

/* --life at asc */

.img_scroll_dv.asc_scroll {
    margin-bottom: -47px !important;
}

.asc_ware_sec {
    padding-top: 50px;
    padding-bottom: 0px;
    position: relative;
}

/* ----end life at asc */


@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .warehouse_info_sec {
        padding-bottom: 50px !important ;
    }
    .cfs_main_sec {
        padding-bottom: 50px !important ;
    }
    .info_ware_sec {
        padding-bottom: 0px;
    }

    .heading.wht_txt.asc_heading {
        line-height: 45px !important;
    }

    .ware_sub_paragraph {

        padding-bottom: 35px !important;
    }


    .supply_control {
        margin-bottom: 50px;
    }

    .asc_liquid_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .asc_lf_p1 {
        margin-bottom: 20px !important;
    }
    .asc_para {
        font-size: 16px;
    }

    .asc_main_para {
        font-size: 20px;
        line-height: 110%;
    }


    .info_ware_col_one {
        width: 100%;
    }

    .info_para_cust {
        font-size: 20px;
        line-height: 30px;

    }

    .info_para {

        font-size: 20px;
        line-height: 30px;

    }

    .info_para_1 {

        font-size: 18px;
        line-height: 25px;

    }

    .info_title {

        font-size: 20px;
        line-height: 25.4px;

    }

    .info_title_1 {

        font-size: 20px;
        line-height: 25.4px;

    }

    .info_para_cust.infra_paras {
        padding-left: 0px;
    }

    .infra_lastpara {
        padding-bottom: 30px;
        margin-top: -20px;
    }


    /* ----------Asc_liquid */

    .asc_head {

        line-height: 200%;

    }

    .asc_main_para {

        line-height: 130%;

    }

    /* --------end-asc liquid */


}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .info_para_1 {

        font-size: 22px;
        line-height: 30px;
    }
    .supply_control {
        font-size: 17px;
        line-height: 147%;
    }

    .info_title {

        font-size: 22px;
        line-height: 25.4px;
    }

    .info_title_1 {

        font-size: 22px;
        line-height: 25.4px;

    }

    .life_at_ascscroll {
        margin-bottom: -85px !important;
    }

}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .asc_liquid_sec {
        padding-top: 50px;
        padding-bottom: 50px;

    }

    .heading.wht_txt.asc_heading {
        line-height: 50.4px;
    }
}



@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .ware_info_1 {
        font-size: 19px !important;
    }

}