.solution_center {
    background: #F4F4F4;
    padding-top: 72px;
    padding-bottom: 150px;
}

.solu_col1 {
    text-align: center;
    margin-bottom: 45px;
}

.st_tab {
    margin-bottom: 0 !important;
}

.st_link {
    width: 50%;
    border-radius: 0px !important;
    border: 0px !important;
    background: #2727A3;
    font-family: 'oms';
    font-weight: 250;
    font-size: 22px;
    text-align: center;
    line-height: 36px;
    color: #FFFFFF;
    transition: all 0.6s;
    -webkit-appearance: initial !important;
    appearance: initial !important;
}

.st_link:hover {
    border: 0px;
    background: #FF7900 !important;
    color: #101021;
}

.st_link.active {
    border-radius: 0px;
    border: 0px;
    background: #FF7900 !important;
    color: #101021 !important;
}

.solu_content_dv {
    position: relative;
}

.solu_content_dv img {
    width: 100%;
}

.full_patch_dv {
    position: absolute;
    padding: 30px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    max-width: 655px;
    width: 100%;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
}

.solu_sub_hd {
    font-weight: 250;
    font-size: 28px;
    line-height: 36px !important;
    color: #101021;
    border-bottom: 2px solid #232394;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'oms';
}

.solu_sub_ttl {
    color: #939393;
    margin-bottom: 0px;
}






@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .solution_center {
      
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .st_link {
        font-size: 19px;
    }
    .solu_sub_hd {
        font-size: 23px;
        line-height: 13px;
    }
    .full_patch_dv {
        padding:25px 20px;
        max-width: 450px;
    }

    .solution_center {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .solution_center {
            padding-bottom: 50px;
            padding-top: 50px;
        }
    
    .st_link {
        width: 100%;
        font-size: 18px;
    }

    .full_patch_dv {
        position: relative;
        right: unset;
        top: unset;
        transform: unset;
        padding: 15px;
        width: 90%;
        margin: auto;
        margin-top: -42px;
    }
    .solu_sub_hd {
        font-size: 20px;
        margin-bottom: 3px;
    }
    .solu_sub_ttl {
        margin-bottom: 5px;
    }
}