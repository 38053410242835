.application_form {
    padding:70px 0;
    text-align: center;
}
.app_form_col {
    margin-top: 50px;
}
.app_frm_btn_col {
    margin-top: 35px;
}
.file_input_col label {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #2727A3;
    width: fit-content;
    text-align: left;
    float: left;
    margin-right: 10px;
}
.file_input_col input {
    width: 70%;
    float: left;
    margin-top: -10px;
}
.app_frm_btn {
    margin-top: 50px;
}