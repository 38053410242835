.blog_featured {
    padding: 70px 0;
    background-color: #F4F4F4;
}

.blg_feat_col {
    text-align: center;
    margin-bottom: 50px;
}

.bf_col {
    height: auto;
    width: 100%;
    max-width: 360px;
    margin: auto;
}

.bf_prt_1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bf_prt_1 {
    width: 100%;
    height: 46%;
}

.bf_prt_2 {
    width: 100%;
    height: 54%;
    padding: 25px 17px;
    background-color: #FFFFFF;
    position: relative;
}
.fb_arrow {
    position: absolute;
    right: 22px;
    bottom: 65px;
    width: 29px;
}

.bf_bx_tag {
    background: #FF7900;
    font-weight: 250;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 5px;
    
}

.bf_bx_ttl {
    font-weight: 250;
    font-size: 20px;
    line-height: 31px;
    color: #101021;
    margin-top: 15px;
    padding-bottom: 10px;
}

.bf_bx_date {
    font-weight: 250;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #101021;
    font-family: 'oms';
}
.blog_ft_bx a:hover {
    text-decoration: none;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .bf_bx_ttl {
        font-size: 18px;
        line-height: 29px;
    }
    .bf_bx_date {
        font-size: 14px;
    }
    .fb_arrow {
        bottom: 78px;
        width: 25px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    
    .bf_col {
        height: auto;
    }
    .life_para {
        font-size: 20px;
        line-height: 32px;
    }
    .bf_prt_1 {
        width: 100%;
        height: auto;
    }
    .bf_prt_2 {
        padding: 20px;
    }
    .bf_bx_tag {
        font-size: 12px;
    }
    .bf_bx_ttl {
        font-size: 15px !important;
        line-height: 22px !important;
    }
    .bf_bx_date {
        font-size: 14px;
        margin-bottom: 0;
    }
    .fb_arrow {
        right: 15px;
        bottom: 20px;
        width: 25px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .bf_col {
        height: auto;
        margin-bottom: 30px;
    }
    .blog_box_content {
        width: 100%;
        padding: 15px;
    }
    .bf_bx_tag {
        font-size: 11px;
    }
    .bf_bx_ttl {
        font-size: 17px;
        line-height: 25px;
    }
    .bf_bx_date {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 0;
    }
    .fb_arrow {
        right: 15px;
        bottom: 20px;
        width: 25px;
    }

    .blog_featured {
        padding-top: 50px !important;
        padding-bottom: 15px !important;
    }
    .blg_feat_col {
        margin-bottom: 20px;
    }
}