.contact_dtls {
    padding:55px 0;
}
.cd_col1 {
    background: #2F2F6D;
    padding:50px 100px 50px 100px;
}
.cd_col2 {
    padding:0px;
}
.map_col  iframe{
    width:100%;
    height:100%;
}
.form_thanks_msg {
    text-align: center;
    font-size: 21px;
    margin-bottom: 0;
    display:none;
}
.thnk_msg_show {
    display: block;
}
.cont_form_bx_hide {
    display: none;
}
.contact_ul {
    list-style:none;
    padding:0px;
}
.contact_ul li{
    margin-bottom: 30px;
}
.contact_ul li span {
    font-weight: 25;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 11px;
    width: 100%;
    float: left;
    font-family: 'oms';
    color: #fff;
}
.contact_ul li a {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 0px;
    font-family: 'Red Hat Display', sans-serif;
}

.contact_ul li a:hover {
    color: #FF7900 !important;
}
.contact_ul li p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 0px;
}
.social_ul {
    list-style:none;
    padding:0px;
    display: flex;
    margin-left: -7px;
    margin-top: 40px;
}
.social_ul li {
    width: 36px;
    height: 36px;
}
.social_ul li a {
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.social_ul li a img {
    
}

.social_ul li a img:hover{
    color: #101041 !important;
}


.cnt_form_row {
    margin-top: 80px !important;
}
.contact_form_dv {
    padding:50px 20px;
    border: 1px solid #A1AEC7;      
}
.contact_form_dv .sb_btn {
    margin: auto;
}
.crm_inner_dv {
    width:100%;
    max-width:670px;
    margin: auto;
    text-align: center;
}
.contact_form_dv .fld_full {
    margin-left: -13px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
   
    .cd_col1 {
        padding: 30px;
    }
    .contact_ul li span {
        font-size: 25px;
        line-height: 30px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .contact_dtls .row {
        margin:0px;
    }
    .map_col iframe {
        height: 350px;
    }
    .cd_col1 {
        padding: 30px;
    }
    .contact_ul li span {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    .contact_ul li p ,.contact_ul li a {
        font-size: 15px;
    }
    .contact_form_dv .sb_btn {
        transform: translateX(-20px);
    }
    .contact_form_dv .mr_rght {
        margin-right: 40px;
    }
}



