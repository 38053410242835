.newsletter_sec {
    background: #101021;
    border-top: 1px solid rgba(75, 75, 75, 0.31);
    border-bottom: 1px solid rgba(75, 75, 75, 0.31);
    padding:40px 0 56px 0;
    position: relative;
}
.newsletter_col1 {
    background: #15153B;
    background-image:url('../img/map.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    padding:110px 100px 65px 45px;
}
.address_dv {

}
.add_p {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 20.3px;
    letter-spacing: 0.02em;
    color: #FFFFFF;    
    margin-bottom: 0px;
}
.cntact_dtl {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top:30px;
    width: fit-content;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    display: block;
}

.address_dv a:hover {
    color: #FF7900 !important;
    border-bottom: 1px solid #FF7900  !important;
    text-decoration: none;
   
    
}




.newsletter_col2{
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding:60px 50px;
    position: relative;
    overflow: hidden;
}
.newsletter_col2:hover .nswltter_vctr{
    transform: scale(1.2);
}
.newsletter_col2 h3 {
    color: #fff;
    font-weight: 300;
}
.newsletter_form {
    position: relative;
}
.sub_err {
    bottom:-27px;
    margin-bottom: 0;
}
.thnk_msg_show {
    display: block;
}
.sub_thank_msg {
    text-align: left !important;
    margin-top: 30px;
    color:#fff;
}
.news_ltr_input {
    border: 1px solid #ffffff33;
    height:53px;
    background-color: transparent;
    padding-left: 22px;
    margin-top: 32px;

    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #eeee;
    width: 40%;
    border-radius: 0px;
}
.news_ltr_input:focus {
    outline: none;
}
.news_ltr_input::placeholder {

    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #ffffff33;
}
.news_ltr_btn {
    width: 103px;
    height: 54.36px;
    background: #D9D9D9;
    border: 1px solid #FFFFFF;
    font-family: 'oms';
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #2727A3;
    border-radius: 0px;
}
.news_ltr_btn:hover {
    background: #f67e1c;
    border: 1px solid #f67e1c;
}
.nswltter_vctr {
    position:absolute;
    right: -35px;
    bottom: -35px;
    transform: scale(0.8);
    transition: all 0.8s;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .newsletter_col1 {
        padding: 90px 100px 60px 25px;
        margin-bottom: 25px;
    }
    .newsletter_col2 {
        padding: 30px 25px;
    }
    .news_ltr_input {
        margin-top: 20px;
        height: 45px;
        width: 67%;
    }
    .news_ltr_btn {
        width: 84px;
        height: 44.36px;
        font-size: 14px;
    }
    .cntact_dtl {
        margin-top: 15px;
    }
    .nswltter_vctr {
        right: -65px;
        bottom: -65px;
        transform: scale(0.5);
    }
    .newsletter_col2:hover .nswltter_vctr {
        transform: scale(0.9);
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1199px) {

    .newsletter_col1 {
        padding: 44px 50px 30px 30px;
    }
    .newsletter_col2 {
        background: #2727A3;
        padding: 30px 30px;
        position: relative;
        overflow: hidden;
    }
    .news_ltr_input {
        width: 65%;
    }
}

@media only screen and (min-device-width: 841px) and (max-device-width: 1199px) { 
    .newsletter_col1 {
        padding: 60px 50px 36px 30px;
    }
}