c.navbar .megamenu {
    padding: 1rem;
}

span.site-desc {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.065em;
    text-transform: capitalize;
    color: #FFF34A;
    display: flex;
}






/* ============ desktop view ============ */
@media all and (min-width: 992px) {

    .navbar .has-megamenu {
        position: static !important;
    }

    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 0;
    }

}

/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px) {

    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }
}

/* ============ mobile view .end// ============ */


.header {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 2;
    transition: all 0.6s;
    transition: top 0.3s;
}
div#desk_header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
#desk_header .hdr_container , #desk_header .navbar {
    height:100%;
}
.header nav.navbar,
.header .megamenu {
    padding: 0 1% !important;
}

.hdr_bg {
    /* background-color: #101041; */
    z-index: 5;
    border-bottom: 0px solid;
    background:rgb(47 47 109 / 90%);
}



.desktop_nav {
    justify-content: flex-end;
}

.hdr_container {
    width: 100%;
    margin: auto;
    /* padding: 0 50px; */
}

.asc_div {
    display: flex;
    /* width: 20%; */
    align-items: center;
}

.header .asc_div img {
    float: left;
    margin-right: 20px;
    transition: all 0.3s;
}

.asc_name {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Red Hat Display';
    margin-bottom: 0;
}

.desktop_nav li {
    display: flex;
    cursor: pointer;
}

.desk_nav_link {
    font-family: 'oms';
    font-weight: 250;
    font-size: 16px;
    line-height: 25.6px;
    color: #FFFFFF !important;
    padding: 0 15px !important;
    align-self: center;
}

.profile_box::after {
    display: none;
}

.search_box,
.profile_box {
    width: 60px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    cursor: pointer;
    float: left;
    transform: scale(1);
    transition: all 0.3s;
}

.profile_box:hover {
    background-color: #FF7900;
}

.profile_box:hover img,
.search_box:hover img {
    filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0%) contrast(98%);
}

.search_box:hover {
    background-color: #FF7900;
}

.search_box img,
.profile_box img {
    width: 21px;
}

.mobile_nav,
#mobile_menu {
    display: none;
}



/*--------------------------- Megamenu Style Start*/
.dropdown-menu.megamenu {
    border-radius: 0px;
    border: 0px solid #0000;
    padding: 0px;
}

.dropdown-menu.megamenu>.row {
    margin: 0px;
}

.dropdown-toggle::after {
    border: none;
    width: 10px;
    height: 10px;
    background-image: url('../img/svg/dropdown_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: -6px;
}

h6.mm_ttl {
    font-weight: 250;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.col-megamenu p {
    color: hsl(0deg 0% 100% / 50%);
}

.megamenu {
    /* height:527px;
    background: rgb(16 16 33 / 92%) !important; */
    height: 0;
    background: transparent;
    transition: all 0.2s;
    display: block !important;
    pointer-events: none;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.col-megamenu .tb_bx {
    width:50%;
}
li.edge_hd_link {
    margin-bottom: 3px !important;
}
ul.edge_sublink {
    padding-left: 0px;
}
ul.edge_sublink a {
    font-size: 18px; 
}
ul.edge_sublink span {
    margin-right: 5px;
}
.mega_animation {
    background: rgb(16 16 33 / 92%) !important;
    pointer-events: all;
    display: block;
}

.mega_colum {
    transition: all 0.4s;
    height: 100%;
    padding: 0px !important;
}
.submenu_animation {
    opacity: 1 !important; 
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important; 
}
.mc_1 .col-megamenu {
    opacity: 0;
    -webkit-transform: translateY(2.2rem);
    transform: translateY(2.2rem);
    transition: 0.6s cubic-bezier(.19, 1, .22, 1);
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

.animi_bx1 {
    transition-delay: .20s !important;
    padding:40px 70px 40px 15px;
}

.mc_2 .col-megamenu {
    opacity: 0;
    -webkit-transform: translateY(2.2rem);
    transform: translateY(2.2rem);
    transition: 0.6s cubic-bezier(.19, 1, .22, 1);
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

.animi_bx2 {
    transition-delay: .25s !important;
    padding: 40px 70px 40px 52px;
}

.mc_3 .col-megamenu {
    opacity: 0;
    -webkit-transform: translateY(2.2rem);
    transform: translateY(2.2rem);
    transition: 0.6s cubic-bezier(.19, 1, .22, 1);
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

.animi_bx3 {
    transition-delay: .30s !important;
    padding: 40px 70px 40px 52px;
}

.megamenu .row {
    height: 100%;
    /* -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(6px); */
}

.mega_col_border {
    border-right: 1px solid rgb(216 216 216 / 20%);
}

.mega_colum {
    /* padding: 50px 30px 50px 30px; */
}

.mc_1 {
    padding: 40px 70px 40px 15px;
}

.mc_2 {
    padding: 40px 70px 40px 52px;
}

.mc_3 {
    padding: 40px 70px 40px 52px;
}

.mega_colum li {
    margin-bottom: 20px;
}

.link_color {
    color: #FF7900;
}

.mega_colum a {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 250;
    font-size: 20px;
    line-height: 27px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.mega_colum a:hover,
.desk_nav_link:hover {
    text-decoration: none;
    color: #FF7900 !important;
}
.mega_colum a:hover.dropdown-toggle::after,
.desk_nav_link:hover.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}
.mega_img {
    width: 100%;
    margin-left: 0%;
}

.login_box {
    float: left;
    width: 340px;
    margin-top: 15px;
}

.login_box .tb_bx {
    background: rgb(39 39 162 / 30%);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.profile_megamenu {
    background: transparent;
}

.profile_mega_animation {
    pointer-events: all;
}

.about_pg a.about_lk {
    color: #FF7900 !important;
}
.about_pg a.about_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}
.about_pg .about_megamenu  .mega_colum a.abt_lk {
    color: #FF7900 !important;
}

.leader_pg a.about_lk {
    color: #FF7900 !important;
}
.leader_pg a.about_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}
.leader_pg .about_megamenu  .mega_colum a.leader_lk {
    color: #FF7900 !important;
}



.about_pg #mob_accordian a.abt_lk {
    color: #FF7900 !important;
}

.srvc_pg a.service_lk {
    color: #FF7900 !important;
}
.srvc_pg a.service_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}

.warehouse_pg .service_megamenu  .mega_colum a.warehouse , .supply_pg .service_megamenu  .mega_colum a.supply_chain , .land_pg .service_megamenu  .mega_colum a.surface_Trans ,
.infra_pg .service_megamenu  .mega_colum a.CFS_ICD , .freight_frwd_pg .service_megamenu  .mega_colum a.freight_Forwd , .controltower_pg .service_megamenu  .mega_colum a.control_tower { 
    color: #FF7900 !important;
}
.warehouse_pg #mob_accordian a.warehouse , .supply_pg #mob_accordian a.supply_chain , .land_pg #mob_accordian a.surface_Trans ,
.infra_pg #mob_accordian a.CFS_ICD , .freight_frwd_pg #mob_accordian a.freight_Forwd , .controltower_pg #mob_accordian a.control_tower { 
    color: #FF7900 !important;
}




.automotive_pg .sector_megamenu  .mega_colum a.automotive_lk , .consumer_pg .sector_megamenu  .mega_colum a.consumer_lk , 
.e_com_pg .sector_megamenu  .mega_colum a.e_com_lk { 
    color: #FF7900 !important;
}
.automotive_pg #mob_accordian a.automotive_lk , .consumer_pg #mob_accordian a.consumer_lk , .e_com_pg #mob_accordian a.e_com_lk { 
    color: #FF7900 !important;
}


.apedge_pg .ascedge_megamenu  .mega_colum a.integ_lk {
    color: #FF7900 !important;
}
.apedge_pg #mob_accordian a.integ_lk {
    color: #FF7900 !important;
}


.blog_pg .media_megamenu  .mega_colum a.blog_lk {
    color: #FF7900 !important;
}
.blog_pg #mob_accordian a.blog_lk {
    color: #FF7900 !important;
}

.event_pg .media_megamenu  .mega_colum a.news_event_lk {
    color: #FF7900 !important;
}
.event_pg #mob_accordian a.news_event_lk {
    color: #FF7900 !important;
}

.life_at_asc_pg .career_megamenu  .mega_colum a.life_asc_lk {
    color: #FF7900 !important;
}
.life_at_asc_pg #mob_accordian a.life_asc_lk {
    color: #FF7900 !important;
}

.curnt_opn_pg .career_megamenu  .mega_colum a.curnt_opn_lk {
    color: #FF7900 !important;
}
.curnt_opn_pg #mob_accordian a.curnt_opn_lk {
    color: #FF7900 !important;
}



.sector_pg a.sector_lk {
    color: #FF7900 !important;
}
.sector_pg a.sector_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}

.apedge_pg a.edge_lk {
    color: #FF7900 !important;
}
.apedge_pg a.edge_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}

.blog_pg a.media_lk {
    color: #FF7900 !important;
}
.blog_pg a.media_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}


.event_pg a.media_lk {
    color: #FF7900 !important;
}
.event_pg a.media_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}

.career_pg a.career_lk {
    color: #FF7900 !important;
}
.career_pg a.career_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}

.contact_pg a.contact_lk {
    color: #FF7900 !important;
}
.contact_pg a.contact_lk.dropdown-toggle::after {
    filter: invert(51%) sepia(66%) saturate(2530%) hue-rotate(359deg) brightness(101%) contrast(108%);
}


/*--------------------------- Megamenu Style END*/


@media only screen and (min-device-width: 320px) and (max-device-width: 1199px) {
    .hdr_container {
        padding: 0;
    }

    .header .asc_div img {
        width: 90px;
    }

    .header nav.navbar,
    .header .megamenu {
        padding: .5rem 1rem !important;
    }

    .header .desktop_nav {
        visibility: hidden !important;
        width: 0px;
    }

    #mobile_menu,
    .mobile_nav {
        display: block;
        order: 3;
        font-family: 'Barlow';
        float: left;
    }

    .header {
        height: 75px;
        z-index: 5;
    }

    .header .navbar {
        padding-bottom: 0 !important;
        padding-top: 0;
    }

    .header .asc_div {
        width: 60%;
    }

    .asc_div img {
        margin-right: 10px;
    }

    .asc_name {
        font-size: 10px;
        line-height: 15px;
    }

    span.site-desc {
        font-size: 11px;
    }

    .profile_box {
        display: none;
    }

    .search_box {
        width: 35px;
        height: 35px;
        margin-left: 0px;
        margin-right: 9px;
    }

    .search_box img {
        width: 15px;
    }

    .mobile_nav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: linear-gradient(291.97deg, #CA6204 -16.32%, #2F2F6D 46.64%);
        top: 0;
        left: 0;
        padding: 30px;
        padding-left: 20px;
        z-index: 9;
        transform: translateX(101%);
        transition: all ease 0.6s;
        display: block;
        overflow: scroll;
    }

    .mobile_nav a.nav-link {
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.03em;
        padding: 0 !important;
    }

    ul.navbar-nav li {
        border-bottom: 1px solid #d6d6d6;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 15px 0;
        transition-delay: 2s;
    }

    ul.dropdown-menu {
        background-color: transparent;
        border: none;
    }

    ul.dropdown-menu li {
        padding: 5px 0;
    }

    ul.navbar-nav li a.dropdown-item {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.05em;
        padding-left: 0px;
    }

    ul.dropdown-menu {
        padding-left: 10px;
    }


    .open_mob_nav {
        transform: translateX(0%);

    }

    img.close_mob_nav {
        float: right;
        width: 30px;
    }


    div#mob_accordian {
        margin-top: 80px;
    }

    #mob_accordian ul {
        padding-left: 0px;
    }

    #mob_accordian li {
        list-style-type: none;
        border-bottom: 1px solid rgb(255 255 255 / 20%);
    }
    li.edge_advntg {
        padding-left: 20px;
    }
    #mob_accordian ul ul li a {
        color: white;
        text-decoration: none;
        font-size: 14px;
        line-height: 27px;
        display: block;
        padding: 0 15px;
        transition: all 0.15s;
        position: relative;
        font-family: 'oms';
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 0px;
    }

    .mobile_nav ul li ul li ul li {
        border-bottom: 1px solid rgb(255 255 255 / 20%) !important;
    }

    .lv_1 {
        font-size: 20px !important;
        line-height: 40px !important;
        font-weight: 300;
    }

    .lv_2 {
        font-size: 15px !important;
        font-weight: 250;
        line-height: 20px !important;
        height: 55px !important;
    }

    .lv_3 {
        font-size: 15px !important;
        line-height: 30px !important;
        font-weight: 200;
    }

    .lv_3ul {
        border-bottom: 0px solid #fff;
        border-left: 0px solid !important;
        padding: 10px 0px;
        margin-bottom: 30px;
    }

    #mob_accordian ul ul li.active>a {
        color: #FF7900;
    }

    #mob_accordian ul ul li.active a:hover {
        color: #FF7900;
    }

    #mob_accordian ul ul {
        display: none;
    }

    #mob_accordian li.active>ul {
        display: block;
    }

    #mob_accordian ul ul ul {
        padding-left: 15px;
        border-left: 1px dotted #101041;
    }

    #mob_accordian li.active {
        border-bottom: 0px;
    }

    #mob_accordian li:last-child {
        border: 0px;
    }

    #mob_accordian a:not(:only-child):after {
        content: "+";
        font-family: 'barlow';
        position: absolute;
        right: 10px;
        top: 8px;
        font-size: 25px;
        font-weight: 300;
    }

    #mob_accordian .active>a:not(:only-child):after {
        content: "-";
    }

    /*------------------------- mobile menu css End*/
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
    .search_box {
        display: none;
    }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .hdr_container {
        padding: 0 0;
    }

    .desk_nav_link {
        font-size: 15px;
    }
    .search_box, .profile_box {
        margin-left: 13px;
    }
    .mega_colum a {
        font-size: 18px;
    }


}