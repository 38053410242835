.lifestyle_other_sec {
    background: #fff;
    border-bottom: 1px solid #00000030;
}
.ls_col1 {
    display: flex;
    align-self: center;
}
.lf_oly {
    background-color: #101021 !important; 
    
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .ls_inner_dv p.apollo_tech_para {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    
}



