
.team_sec {
    padding-top: 80px;
    padding-bottom: 10px;
    border-bottom: 1px solid #A1AEC7;
    position: relative;
    background-color: #fff;
}

.team_row {
    margin-top: 40px;
}

.team_box {
    padding: 0;
    border: 0px;
    margin-bottom: 90px;
    cursor: pointer;
}

.team_box .card-body {
    padding: 0;
    padding-top: 30px;
}

.team_box .card-title {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #414042;
}

.team_box .card-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #414042;
}


/*-------------------------- Team Content Slide Box Start*/
.team_slidebox {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(100%);
    transition: all 0.6s;
    z-index: 99;
    padding: 0px 0px 0 0px;
}

.team_slidebox .row {
    height: 100%;
}

.team_slidebox .row .col-12 {
    height: 100%;
}

.tm_cont_box {
    height: 100%;
    display: none;
}

.tm_cont_box .row {
    margin: 0;
}

.close_tm_box {
    position: absolute;
    right: 60px;
    top: 70px;
    cursor: pointer;
    z-index: 2;
    border: 1px solid #A1AEC7;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
}

.close_tm_box img {
    width: 22px;
}

.tm_full_img_col {
    padding: 0;
    height: 100%;
}

.tm_full_img_col img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.tm_fullcontent_col {
    background-color: #fff;
    padding: 45px 60px 0 60px;
    height: 100%;
}

.tm_name_LB {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #414042;
    margin-bottom: -5px;
}

.tm_designation_LB {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #414042;
}

.tm_p_dv {
    max-height: 300px;
    height: 100%;
    overflow-y: auto;
    margin-top: 30px;
    padding-right: 30px;
    max-width: 550px;
    width: 100%;
}

.tm_p_dv p.para {
    margin-top: 0;
}

.linkedin_box {
    width: 44px;
    height: 44px;
    border-radius: 50px;
    display: flex;
    margin-top: 5px;
}

.linkedin_box img {
    width: 30px;
    filter: brightness(0) invert(1);
}

.tm_p_dv::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.tm_p_dv::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #101021;
    border-radius: 5px;
    background-color: #101021 ;
}

/* Handle */
.tm_p_dv::-webkit-scrollbar-thumb {
    background: #FFF34A;
    border-radius: 5px;
}

/* Handle on hover */
.tm_p_dv::-webkit-scrollbar-thumb:hover {
    background-color: #101021;
}

/*-------------------------- Team Content Slide Box END*/



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .team_box .card-title {
        font-size: 19px;
    }

    .team_box .card-text {
        font-size: 15px;
        line-height: 27px;
    }

    /*-------------------------- Team Content Slide Box START*/
    .team_slidebox {
        padding: 0px 40px 0px 40px;
    }

    .tm_fullcontent_col {
        padding: 0px 40px 0 40px;
    }

    /*-------------------------- Team Content Slide Box END*/

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .team_sec {
        padding-top:80px;
    }
    .team_row {
        margin-top:30px;
    }
    .team_box {
        margin-bottom: 50px;
    }
    .team_box .card-title {
        font-size: 18px;
    }
    .team_box .card-text {
        font-size: 14px;
        line-height: 25px;
    }

    /*-------------------------- Team Content Slide Box START*/
    .team_slidebox {
        padding: 0px 30px 50px 30px;
    }

    .tm_full_img_col {
        display: none;
    }

    .tm_fullcontent_col {
        padding: 15px 0px 0px 0px;
    }

    .tm_p_dv {
        max-width: 100%;
    }

    .close_tm_box {
        right: 50px;
        top: 150px;
        width: 40px;
        height: 40px;
    }

    .close_tm_box img {
        width: 15px;
    }

    .tm_name_LB {
        /* font-size:22px; */
        margin-bottom: 0px;
    }

    .tm_designation_LB {
        /* font-size: 16px; */
    }

    /*-------------------------- Team Content Slide Box END*/

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .team_sec {
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .team_row {
        margin-top: 40px;
    }
    .tm_full_img_col {
        display: none;
    }
    .team_box {
        margin-bottom: 30px;
    }

    .team_box .card-body {
        padding-top: 15px;
    }

    .team_box .card-title {
        font-size: 18px;
    }

    .team_box .card-text {
        font-size: 13px;
        line-height: 18px;    
    }

    /*-------------------------- Team Content Slide Box START*/
    .team_slidebox {
        padding: 80px 20px 50px 20px;
    }

    .tm_fullcontent_col {
        padding: 15px 0px 0px 0px;
    }

    .tm_p_dv {
        margin-top: 10px;
        padding-right: 0;
        max-height: fit-content;
    }

    .close_tm_box {
        right: 25px;
        top: 60px;
        width: 30px;
        height: 30px;
    }

    .close_tm_box img {
        width: 15px;
    }

    .tm_name_LB {
        font-size: 19px;
    }

    .tm_designation_LB {
        font-size: 16px;
    }

    /*-------------------------- Team Content Slide Box END*/

}