.privacy_pg .header {
    background: rgba(16, 16, 33, 0.8) !important;
}
.privacy_content_dv {
    padding-top:200px;
    padding-bottom: 100px;
}
.pp_hd{
    font-weight: 250;
    font-size: 32px;
    line-height: 39px;
    color: #101021;
    padding-bottom: 17px;
    margin-bottom: 35px;
    border-bottom: 1px solid #A1AEC7;
}
.pp_sub_hd {
    font-size: 24px;
    font-weight: 250;
    line-height:36px;
    margin-top: 45px;
    margin-bottom: 10px;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .privacy_content_dv {
        padding-top: 130px;
        padding-bottom: 50px;
    } 
    .pp_hd{
        font-size: 24px;
        line-height: 20px;
        padding-bottom: 13px;
        margin-bottom: 25px;
    }
    .pp_sub_hd {
        font-size: 20px;
        line-height: 30px;
        margin-top: 30px;
    }
}



