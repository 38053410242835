.key_sector_sec {
  width: 100%;
  padding: 75px 0;
  background: #101021;
  border-bottom: 1px solid rgba(75, 75, 75, 0.31);
}
.key_sector_sec  .heading {
  margin-bottom: 0px;
}
.key_sec_col1 .swiper-slide {
  height: auto !important;
}

.myaccordion h2,
.myaccordion p {
  font-family: 'Red Hat Display';
}

.myaccordion .card,
.myaccordion .card:last-child .card-header {
  border: none;
  padding: 0;
}

.myaccordion .card-header {
  border-bottom-color: #EDEFF0;
  background: transparent;
}

.myaccordion .fa-stack {
  font-size: 18px;
}
.ks_acord_animate {
  margin-top: 30px;
}
.myaccordion .sec_btn {
  width: 100%;
  background: transparent;
  padding: 0;
  font-family: 'oms';
  font-style: normal;
  font-weight: 250;
  font-size: 20px;
  line-height: 27px;
  border: none;
  border-bottom: 1px solid #ffffff33;
  border-radius: 0;
  height: 72px;
  text-align: left;
}
.myaccordion .sec_btn:hover {
  color: #EF7F1A !important;
}
.myaccordion .sec_btn:not(.collapsed) {
  border-bottom: none;
  color: #EF7F1A !important;
}

.key_icon .plus_icon {
  top: -9px;
}

.key_icon .minus_icon {
  top: -2px;
}

.lst_key {
  border-bottom: none !important;
}

span.accord_icon {
  position: relative;
}

img.minus_icon {
  top: 7px;
  opacity: 0;
}

#accordion_Sector button.sec_btn:not(.collapsed) .minus_icon {
  opacity: 1;
}
#accordion_Sector button.sec_btn:not(.collapsed) .plus_icon {
  opacity: 0;
}
#accordion_Sector button.sec_btn.collapsed .plus_icon {
  opacity: 1;
}



.accord_icon img {
  position: absolute;
  right: 0;
  transition: all 0.5s;
}

.ks_acord_animate .card-body {
  float: left;
  border-bottom: 1px solid #ffffff33;
}

#headingOne .btn.collapsed img.minus_icon,
#headingTwo .btn.collapsed img.minus_icon,
#headingThree .btn.collapsed img.minus_icon,
#headingFour .btn.collapsed img.minus_icon {
  display: none;
}

#headingOne .btn:not(.collapsed) img.plus_icon,
#headingTwo .btn:not(.collapsed) img.plus_icon,
#headingThree .btn:not(.collapsed) img.plus_icon,
#headingFour .btn:not(.collapsed) img.plus_icon {
  display: none;
}

#headingFour .btn.collapsed {
  border-bottom: 1px solid #ffffff00;
}

.csmt_card {
  background-color: transparent;
}

.csmt_card .card-body {
  padding-left: 0px;
}

.myaccordion .btn-link:hover,
.myaccordion .btn-link:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.myaccordion li+li {
  margin-top: 10px;
}



/*----------------------------Sector Image slider */

.key_sec_col2 {
  margin-top: 70px;
}

.sector_details_dv {
  position: relative;
  float: left;
  width: 85%;
}

.sector_img_dv {
  width: 512px;
  margin: auto;
  margin-right: 0;
}

.sector_img_dv img {
  width: 100%;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.5s ease-in;
  transition-delay: 500ms;
}

.sec_strk_hd {
  -webkit-text-stroke: 1px #27276A;
  -webkit-text-fill-color: transparent;
  font-family: 'Red Hat Display';
  font-weight: 700;
  text-transform: capitalize;
  float: left;

  position: absolute;
  top: auto;
  bottom: 0;
  display: none;

}

.techno {
  font-size: 128px;
  line-height: 170px;
  transform: translateY(100%);
  transition: all 0.8s;
  transition-delay: 1000ms;
}

.pharma {
  font-size: 193px;
  line-height: 202px;
  transform: translateY(100%);
  transition: all 0.8s;
  transition-delay: 1000ms;
}

.e_com {
  font-size: 112px;
  line-height: 202px;
  transform: translateY(100%);
  transition: all 0.8s;
  transition-delay: 1000ms;
}

.food_bevg {
  font-size: 155px;
  line-height: 170px;
  transform: translateY(100%);
  transition: all 0.8s;
  transition-delay: 1000ms;
}


.key_Sec_slider .swiper-slide {
  opacity: 0 !important;
  display: flex;
  justify-content: end;
}

.key_Sec_slider .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.key_Sec_slider .swiper-slide.swiper-slide-active .sector_img_dv img {
  transform: scale(1);
}

.key_Sec_slider .swiper-slide.swiper-slide-active .techno {
  transform: translateY(0%);
}

.key_Sec_slider .swiper-slide.swiper-slide-active .pharma {
  transform: translateY(0%);
}

.key_Sec_slider .swiper-slide.swiper-slide-active .e_com {
  transform: translateY(0%);
}

.key_Sec_slider .swiper-slide.swiper-slide-active .food_bevg {
  transform: translateY(0%);
}




@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}


@media only screen and (min-device-width: 768px) and (max-device-width: 1100px) {

  .sector_details_dv {
    width: 100%;
  }

  .ks_acord_animate .card-body {
    padding-top: 5px;
  }
}


@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

  .techno {
    font-size: 90px;
    line-height: 120px;
  }

  .pharma {
    font-size: 140px;
    line-height: 130px;
  }

  .e_com {
    font-size: 80px;
    line-height: 100px;
  }

  .food_bevg {
    font-size: 110px;
    line-height: 100px;
    bottom: 15px;
  }

  .myaccordion .sec_btn {
    font-size: 16px !important;
    height: 60px;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
  }

  .accord_icon img {
    top: auto;
    bottom: -6px;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

  .key_Sec_slider .swiper-slide {
    justify-content: center;
  }

  .accord_icon img {
    top: auto;
    bottom: -6px;
  }

  .sector_img_dv {
    width: 100%;
  }

  .techno {
    font-size: 60px;
    line-height: 70px;
  }

  .pharma {
    font-size: 90px;
    line-height: 80px;
  }

  .e_com {
    font-size: 51px;
    line-height: 70px;
  }

  .food_bevg {
    font-size: 75px;
    line-height: 70px;
  }

  .accord_icon img {
    width: 13px;
  }

  .myaccordion .sec_btn {
    font-size: 14px !important;
    height: 60px;
    line-height: 20px;
    text-align: left;
    padding-right: 20px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

  .key_sector_sec {

    padding: 50px 0;
  }

  .key_Sec_slider .swiper-slide {
    justify-content: center;
  }

  .sector_details_dv {
    width: 100%;
  }

  .sector_img_dv {
    width: 100%;
  }

  .key_col2 {
    display: none;
  }
  .techno {
    font-size: 58px;
    line-height: 80px;
  }

  .pharma {
    font-size: 87px;
    line-height: 100px;
  }

  .e_com {
    font-size: 50px;
    line-height: 68px;
  }

  .food_bevg {
    font-size: 71px;
    line-height: 60px;
  }

  .myaccordion .sec_btn {
    font-size: 15px;
    height: 60px;
  }

  .accord_icon img {
    position: absolute;
    right: 5px;
    width: 14px;
  }

  .key_icon .minus_icon {
    top: 0px !important;
  }

  .key_icon .plus_icon {
    top: -6px !important;
  }
  .key_col2 {
    display: none;
  }
}