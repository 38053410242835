.footer_sec {
    background: #101021;
    position: relative;
}
.ftr_col2 {
    border-left: 1px solid rgba(75, 75, 75, 0.31);
    padding-left: 46px;
    height: 100%;
}
.ftr_col1 , .ftr_col2 , .ftr_col3 , .ftr_col4 {
    padding-top: 64px;
}
ul.social_media {
    padding: 0;
    list-style: none;
    display: flex;
    margin-top: 160px;
}
ul.social_media li {
    margin-right: 15px;
}
.social_media img:hover {
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(85%);
}
.ftr_col_hd {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #405372;
    margin-bottom: 30px;
    font-weight: 250;

}
.ftr_link_ul {
    list-style: none;   
    padding:0px;
}
.ftr_link_li a{
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;
    display: block;
}
.ftr_link_li a:hover {
    color: #FF7900;
    text-decoration: none;
}

.footer_sec  .asc_div:hover {
    text-decoration: none;
}
img.asc_logo_footer {
    margin-right: 20px;
}

.mob_link {
    display: none;
}
.desktop_link {
    display: block;
}
.right_txt {
    position:absolute;
    top: auto;
    bottom:0;
}
.ftr_col1 {
    display: flow-root;
    height: 90.5%;
    position: relative;
}
.ftr_col5 {
    border-left: 1px solid rgba(75, 75, 75, 0.31);
    float: left;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 47px;
    padding-left: 46px;
}

.ftr_col5 a:hover {
    color:#FF7900;
}
.cp_txt {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #405372;
    margin-bottom: 0px;
    width: fit-content;
    float: left;
    font-family: 'oms';
    font-weight: 250;
}
.cp_txt a {
    color: #405372;  
    font-family: 'oms';
    font-weight:250;
}
.cp_link {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #405372;
    margin-bottom: 0px;
    float: right;
    font-family: 'oms';
    font-weight: 250;
}
.cp_link a {
    color: #405372;
    font-family: 'oms';
}



@media only screen and (min-device-width: 707px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .desktop_link {
        display: block  !important;
    }
    .mob_link {
        display: none  !important;
    }
    .ftr_col1 {
        border-bottom: 0px  !important;
    }
}


@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {


    .desktop_link {
        display: block !important;
    }
    .mob_link {
        display: none !important;
    }
    .ftr_col1 {
        border-bottom: 0px  !important;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .servc_col {
        display:none;
    }
    .asc_div .asc_logo_footer {
        width:30%;
    }
    ul.social_media {
        margin-top: 30px;
    }
    .ftr_link_li a {
        margin-bottom: 15px;
    }
    .ftr_col2 {
        border-top: 1px solid rgba(75, 75, 75, 0.31);
        border-bottom: 1px solid rgba(75, 75, 75, 0.31);
        padding-left: 0px;
        border-left: 0px; 
    }
    .ftr_col3 , .ftr_col4{
        border-bottom: 1px solid rgba(75, 75, 75, 0.31);
    }
    .ftr_col1, .ftr_col2, .ftr_col3, .ftr_col4 {
        padding-top: 20px;
    }

    .ftr_col5 {
        border-left: 0px;
        padding-top: 20px;
        padding-bottom: 30px;
        padding-left: 0px;
        display: grid;
    }
    .cp_txt {
        font-size: 13px;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .cp_link {
        font-size: 13px;
        width: 100%;
        text-align: center;
    }
    .desktop_link {
        display: none;
    }
    .mob_link {
        display: block;
    }
    .mob_link {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 15px;
        text-align: center;
    }
    .ln_1 {
        order: 2;
    }
    .ln_2 {
        order: 3;
    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .ftr_col1 > a.asc_div {
        width: 40%;
    }
    .asc_div .asc_logo_footer {
        width: 50%;
        margin-right: 15px;
    }
    .ftr_col1 .asc_name {
        font-size: 15px;
        line-height: 21px;
    }

    .ftr_col1 {
        float: left;
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(75, 75, 75, 0.31);
    }
    .ftr_col1, .ftr_col2, .ftr_col3, .ftr_col4 {
        padding-top: 30px;
    }
    .ftr_col1 > a.asc_div {
        float: left;
    }
    ul.social_media {
        margin-top: 16px;
        float: right;
    }
    .ftr_col_hd {
        margin-bottom: 20px;
    }
    .ftr_link_li a {
        font-size: 13px;
        margin-bottom: 16px;
    }
    .ftr_col2 {
        border-left: 0px;
        padding-left: 0;
    }
    .ftr_col5 {
        border-left: 0px;
        padding-top: 50px;
        padding-bottom: 47px;
        padding-left: 0px;
    }
    .cp_txt {   
        font-size: 13px;
    }
    .cp_link {
        font-size: 13px;
    }
    .desktop_link {
        display: none;
    }
    .mob_link {
        display: block;
    }
    .mob_link {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 25px;
        text-align: center;
    }
    
}