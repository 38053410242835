.cfs_icd_infra {
    background: #101021;
    padding: 85px 0;
}

.cfs_tabs {
    margin-top: 50px;
}
.cfs_box_section {
    margin-top: 50px;
}
.pdf_link {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #302c68;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    display: block;
    margin: auto;
    margin-top: 10px;
}
.pdf_link:hover {
    color: #FF7900 !important;
    border-bottom: 1px solid #FF7900 !important;
    text-decoration: none;
}
.cfs_row_tab {
    width: 100%;
    border-bottom: 3px solid #A1AEC7;
    position: relative;
}

.cfs_row_tab .tab-indicator {
    width: calc(100% / 3);
    height: 3px;
    background-color: #FF7900;
    position: absolute;
    top: auto;
    bottom: -3px;
    left: 0px;
    transition: all 500ms ease-in-out;
}

.cfs_link {
    width: 33.33%;
    padding-bottom: 20px;
    appearance: unset;
    -webkit-appearance: unset;
}

.cfs_tab_div {
    width: 100%;
    max-width: 225px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cfs_icon_prt1 {
    width: 13%;
    float: left;
    margin-right: 10px;
}

.cfs_icon_prt1 img {
    width: 100%;
    opacity: 0;
    filter: brightness(0) invert(1);
    transition: all 0.5s;
}

.cfs_icon_prt2 {
    width: fit-content;
    float: left;
    text-align: center;
}

.cfs_tb_ttl1 {
    font-weight: 250;
    font-size: 20px;
    line-height: 29px;
    color: #fff;
    margin-bottom: 0;
}

.cfs_state_nm {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #A1AEC7;
    margin-bottom: 0;
}

.cfs-tab-cntnt {
    padding-top: 25px;
}

.nav-link.cfs_link {
    border: 0px;
}

.nav-link.cfs_link.active {
    background-color: transparent;
    border: 0px;
}

.nav-tabs .cfs_link:hover {
    border: 0px;
}

.nav-tabs .cfs_link:hover .cfs_icon_prt1 img {
    opacity: 1;
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(85%);
}

.nav-tabs .cfs_link:hover .cfs_tb_ttl1 {
    color: #FF7900;
}

.nav-link.cfs_link.active .cfs_tb_ttl1 {
    color: #FF7900;
}

.nav-link.cfs_link.active .cfs_icon_prt1 img {
    opacity: 1;
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(85%);
}

.cfs_ctnt_hd {
    color: #A1AEC7;
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 40px;
}

.cfs_list_hd {
    font-size: 28px;
    line-height: 36px;
    color: #A1AEC7;
    margin-bottom: 26px;
    font-weight: 250;
}

.cfs_ul_list {
    max-width: 475px;
    width: 100%;
}

.cfs_ul_list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 12px 0;
    border-bottom: 1px solid rgb(217 217 217 / 40%);
}

.cfs_ul_list li:last-child {
    border-bottom: 0px;
}

.cfs_col2 {}

.cfs_img_dv {}

.cfs_img_dv img {
    width: 100%;
}

.csf_img_txt {
    background-color:#fff;
    padding:23px 17px;
}

.csf_img_txt_hd {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #101021;
    border-bottom: 2px solid #833DAE;
    width: fit-content;
    padding-bottom: 3px;
}

.csf_img_txt p {
    color: #101021;
    margin-bottom: 0px;
}
.cfs_hd {
    text-align: center;
}

.desktop_cfs_tab {
    display: block;
}
.mobile_cfs_accord {
    display: none;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .cfs_tb_ttl1 {
        font-size: 18px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .nav-link.cfs_link {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 5px;
    }
    .cfs_icon_prt1 {
        margin-right: 5px;
    }
    .cfs_tb_ttl1 {
        font-size: 15px;
        line-height: 29px;
    }
    .cfs_state_nm {
        font-size: 12px;
        line-height: 18px;
    }
    .cfs_ctnt_hd {
        font-size: 18px;
    }
    .cfs_list_hd {
        font-size: 23px;
    }
    .cfs_ul_list li {
        font-size: 15px;
        line-height: 25px;
    }
    .cfs_col2 {
        margin-top: 50px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .cfs_icd_infra {
        padding: 50px 0;
    }
    .cfs_icd_mob_sec .card-header{
        height: auto;
    }
    
    .cfs_tab_div {
        margin-left: 0;
        justify-content: left;
    }
    .cfs_link {
        width: 100%;
        padding-bottom: 20px;
    }
    .tab-indicator {
        display:none;
    }
    .nav-link.cfs_link {
        border: 0px;
        padding-left: 0;
    }
    .cfs_icon_prt2 {
        text-align: left;
    }
    .cfs_hd {
        text-align: left;
    }
    .cfs_tb_ttl1 {
        font-size: 18px;
    }
    .cfs_state_nm {
        font-size: 12px;
    }
    .cfs_ctnt_hd {
        font-size: 16px;
        line-height: 26px;
    }
    .cfs_list_hd {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .cfs_ul_list li {
        font-size: 14px;
        line-height: 23px;
    }
    .cfs_col2 {
        margin-top: 50px;
    }

    .desktop_cfs_tab {
        display: none;
    }
    .mobile_cfs_accord {
        display: block;
    }

    .mobile_cfs_accord .cfs_icon_prt1 img {
        opacity: 1;
    }
    .mobile_cfs_accord .cfs_tab_div {
        float: left;
    }
    .mobile_cfs_accord .status_icon {
        margin-top: 12px;
    }
    .mobile_cfs_accord .warehouse_content {
        margin-top: 20px;
    }
    .mobile_cfs_accord .accordion_solution .card-header button:not(.collapsed) .cfs_icon_prt1 img{
        -webkit-filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0) contrast(98%);
        filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0) contrast(98%);
    }
    .mobile_cfs_accord .accordion_solution .card-header button:not(.collapsed) .cfs_tb_ttl1 {
        color: #111021;
    }
    .mobile_cfs_accord .accordion_solution .card-header button:not(.collapsed) .cfs_state_nm {
        color: #111021;
    }
}