.asc_advance {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 100px;
    padding-bottom: 100px;
}

.asc_advnc_col1 {
    margin-bottom: 55px;
}

.asc_advnc_col {
    /* background-color: #fff; */
    max-width: 370px;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
}

.asc_advnc_col img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aac_hd_dv {
    position: absolute;
    width: 100%;
    background-color: rgb(255 255 255 / 85%);
    backdrop-filter: blur(8px);

    padding: 15px 25px 0px 20px;
    top: auto;
    bottom: 0;
    transition: all 0.6s;
    transform: translateY(0%);
    height: 60px;
}

.asc_advnc_ctnt {
    padding: 20px 25px 30px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 85%);
    backdrop-filter: blur(8px);

    top: 0;
    transform: translateY(80%);
    transition: all 0.6s;
}

.asc_advnc_col:hover .aac_hd_dv {
    transform: translateY(-400%);
}

.asc_advnc_col:hover .asc_advnc_ctnt {
    transform: translateY(0);
}

.aac_hd {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #2727A3;
    font-family: 'oms';
}

.aac_desc {
    color: #6D6D6D;
    margin-bottom: 0px;
}

.advn_col_desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    margin-top: 23px;
}

.asc_advance hr {
    margin-top: 47px;
    margin-bottom: 36px;
    border: 0;
    width: 95%;
    float: left;
    border-top: 1px solid rgb(161 174 199 / 70%);
}

.asc_adv_ttl2 {
    font-weight: 250;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.asc_ad_arrow {
    position: absolute;
    width: 21px !important;
    height: 27px !important;
    top: 17px;
    right: 15px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .advn_col_desc {
        font-size: 20px;
        line-height: 24px;
    }

    .aac_desc {
        font-size: 15px;
        line-height: 23.6px;
    }

    .asc_advance {

        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .advn_col_desc {
        font-size: 21px;
        line-height: 31px;
        margin-top: 10px;
    }

    .asc_advnc_col1 {
        margin-bottom: 20px;
    }

    .asc_advnc_ctnt {
        padding:20px;
    }
    .aac_hd_dv {
        padding: 15px 15px 0px 15px;
    }
    .aac_hd {
        font-size: 17px;
        line-height: 19px;
    }

    .asc_adv_ttl2 {
        font-size: 25px;
    }

    .asc_advance {

        padding-top: 50px;
        padding-bottom: 50px;
    }
    /* .aac_desc {
        font-size: 14px;
        line-height: 20px;
    } */
    .asc_advnc_col {
        max-width:100%;
        margin-bottom: 50px;
    }
    .asc_advnc_col:hover .aac_hd_dv {
        transform: translateY(-400%);
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .asc_advance {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .asc_advance hr {
        margin-top: 15px;
    }
    .advn_col_desc {
        font-size: 18px;
        line-height: 31px;
    }

    .asc_advnc_col1 {
        margin-bottom: 40px;
    }

    .asc_advnc_col {
        margin-bottom: 30px;
    }

    .aac_hd {
        font-size: 18px;
    }

    .asc_adv_ttl2 {
        font-size: 18px;
        line-height: 30px;
    }
}