.challange_cstm {
    padding-top:73px;
    padding-bottom: 66px;
}

.chalng_para {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #101021;
    margin-top: 20px;
    margin-bottom: 50px;
}
.chalng_list {
    width:100%;
    max-width:520px;
}
.chalng_list li{
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #101021;
    margin-bottom: 25px;
}

.chalng_box {
    border: 1px solid rgba(0, 0, 0, 0.2);
    float: left;
    width: 100%;
}
.chalng_inner {
   padding:45px 20px; 
   float: left;
   display: flex;
   gap:12px;
}
.chalng_icon_dv {
    float: left;
}
.chalng_txt_dv {
    float: left;
}
.chalng_txt_dv .chlng_bx_hd {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #2727A3;
    margin-bottom: 10px;
}
.chalng_txt_dv p{
    color: #101021;
    margin-bottom:0px;
}


.automotive_pg .chalng_icon_dv {
    width: 7%;
}
.automotive_pg .chalng_txt_dv {
    width: 93%;
}

.e_com_pg .chalng_box {
    margin-bottom: 30px;
    height: 195px;
}
.e_com_pg .chnlg_bx_1 {
    height: 140px;
}
.ecom_chlng_hd_col {
    margin-bottom: 50px;
}
.automotive_pg .chalng_box {
    height: 340px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .challange_cstm {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .chalng_inner {
        padding: 40px 15px;
    }
    .chalng_txt_dv .chlng_bx_hd {
        font-size: 19px;
    }
    .e_com_pg .chalng_box {
        height: 235px;
    }
    .automotive_pg .chalng_box {
        height: 400px;
    }
    .automotive_pg .chalng_icon_dv img {
        width: 21px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .chalng_list li {
        margin-bottom: 30px;  
    }

    .challange_cstm {
        padding-top: 73px;
        padding-bottom: 73px;
    }

    .challange_cstm {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .chalng_icon_dv img  {
        width: 25px;
    }
    .chalng_box {
        margin-bottom: 30px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .chalng_para {
        font-size: 16px;
    }
    .chalng_list li {
        margin-bottom: 0px;
    }


    .challange_cstm {
        padding-top:73px;
        padding-bottom: 73px;
    }

    .challange_cstm {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .chalng_box {
        margin-bottom: 20px;
    }
    .chalng_inner {
        padding: 25px 15px;
    }
    .chalng_icon_dv img {
        width: 22px;
    }
    .chalng_txt_dv .chlng_bx_hd {
        font-size: 18px;
    }
    .e_com_pg .chalng_box {
        height: auto;
    }
    .ecom_chlng_hd_col {
        margin-bottom: 15px;
    }
    .automotive_pg .chalng_box {
        height: auto;
    }
}



