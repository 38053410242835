.blog_sec {
    padding: 105px 0px;
}

.blg_sec_col {}

.blog_box {
    height: 192px;
    width: 100%;
    margin-left: auto;
    transition: all 0.2s;
    float: left;
    margin-bottom:35px;
    background: #F4F4F4;
}
.blog_box:hover {
    background: #E5EBF5;
}
.blog_box:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.blog_box a {
    display: block;
    width: 100%;
    height: 100%;
    float: left;
}

.blog_box_img {
    width: 22%;
    height: 100%;
    float: left;
}

.blog_box_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog_box_content {
    width: 78%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding-left: 40px;
    position: relative;
}

.media_tag {
    font-family: 'oms';
    font-weight: 250;
    font-size: 13px;
    line-height: 26px;
    color: #fff;
    background: #FF7900;
    padding:5px;
}

.blg_ttl {
    font-weight: 250;
    font-size: 23px;
    line-height: 34px;
    color: #101021;
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'oms';
}

.media_date {
    font-weight: 250;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #101021;
    margin-bottom: 0;
    font-family: 'oms';
}
img.blg_arrow {
    position: absolute;
    top: 65px;
    right: 40px;
    width: 35px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .blg_ttl {
        font-size: 20px;
        line-height: 31px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .blog_box_img {
        width: 30%;
    }
    .blog_box_content {
        width: 70%;
    }
    .media_tag {
        font-size: 14px;
        line-height: 27px;
    }
    .blg_ttl {
        font-size: 18px;
        max-width: 370px;
        line-height: 27px;
        margin-bottom: 10px;
    }
    .media_date {
        font-size: 14px;
        margin-bottom: 0;
    }
    img.blg_arrow {
        right: 25px;
        top: 70px;
        width: 28px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .blog_box {
        height: auto;
    }
    .blog_box_img {
        width: 100%;
    }
    .blog_box_content {
        width: 100%;
        padding: 20px 15px;
    }
    .media_tag {
        font-size: 14px;
    }
    .blg_ttl {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .media_date {
        font-size: 13px;
        line-height: 16px;
    }
    img.blg_arrow {
        right: 20px;
        bottom: 21px;
        width: 25px;
        top: auto;
    }

    .blog_sec {
        padding-top: 50px !important;
        padding-bottom: 15px !important;
    }
}