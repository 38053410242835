.service_offer {
    background: #101021;
    padding-top: 108px;
    padding-bottom: 108px;
}
.digitalize_sec {
    background-color: #f4f4f4;
}
.cntrol_twr_link {
    color: #2A2AB0;
    text-decoration: underline;
    font-size: 16px;
    width: 100%;
    display: block;
    font-family: 'Red Hat Display';

}
.sc_digital_p {
    margin-bottom: 20px;
}
.cntrol_twr_link:hover {
    color: #2A2AB0;  
}
.so_sub_ttl {
    font-weight: 250;
    font-size: 25px;
    line-height: 36px;
    color: #a1aec7;
    margin-bottom: 21px;
    font-family: 'oms';
}

.life_para a {
    color: #101021;
    text-decoration: underline;
}

.so_row_2 {
    padding-top: 71px;
}

.so_col1 {
    width: 100%;
    /* max-width: 490px; */
    padding-left: 65px;
}

.so_col1_multi {
    width: 100%;
    /* max-width: 490px; */
    /* padding-left: 65px; */
}

.so_col_img {
    /* margin-bottom: 63px; */
    width: 100%;
}

.so_col_img_multi {
    /* margin-bottom: 63px; */
    width: 100%;
}

.so_col_hd {

    font-weight: 250;
    font-size: 28px;
    line-height: 36px;
    color: #A1AEC7;
    margin-bottom: 20px;
    font-family: 'oms';

}


.so_col_desc {
    color: #E3E3E3 !important;
}


.so_col_desc.dw_boldhead {
    font-weight: 400;
}


.so_col_desc.custom_subhead {
    font-weight: 400;
    color: #FFFFFF !important;
}

.so_col_desc.custom_para_1 {
    color: #FFFFFF !important;
}

.dedicate_row {
    padding-bottom: 50px;
}

.dedicate_row .so_col1_multi {
    max-width: 600px;
}

.multi_box {
    max-width: 600px;
    margin-left: auto;
}

/* Route_optimization */

.route_section {
    background: #101021;
    padding-top: 80px;
    padding-bottom: 64px;
}

.so_col_desc.route_mainhead {
    font-weight: 400;
}

/* end_Route_optimization */


/* Network Design */


.Network_offer {
    padding-top: 80px;
    padding-bottom: 64px;
}

.net_col2 {
    display: flex;
    align-self: center;
}


.network_para {
    color: #101021 !important;
}

.so_col1_network {
    padding-left: 0px;
}

.design_ttl {
    width: 100%;
    /* margin-top: 108px; */
}


/*end- Network Design */


/* Simulation_design */

.simulation_offer {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 80px;
    padding-bottom: 64px;
}

.so_col_img_simulation {
    width: 100%;
}

.so_col_desc.simulation_subpara {
    font-weight: 400;
}


/* end_Simulation_design */


/* Custom_brokerage */


/* end_Custom_brokerage */


/* ---------------ocean-freight */


.so_col_desc.ocean_ttl {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;

    display: flex;
    align-items: center;

    color: #FFFFFF;
    margin-bottom: 27px;
}

.so_col_desc.ocean_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.ocean_para_main {
    padding-right: 112px;
}

.so_col1_optimization.freight_img {
    padding-top: 75px;
}

.ocn_frg_img_dv {
    padding-top: 0px !important;
}

.ocean_head {
    color: #A1AEC7;
    margin-bottom: 11px;

}

.ocean_freight_section {
    background: #101021;
    padding-top: 80px;
    padding-bottom: 64px;

}

/* -------------end- ocean-freight */


/* -----air-freight------ */

.air_freight_sec {

    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 80px;
    padding-bottom: 64px;
}



.so_col_desc.network_para.air_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight:400;
    font-size: 22px;
    line-height: 53px;
    display: flex;
    align-items: center;
    color: #FFFFFF !important;
}



.so_col_desc.air_sub_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.design_ttl.air_main {
    padding-left: 52px;
}

.so_col1_network.img_air {
    /* padding-top: 62px; */
}


/* ------------------end-air-freight------ */


/* ----------full truck load */


.full_truck_sec {
    background: #101021;
    padding-top: 80px;
    padding-bottom: 64px;
}


.so_col_desc.truck_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.so_col1_multi.truck_main {
    padding-left: 20px;
}


.full_truck_content p {
    color: #e3e3e3;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

.so_col_desc.land_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.truck_head {
    color: #A1AEC7;
}

.part_truck_head {
    color: #101021;
}


.heading.supply_head.air_head.last_mile {
    line-height: 171%;
}



/* ----------end full truck load */


/* ---------------asc_prime */

.design_ttl.air_main.asc_prime {
    padding-left: 0px;
    padding-right: 84px;
}

sup {
    font-size: 20px;
}

.prm_bx {
    margin-bottom: 40px;
}

.prime_bx_num {
    margin-bottom: 1rem;
}

.prime_bx_num p {
    align-items: center;
    color: #101041;
    display: flex;
    font-size: 40px;
    font-style: normal;
    font-weight: 250;
    line-height: 26px;
    font-family: 'oms';
}

.prime_desc2 p {
    align-items: center;
    color: #101021 !important;
    display: flex;
    font-style: normal;
    font-weight: 400;
}

.asc_prime_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #101021;

}


.so_col_desc.prime_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #101021 !important;
}


.so_col_desc.prime_sub_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #101021 !important;
}

.asc_primeheading {
    color: #101021 !important;
}


.asc_prime_sec {
    padding-top: 80px;
    padding-bottom: 64px;
}


.cc_ttl.asc_ttl {
    color: #101041 !important;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}



.count_col.cc_1.asc_count {
    border-left: 0px solid #A1AEC7;
    background-color: #E7EAF0;
    max-width: 259px;
    height: 158px;
}

.cc_num_1.asc_num {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #101041;

}



/* ---------end asc_prime */


/* -----------park_truck */

.soluion_ttl {
    font-family: 'oms';
    font-weight: 250;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #101021;
    transition: all 0.5s;
    /* width: 140px; */
    display: inline-table;

}

.soluion_content {
    color: #101021;
}

.truck_loaded .card-header button:not(.collapsed),
.truck_loaded .card-header button,
.truck_loaded .card-header {
    background: transparent !important;
    padding: 0px;
}

.truck_loaded .card {
    border-bottom: 1px solid rgb(0 0 0 / 20%) !important;
}

.accordion_solution.truck_loaded.solution_pad {
    padding-right: 63px;
}

.status_icon.accord_icon {
    top: 12px;
}

.park_truck_sec {
    padding-top: 69px;
    padding-bottom: 77px;
}

.surface_prk_trk_acrd .sol_plus_icon {
    top: -4px;
}

.surface_prk_trk_acrd .sol_minus_icon {
    top: 3px;
}



/* -------------end -park_truck */


/* --------------life at asc */

.asc_section {

    padding-top: 82px;
    padding-bottom: 82px;
}

.life_sec {
    margin-top: -15px;
    padding-top: 0px;
}

.life_head {
    line-height: 46.4px;
}

.life_sec_anchor {
    display: block !important;
}

.life_para {
    color: #101021 !important;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;



}

.cards_block_section {
    background-color: #101021;
    padding-top: 100px;
    padding-bottom: 100px;
}


.aac_hd.main_heading_asc {
    color: #2727a3 !important;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 26px;
}

.aac_desc.main_para_asc {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 26px !important;



    color: #101021 !important;

}

.asc_advnc_col.main_asc_col {
    background: #ffff !important;
}

/* ---------end life at asc */


/* ------------grow_section */


.grow_section {
    background: linear-gradient(116.5deg, #9642B3 -12.83%, #232394 64.77%);


}


.customised_slider.sldr_crl_bnt.life_slider {
    margin-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 50px;
}

.recog_card .asc_advnc_col {
    max-width: 380px;
    width: 100%;
    background: linear-gradient(310.2deg, rgba(255, 255, 255, 0.7) 4.96%, rgba(255, 255, 255, 0.1) 70.41%);
    box-shadow: 0px 4px 24px -41px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12.5px);
}

.recog_card .aac_hd {
    color: #fff;
    font-weight: 400;
    font-size: 28px;
}

.recog_card .aac_desc {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.life_slider.customised_slider .swiper-button-next {
    background: rgba(255, 255, 255, 0.1);
    /* Grey Light */

    border: 1px solid #A1AEC7;
    backdrop-filter: blur(2.5px);
}

.life_slider.customised_slider .swiper-button-prev {

    background: rgba(255, 255, 255, 0.1);
    /* Grey Light */

    border: 1px solid #A1AEC7;
    backdrop-filter: blur(2.5px);
}

.airfreight_img {
    width: 100%;
    /* margin-bottom: 50px; */
}

.career_popup {
    cursor: pointer;
}
.carrer_overlay {
    background: rgba(49,61,71,.8);
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    display: none;
}
.career_popup_box {
    background: rgba(16, 16, 33, 1);
    left: 50%;
    padding:50px 30px;
    top: 15%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 40%;
    position: fixed;
    display: none;
    z-index: 1;
}
.career_cls_btn {
    float: right;
    width: 30px;
    cursor: pointer;
}

.career_box {
    justify-content: left !important;
    height: 280px !important;
}
.career_box div {
    width: 100%;
    text-align: left !important;
}
.career_box div img {
    margin-bottom: 10px;
}
.career_box .rslt_bx_p {
    max-width: 100%;
    text-align: left;
}
/* ------end grow-section */



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .career_popup_box {
        width:60%;
    }

    .so_sub_ttl {
        font-size: 21px;
        line-height: 33px;
    }

    .so_col_hd {
        font-size: 25px;
    }

    .so_col1_network {
        padding-left: 0px;
        margin-top: 68px;
    }

    .so_col1_optimization {
        padding-left: 0px;
        margin-top: 68px;
    }

    .service_offer {

        padding-top: 50px;
        padding-bottom: 50px;
    }

    .full_truck_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .soluion_ttl {
        font-size: 16px;
        line-height: 25px;
    }

    .accord_icon img {
        top: auto;
        bottom: -10px;
    }

    .park_truck_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .solution_pad .card:last-child {
        border-bottom: none !important;
    }

    .last_mile_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .asc_prime_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }


}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .career_box {
        height: 315px !important;
    }
    .career_popup_box {
        width:80%;
    }

    .asc_section {
        padding-bottom: 50px;
    }


    .service_offer {

        padding-top: 50px;
        padding-bottom: 50px;
    }

    .so_sub_ttl {
        font-size: 20px;
        line-height: 34px;
    }

    .so_col_hd {
        font-size: 24px;
    }

    .info_ware_col_one {
        width: 100% !important;
    }

    .info_para_cust {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: auto !important;
    }

    .prm_bx {
        margin-bottom: 0;
    }

    .info_para {
        font-size: 20px;
        line-height: 32px;
    }

    .so_col1_optimization {
        padding-left: 0px;
    }

    .so_col1_optimization.freight_img {
        padding-top: 30px;
    }

    .ocean_para_main {
        padding-right: 50px;
    }

    .so_col1_network {
        padding-left: 0px;
    }

    .park_truck_accord {
        margin-bottom: 80px;
    }

    .ocn_frg_img_dv {
        padding-top: 50px !important;
    }

    /* ----------------last mile tab*/

    .design_ttl.air_main {
        padding-left: 0px;
        margin-top: 30px;
    }

    /*--------------------end last mile tab */


    /* -------asc prime */

    .cc_ttl.asc_ttl {

        font-size: 13px;

    }

    .cc_num_1.asc_num {

        font-size: 24px;

    }




    /* ------end-asc prime */

    /* ------Start-asc life */
    .cards_block_section .asc_advnc_col {
        height: 550px;
    }

    /* ------end-asc life */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .career_popup_box {
        width:80%;
        top: 15%;
    }
    .career_popup_form {
        margin-top: 25px;
    }


    .multi_inner_col {
        margin-top: 30px;
    }
    .so_col_desc.ocean_para {
        font-size:14px;
    }
    .so_col_desc.air_sub_para {
        font-size:14px;
    }
    .service_offer {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .park_truck_accord {
        padding-right: 0px !important;
    }
    .life_para {
        font-size: 17px;
        line-height: 29px;
    }

    .so_col_img.consulting_img {
        margin-bottom: 30px;
    }

    .Network_offer {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .so_col1_optimization {
        margin-top: 35px;
    }

    .ocn_frg_img_dv {
        padding-bottom: 60px !important;
    }

    .air_freight_sec {

        padding-top: 50px;
        padding-bottom: 50px;
    }

    .asc_rightpad {
        padding-right:0px;
    }


    .asc_prime_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .design_ttl {

        margin-top: 0px;
    }

    .so_col1_network.img_air {
        padding-top: 0px;
    }

    .so_row_2 {
        padding-top: 0px;
    }

    .so_col1 {
        margin-top: 40px;
        padding-left: 0px;
    }

    .so_sub_ttl {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 45px;
    }

    .so_col_hd {
        font-size: 23px;
        line-height: 33px;
    }

    .so_col_img {
        margin-bottom: 0px;

    }



    .supply_control {

        font-size: 16px;
    }

    .info_para_cust {
        font-size: 18px;
        line-height: 28px;
    }

    /* supplychain network-desing */

    .heading.supply_head {

        line-height: 30px;
    }

    /*end supplychain network-desing */


    /* supplychain simulation */

    .heading.wht_txt.simulation_head {

        line-height: 30px;
    }

    /*end supplychain simulation */



    /* -----------air-feight */


    .ocean_para_main {
        padding-right: 0px;
    }

    .design_ttl.air_main {
        padding-left: 0px;
    }

    .so_col_desc.network_para.air_para {

        line-height: 28px;

    }

    .heading.supply_head.air_head {

        line-height: 160%;

    }

    /* ---------end--air-feight */


    /* ----------asc_prime */

    .design_ttl.air_main.asc_prime {

        padding-right: 0px;
    }

    .asc_prime_para {

        line-height: 30px;
        font-size: 20px;



    }

    .count_col.cc_1.asc_count {
        padding-left: 22px;

        border-bottom: 0px solid #A1AEC7;
    }

    /* --------end asc-prime */


    /* ------------last mile */

    .heading.supply_head.air_head.last_mile {
        line-height: 112%;
    }
    .lst_ml_img_col {
        margin-top: 30px;
    }

    /* end-last mile */
    .so_col1_multi.truck_main {
        padding-left: 0px;
        margin-top: 30px;
    }

    /* -------park truck */

    .soluion_ttl {

        font-size: 14px;
        line-height: 20px;
        font-weight: 250;

    }

    .solution_pad .card:last-child {
        border-bottom: none !important;
    }

    .so_col_img.aero_img {
        margin-bottom: 30px !important;
    }

    .full_truck_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .surface_prk_trk_acrd .card-header button.card-link {
        padding-left: 0 !important;
        padding-right: 0px !important;
    }
    .surface_prk_trk_acrd  button span .sol_plus_icon {
        top: -3px;
    }
    .surface_prk_trk_acrd  button span{
        width:80%;
        float: left;
        font-size: 14px;
    }
    .surface_prk_trk_acrd .accord_icon {
        width: 16%;
        top: 5px;
    }

    .park_truck_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .so_col_desc.truck_para {
        font-size: 16px;
    }



    /* end park truck */

    /* -----life at ASC */

    .cards_block_section {
        background-color: #101021;
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .asc_section {
        padding-top: 0px;
        padding-bottom: 30px;

    }

    .heading.life_head.gyc_career {
        padding-top: 40px;
    }
    .prime_bx1 {
        margin-left: 0 !important;
        max-width: 92% !important;
        padding: 25px !important;
    }




    /* --------end life at ASC */


    .ocean_freight_section {
        padding-top: 50px;
        padding-bottom: 0px;
    }


    .simulation_offer {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .so_col_img_simulation {
        margin-top: 0px;
    }


    .route_section {
        padding-top: 50px;
        padding-bottom: 50px;
    }


}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .so_col1 {

        padding-left: 0px;
    }

    .so_col1_multi.truck_main {
        padding-left: 0;
        padding-top: 30px;
    }
}