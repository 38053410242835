.news_sec {
    background: #101021;
    padding-top: 100px;
    padding-bottom: 107px;
    overflow: hidden;
}

.news_container {
    width: 100%;
    padding-left: 180px;
}

.news_row_col2 {
    padding-right: 0px;
}

.news_ttl {
    font-size: 160px;
    line-height: 151px;
    text-transform: capitalize;
    -webkit-text-stroke: 1px #3a3a8b;
    -webkit-text-fill-color: transparent;
    transform: rotate(-180deg);
    writing-mode: tb-rl;
    margin-top: 0;
    height: 80%;
    font-weight: 400;
}


.news_slider.swiper {
    width: 100%;
    height: 560px;
}

.news_slider .swiper-button-prev {
    transform: rotate(90deg);
    left: 35px;
    bottom: 80px;
    top: auto;
    background-color:transparent;
    border: 1px solid #A1AEC7;
}

.news_slider .swiper-button-next {
    transform: rotate(90deg);
    left: 35px;
    bottom: 1px;
    top: auto;
    background-color:transparent;
    border: 1px solid #A1AEC7;
}

.news_slider .swiper-slide {}

.news_slider_dv {
    height: 176px;
    width: 90%;
    margin-left: auto;
    /* background: rgb(39 39 163 / 20%); */
    transition: all 0.8s;
}
.news_slider_dv a {
    display: block;
    float: left;
    width: 100%;
    height: 100%;
}
.news_slider_dv:hover {
    background: #EF7F1A;
}

.news_slider_dv:hover .news_sub_ttl {
    color: #101021;
}
.news_slider_dv:hover .news_date {
    color: #101021;
}

.news_slider_dv p {
    transition: all 0.8s;
}

.news_img_dv {
    width: 22%;
    float: left;
    height: 100%;
}

.news_content {
    width: 78%;
    height: 100%;
    float: left;
    padding: 25px;
    padding-right: 100px;
    background: rgb(39 39 163 / 20%);
}

.news_sub_ttl {
    font-size: 21px;
    line-height: 30px;
    color: #FFFFFF;
    width: 100%;
    margin-bottom: 30px;
    font-weight:250;
}

.news_img_dv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news_date {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #A1AEC7;
    font-weight: 250;
    margin-bottom: 0;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .news_container {
        padding-left: 0px;
    }

    .news_ttl {
        height: 85%;
        margin-left: 75px;
        font-size: 139px;
    }

    .news_slider .swiper-button-prev,
    .news_slider .swiper-button-next {
        left: 10px;
    }

    

    
}
@media screen and (min-width: 1280px) and (max-height: 615px) {
    _::-webkit-full-page-media, _:future, :root .news_ttl {
        height:130%;
        margin-left: 150px;
        font-size: 130px;
        line-height: 120px;
    }
}
@media screen and (min-width: 1280px) and (min-height: 856px) {
    .news_ttl {
        height: 64%;
        margin-left: 75px;
    }
    _::-webkit-full-page-media, _:future, :root .news_ttl {
        height: 85%;
    }
}
@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .news_container {
        padding-left: 0px;
    }

    .news_ttl {
        height: 86%;
        margin-left: 75px;
    }

    .news_slider .swiper-button-prev,
    .news_slider .swiper-button-next {
        left: 10px;
    }
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {

    .news_container {
        padding-left: 0px;
    }

    .news_ttl {
        height: 76%;
        margin-left: 75px;
    }

    .news_slider .swiper-button-prev,
    .news_slider .swiper-button-next {
        left: 10px;
    }

    _::-webkit-full-page-media, _:future, :root .news_ttl {
        height:95%;
        margin-left: 100px;
    }
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {

    .news_container {
        padding-left: 0px;
    }

    .news_ttl {
        height: 80%;
        margin-left: 150px;
    }

    .news_slider .swiper-button-prev,
    .news_slider .swiper-button-next {
        left: 20px;
    }
}
@media screen and (min-width: 1600px) and (min-height: 1080px) {
    .news_ttl {
        height: 52% !important;
    }
    _::-webkit-full-page-media, _:future, :root .news_ttl {
        height:65% !important;
    }
}
@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {

    .news_container {
        padding-left: 0px;
    }

    .news_ttl {
        height: 73%;
    }

    .news_slider .swiper-button-prev,
    .news_slider .swiper-button-next {
        left: 25px;
    }

}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .news_container {
        width: 100%;
        padding-left: 0;
    }

    .news_ttl {
        font-size: 110px;
        line-height: 110px;
        height:80%;
        margin-left: 60px;
    }

    .news_slider .swiper-button-prev {
        left: 0px;
        bottom: 65px;
    }

    .news_slider .swiper-button-next {
        left: 0;
    }

    .news_img_dv {
        width: 30%;
        float: left;
    }

    .news_content {
        width: 70%;
        float: left;
        padding: 20px;
    }

    .news_sub_ttl {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
    }


}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .news_container {
        padding-left: 0px;
    }

    .news_row_col2 {
        padding-right: 22px;
        margin-top: 30px;
    }

    .news_slider_dv {
        width: 100%;
        height: 85%;
    }

    .news_slider.swiper {
        width: 100%;
        height: 450px;
    }

    .news_img_dv {
        width: 100%;
        float: left;
        height: auto;
    }

    .news_img_dv img {
        height: 176px;
        object-fit: cover;
        object-position: top;
    }

    .news_content {
        width: 100%;
        padding-left: 15px;
        padding-top: 20px;
        padding-right: 15px;
        height: auto;
    }

    .news_sub_ttl {
        font-size: 15px;
        line-height: 23px;    
        margin-bottom: 10px;
    }

    .news_date {
        font-size: 14px;
    }

    .news_ttl {
        font-size: 75px;
        line-height: 70px;
        transform: rotate(0deg);
        writing-mode: inherit;
        height: auto;
        margin-left: 0;
    }

    .news_slider .swiper-button-prev {
        transform: rotate(0deg);
        left: 0px;
        bottom: 1px;
        top: auto;
    }

    .news_slider .swiper-button-next {
        transform: rotate(0deg);
        left: 60px;
        bottom: 1px;
        top: auto;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .news_container {
        padding-left: 5px;
    }
    .news_sec {
        padding-top: 50px;
        padding-bottom: 80px;
    }
    .news_container .m_0 {
        margin-left: -15px;
        margin-right: -15px;
    }
    .news_row_col2 {
        padding-right: 22px;
    }

    .news_slider_dv {
        width: 100%;
        height: auto;
        display: grid;
    }
    .news_slider_dv a {
        height: auto;
    }
    .news_slider.swiper {
        width: 100%;
        height: 430px;
    }

    .news_img_dv {
        width: 100%;
        float: left;
        height: auto;
    }

    .news_img_dv img {
        height: 200px;
        object-fit: cover;
        object-position: top;
    }

    .news_content {
        width: 100%;
        padding: 20px;
        height: auto;
    }

    .news_sub_ttl {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .news_date {
        font-size: 14px;
    }

    .news_ttl {
        font-size: 35px;
        line-height: 70px;
        transform: rotate(0deg);
        writing-mode: inherit;
        height: auto;
        margin-left: 0;
    }

    .news_slider .swiper-button-prev {
        transform: rotate(0deg);
        left: 0px;
        bottom: 1px;
        top: auto;
    }

    .news_slider .swiper-button-next {
        transform: rotate(0deg);
        left: 60px;
        bottom: 1px;
        top: auto;
    }


}