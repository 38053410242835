.integ_logist {
    padding-bottom: 61px;
}
.integ_imgdv {
    float: right;
}
.integ_img {
    width: 100%;
    margin-bottom: 20px;
    float: right;
    padding-left: 80px;
}
.inet_col1 {
    padding-top: 20px;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
     

    .integ_logist {
        padding-bottom: 50px;
    }
    
    
 
    .integ_img {
        width: 100%;
        margin-bottom: 20px;
        float: none;
        padding-left: 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .integ_img {
        
        padding-left: 30px;
    }
}