.client_sec {
    padding-top: 93px;
    padding-bottom: 115px;
    border-top: 1px solid rgb(161 174 199 / 40%);
}
.auto_logo_div {
    width: 100%;
    max-width: 800px;
    margin: auto; 
}
.auto_logo_div .client_box img {
    width: 100%;
}
.client_box_33 {
    width: 33.33%;
    float: left;
}
.client_box_33 img {
    width:100%;
}
.auto_center {
    width: 100%;
    max-width: 533px;
    margin: auto;
}
.client_box_auto_center {
    width:50%;
    float: left;
}
.client_box_auto_center img {
    width: 100%;
}
.tech_center {
    width: 100%;
    max-width:50%;
    margin: auto;
}
.client_box_tech_center {
    width:50%;
    float: left;
}
.client_box_tech_center img {
    width: 100%;
}

.fmcg_logo_div {
    width: 100%;
    max-width: 800px;
    margin: auto;   
}

.client_box img {
    width:100%;
}


.clnt_tb_animate {
    opacity:0;
}
.client_container {
    max-width: 1120px;
    width: 100%;
    margin: auto;
    position: relative;
}

.client_logo_tab {
    margin-top: 55px;
}

.client_col1 {
    padding-top: 56px;
    border-right: 1px solid #D8D8D8;
}
.client_sec .hd_dv {
    margin: auto;
}
.client_row_tab {
    width: 100%;
    border-bottom: 3px solid #A1AEC7;
    position: relative;
}

.client_row_tab .tab-indicator {
    width: calc(100% / 6);
    height: 3px;
    background-color: #FF7900;
    position: absolute;
    top: auto;
    bottom: -3px;
    left: 0px;
    transition: all 500ms ease-in-out;
}

.client_tb_link {
    width: 16.66%;
    padding: 0 0 6px 0;
    border: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0 !important;
    appearance: unset;
    -webkit-appearance: unset;
}

.client_tb_link:focus-visible,
.client_tb_link:focus {
    outline: none !important;
}

.client_tb_link.active h3 {
    color: #FF7900;
}

.client_tb_link h3 {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #A1AEC7;
    font-weight: 300;
}

.client_box {
    width: 25%;
    float: left;
}

.client_mob {
    display: none;
}

.client_select {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 1px solid rgb(161 174 199 / 40%);
    height: 35px;
    font-family: 'Red Hat Display';
    padding-left: 10px;
    background:transparent;
    border-radius: 0px;
}
.client_select:focus {
    outline:none;
}
.myDiv {
    display: none;
    text-align: center;
}

.myDiv img {
    margin: 0 auto;
}
div#showOne {
    display: block;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .client_tb_link h5 {
        font-size: 16px;
    }

    .client_box img {
        width: 100%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .client_tb_link {
        width: 33.33%;
    }

    .client_logo_tab .tab-indicator {
        display: none;
    }

    .client_box img {
        width: 100%;
    }

    .client_logo_tab {
        display: none;
    }

    .client_mob {
        display: block;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .client_logo_tab {
        margin-top: 30px;
    }

    .client_tb_link {
        width: 100%;
    }

    .client_box {
        width: 50%;
        float: left;
    }

    .client_box img {
        width: 100%;
    }

    .client_logo_tab {
        display: none;
    }

    .client_mob {
        display: block;
    }

    .client_sec {
        padding-top: 50px;
        padding-bottom: 50px;
       
    }

   
}