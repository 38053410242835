.page_spotlight {
    width: 100%;
    height: 646px;
    position: relative;
    /* background: linear-gradient(103.56deg, #9642B3 0.97%, #232394 76.77%); */
}

.spotlight_container {
    max-width: 84%;
    margin-left: auto;
}

.spotlight_container .row {
    margin: 0px;
}

.page_spotlight div {
    height: 100%;
}

.page_spotlight::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* opacity: 0.3; */
}

.page_spotlight span.arrow_btn::after {
    transform: translateX(50%) translateY(-50%) rotate(90deg);
}

.page_spotlight span.arrow_btn::before {
    transform: translateX(50%) translateY(-50%) rotate(90deg);
}

/* pages spotlight BG Images */
.about_spotlight::before {
    background-image: url('../img/about_spot.jpg');
}

.warehouse_spotlight::before {
    background-image: url('../img/warehousing_bg.jpg');
}

.landtransport_spotlight::before {
    background-image: url('../img/services/land-transport/spotlight/spotlight.jpg');
}

.freight_spotlight::before {
    background-image: url('../img/services/freight-forwarding/spotlight/spotlight.jpg');
}

.infra_spotlight::before {
    background-image: url('../img/services/infrastructure-for-you/spotlight/spotlight.jpg');
}

.supplychain_spotlight::before {
    background-image: url('../img/services/supply-chain-consulting-services/spotlight/spotlight.jpg');
}

.asc_edge_spotlight::before {
    background-image: url('../img/asce_edge_spotlight.jpg');
}

.controltower_spotlight::before {
    background-image: url('../img/services/control-tower-and-analytics-center/spotlight/spotlight.jpg');
}

.ascedge_spotlight::before {
    background-image: url('../img//asc-edge/spotlight/spotlight.jpg');
}

.sec_spotlight::before {
    background-image: url('../img/sectors/consumer_durables_high-tech_industries/spotlight/spotlight.jpg');
}


.automotive_spotlight::before {
    background-image: url('../img/sectors/automotive_and_industrial_sector/spotlight/spotlight.jpg');
}

.other_spotlight::before {
    background-image: url('../img/sectors/other_sector/spotlight.jpg');
}
.lifesytle_spotlight::before {
    background-image: url('../img/sectors/other_sector/lifeastyle_banner.jpg');
}

.ecommerce_spotlight::before {
    background-image: url('../img/sectors/e-commerce_and_fmcg/spotlight/spotlight.jpg');
}

.leadership_spot::before {
    background-image: url('../img/leader_spot.jpg') !important;
}

.life_asc_spotlight::before {
    background-image: url('../img/careers_spotlight.jpg');
}
.desktop_spot_video {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    object-fit: cover;
}
.ipad_spot_video {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
}

.contact_spotlight::before {
    background-image: url('../img/contact_us_spot.jpg');
}

.media_spotlight::before {
    background-image: url('../img/media_center_spot.jpg');
}

.blog_spotlight::before,
.event_spotlight::before,
.press_release_spotlight::before {
    background-image: url('../img/media_center_spot.jpg');
}


.spot_col2.about_hyp {
    padding: 27px 50px 27px 50px;
}

.newspot_warehouse {
    height: 750px;
}

.newspot_warehouse .spot_col2 {
    padding: 25px 40px;
}

.newspot_warehouse .lst_prnt::after {
    display: none;
}

.newspot_surface {
    height: 750px;
}

.newspot_surface .spot_col2 {
    padding: 25px 40px;
}

.newspot_surface .lst_prnt::after {
    display: none;
}


.spotlight_dv {
    position: relative;
}

.spotlight_dv .spot_contnt {
    position: absolute;
    width: 100%;
    height: auto;
    top: auto;
    bottom: 0;
}

.service_spotlight .spot_contnt {
    height: auto;
}

.control_spot .spot_contnt {
    height: 300px;
}

.spot_col1 {
    width: 46%;
    float: left;
    padding-right: 30px;
}

.spot_sb_ttl {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #FF7900;
}

.spot_col2 {
    width: 54%;
    float: left;
    background: #fff;
    padding: 60px 50px 60px 50px;
}

.spot_col2 p {
    max-width: 500px;
    width: 100%;
    margin-bottom: 50px;
}

/*----------------------------- Media Spotlight CSS Start    */
.media_spotlight .spot_col1 {
    width: 60%;
}

.media_spotlight .spot_col2 {
    width: 40%;
}

.blog_inside_spot .spot_contnt {
    height: 250px !important;
}

.media_spotlight .media_hd {
    font-size: 42px;
    line-height: 54px;
}

.media_dtl_list {
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 20px;
}

.media_dtl_list li {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #FF7900;
    margin-right: 50px;
}

.media_dtl_list li img {
    margin-right: 11px;
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(85%);
}

.blog_spot_col1 {
    width: 90% !important;
    max-width: 1050px;
}

/*----------------------------- Media Spotlight CSS End    */

/*----------------------------- Service Spotlight CSS Start    */

.offer_text {
    color: #fff !important;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #FFFFFF;
}


.warehouse_list {
    padding-left: 5px;
    width: 100%;
    max-width: 454px;
    margin-bottom: 0;
}

.warehouse_list a:last-child li {
    border-bottom: 0px;
}

.service_listing {
    color: #101021;
    list-style-type: none;
    /* border-bottom: 1px solid rgb(255 255 255 / 10%); */
    border-bottom: 1px solid #10101e21;
    font-size: 18px;
    font-family: 'oms';
    font-style: normal;
    font-weight: 250;
    line-height: 61px;
    position: relative;
}

.service_listing::after {
    content: "";
    position: absolute;
    right: 10px;
    background-image: url('../img/svg/dropdown_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    top: 22px;
    filter: invert(0.7);
}

.service_listing:hover {
    color: #FF7900;
}

.warehouse_list a:hover {
    text-decoration: none;
}

/*----------------------------- Service Spotlight CSS Start    */

/*----------------------------- The ASC Edge Spotlight CSS Start    */
.asc_edge_spot_sub_ul {
    padding-left: 0;
}

.asc_edge_spot_sub_ul a {
    color: #101021;
    list-style-type: none;
    font-size: 18px;
    font-family: 'oms';
    font-style: normal;
    font-weight: 250;
    line-height: 61px;
    position: relative;
    display: block;
    padding-left: 20px;
}

.asc_edge_spot_sub_ul a:hover {
    color: #FF7900;
}

.desktop_view {}

.mobile_view {
    display: none;
}

/*----------------------------- The ASC Edge Spotlight CSS End    */


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
    .spotlight_container {
        max-width: 97.2%;
    }

    .spot_col1 {
        width: 50%;
    }

    .spot_col2 {
        width: 50%;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 55%;
    }

    .media_spotlight .spot_col2 {
        width: 45%;
    }

    /*----------------------------- Media Spotlight CSS End    */

}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .spotlight_container {
        max-width: 98%;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 50%;
    }

    .media_spotlight .spot_col2 {
        width: 50%;
    }

    /*----------------------------- Media Spotlight CSS End    */
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
    .spotlight_container {
        max-width: 95.7%;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 50%;
    }

    .media_spotlight .spot_col2 {
        width: 50%;
    }

    .spot_col2.infras_spot {
        padding: 60px 50px 62px 50px;
    }
    .newspot_surface .spot_col2 {
        padding: 25px 40px;
    }

    /*----------------------------- Media Spotlight CSS End    */
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
    .spotlight_container {
        max-width: 92.5%;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 50%;
    }

    .media_spotlight .spot_col2 {
        width: 50%;
    }

    /*----------------------------- Media Spotlight CSS End    */
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
    .spotlight_container {
        max-width: 91%;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 50%;
    }

    .media_spotlight .spot_col2 {
        width: 50%;
    }

    /*----------------------------- Media Spotlight CSS End    */
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1080px) { 

    .life_asc_spotlight  {
        height: 450px !important;
    }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1024px) {
    
    .ipad_spot_video {
        display: block;
    }
    .desktop_spot_video {
        display: none;
    }

    .life_asc_spotlight  {
        height: 425px !important;
    }
    
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .spotlight_container {
        max-width: 95%;
    }

   

    .spot_sb_ttl {
        font-size: 22px;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 65% !important;
    }

    .media_spotlight .spot_col2 {
        width: 35%;
    }

    .media_spotlight .media_hd {
        font-size: 42px;
        line-height: 54px;
    }

    .media_dtl_list li img {
        margin-top: -4px;
    }

    .offer_text {
        font-size: 18px;
    }

    .blog_inside_spot .spot_contnt {
        height: 250px !important;
    }


    /*----------------------------- Media Spotlight CSS End    */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .spotlight_container {
        max-width: 97%;
    }
    .ipad_spot_video {
        display: block;
    }
    .desktop_spot_video {
        display: none;
    }

    .life_asc_spotlight  {
        height: 320px !important;
    }


    .service_spotlight .spot_contnt {
        height: 350px;
    }

    .newspot_warehouse .spot_contnt {
        height: auto;
    }
    .newspot_surface .spot_contnt {
        height: auto;
    }

    .spot_col1 {
        width: 52%;
    }

    .spot_col2 {
        width: 48%;
        padding: 27px 30px 27px 30px !important;
    }

    .spot_sb_ttl {
        font-size: 20px;
        line-height: 27px;
    }

    /*----------------------------- Media Spotlight CSS Start    */
    .media_spotlight .spot_col1 {
        width: 75%;
    }

    .media_spotlight .media_hd {
        font-size: 35px;
        line-height: 44px;
    }

    .media_spotlight .spot_col2 {
        display: none;
    }

    .media_spotlight .media_hd {}

    .media_dtl_list {}

    .media_dtl_list li {
        margin-right: 35px;
        font-size: 17px;
    }

    .media_dtl_list li img {
        margin-right: 8px;
        width: 17px;
        margin-top: -4px;
    }

    .media_spotlight .spotlight_dv .spot_contnt {
        bottom: 50px;
    }

    .blog_inside_spot .spot_contnt {
        height: auto !important;
    }

    /*----------------------------- Media Spotlight CSS End    */

    /*----------------------------- Service Spotlight CSS Start    */

    .service_listing {
        padding: 15px 0px;
        line-height: 26px;
        font-size: 14px;
    }

    /*----------------------------- Service Spotlight CSS End    */

    /*--------------------------- infra spotlight */
    .spot_col2.infras_spot {
        padding: 65px 35px;
    }

    /*------------------------- end-infra spotlight */

    /*----------------------------- The ASC Edge Spotlight CSS Start    */
    .asc_edge_spot_sub_ul a {
        line-height: 26px;
        font-size: 17px;
    }

    /*----------------------------- The ASC Edge Spotlight CSS End    */

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .page_spotlight {
        height: 260px;
    }
    .ipad_spot_video , .desktop_spot_video {
        display: none;
    }

    .spot_col2 {
        display: none;
    }

    .service_listing.abt_hyp a:last-child li {
        padding-right: 16px;
    }

    .spotlight_container {
        max-width: 95%;
    }

    .spotlight_dv .spot_contnt {
        width: 100%;
        height: auto;
        bottom: 15px;
    }

    .offer_text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
    }

    .spot_col1 {
        width: 100% !important;
        float: left;
        margin-bottom: 15px;
        padding-right: 40px;
    }

    .spot_col2 {
        width: 100%;
        padding: 22px !important;
    }

    .spot_col2 p {
        margin-bottom: 25px;
    }

    .spot_sb_ttl {
        font-size: 17px;
        line-height: 19px;
    }

    .spot_heading {
        margin-bottom: 3px;
    }

    /*----------------------------- Media Spotlight CSS Start    */

    .blog_inside_spot {
        height:350px !important;
    }
    .media_spotlight .spot_col1 {
        width: 100%;
        padding-right: 30px;
    }

    .media_spotlight .media_hd {
        font-size: 22px;
        line-height: 31px;
    }

    .media_spotlight .spot_col2 {
        display: none;
    }

    .media_spotlight .media_hd {}

    .media_dtl_list {
        display: block;
    }

    .media_dtl_list li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        width: 43%;
        float: left;
        margin-right: 0;
    }

    .media_dtl_list li img {
        width: 16px;
        margin-top: -3px;
        margin-right: 8px;
    }

    .mi_spot .spot_contnt {
        height: auto !important;
    }


    /*----------------------------- Media Spotlight CSS End    */

    /*----------------------------- Service Spotlight CSS Start    */

    .service_spotlight.page_spotlight {
        height: 350px;
    }
    .freight_spotlight {
        height: 260px !important;
    }
    .service_listing {
        font-size: 14px;
        line-height: 22px;
        width: 90%;
        padding: 15px 0px;
    }

    /*----------------------------- Service Spotlight CSS End    */


    /* ------------supplychain spotlight */

    .heading.wht_txt.supply_head {

        line-height: 30px;
    }

    /* -----------end supplychain spotlight */

    /*----------------------------- The ASC Edge Spotlight CSS Start    */

    .asc_edge_spot_sub_ul a {
        line-height: 22px;
        font-size: 14px;
    }

    .desktop_view {
        display: none;
    }

    .mobile_view {
        display: block;
    }

    /*----------------------------- The ASC Edge Spotlight CSS End    */

}