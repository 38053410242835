.leadership_sec {
    padding-top: 93px;
    padding-bottom: 55px;
    background-color: #f5f5f5;
}

.leader_row .leader_outer:nth-child(2) {
    display: none;
}
.raja_profile_row {
    margin-right:15px;
    margin-bottom: 30px;
    height: auto;
}

.leader_sec_hd_col {
    margin-bottom: 110px;
}

.leader_outer {
    margin-bottom: 30px;
}

.leader_inner {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.leader_inner:hover .leader_back_dv {
    transform: translateY(0%);
    opacity: 1;
}

.leader_inner:hover .leader_front_dv {
    opacity: 0;
}

img.leader_img {
    width: 100%;
}

.leader_front_dv {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: 0;
    background: rgba(47, 47, 109, 0.85);
    backdrop-filter: blur(5px);
    padding: 10px 35px;
    opacity: 1;
    transition: all .4s ease-in-out;
}

.lead_front_name {
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-bottom: 0;
}

.lead_front_desig {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.leader_back_dv {
    position: absolute;
    top: auto;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 109, 0.85);
    backdrop-filter: blur(10px);
    padding: 50px 36px 65px 36px;
    transform: translateY(100%);
    opacity: 0;
    transition: all .4s ease-in-out;
}

.lead_back_name {
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #FF7900;
}

.lead_back_desig {
    font-weight: 200;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
}

.lead_back_dtls {
    max-height: 290px;
    height: 100%;
    overflow-y: auto;
    margin-top: 30px;
    padding-right: 30px;
    max-width: 550px;
    width: 100%;
}

.lead_back_dtls p {
    color: #FFFFFF;
}

.lead_back_dtls::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.lead_back_dtls::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
    background-color: #fff;
}

/* Handle */
.lead_back_dtls::-webkit-scrollbar-thumb {
    background: #FF7900;
    border-radius: 5px;
}

/* Handle on hover */
.lead_back_dtls::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}


.raja_profile {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
}




@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .lead_back_dtls {
        max-height: 250px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .lead_back_dtls {
        max-height: 295px;
    }
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
    .lead_back_dtls {
        max-height: 295px;
    }
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
    .lead_back_dtls {
        max-height: 295px;
    }
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
    .lead_back_dtls {
        max-height: 295px;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .leader_back_dv {
        padding: 50px 36px;
    }

    .lead_back_dtls {
        max-height: 190px;
        margin-top: 20px;
    }

    .lead_front_desig {
        font-size: 16px;
        line-height: 25px;
    }

    .lead_back_desig {
        font-size: 17px;
        line-height: 27px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .leader_sec_hd_col {
        margin-bottom: 40px;
    }

    .lead_front_name {
        font-size: 20px;
    }

    .lead_front_desig {
        font-size: 15px;
        line-height: 23px;
    }

    .leader_back_dv {
        padding: 30px;
    }

    .lead_back_name {
        font-size: 21px;
        line-height: 24px;
    }

    .lead_back_desig {
        font-size: 15px;
        line-height: 18px;
    }

    .lead_back_dtls {
        margin-top: 15px;
        padding-right: 15px;
        max-height: 410px;
        max-width: 100%;
    }

    .raja_profile_row .lead_back_dtls {
        max-height: 215px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .leader_sec_hd_col {
        margin-bottom: 30px;
    }

    .leader_outer {
        margin-bottom: 30px;
    }

    .lead_front_name {
        font-size: 20px;
    }

    .lead_front_desig {
        font-size: 15px;
        line-height: 22px;
    }

    .leader_back_dv {
        padding: 20px;
    }

    .lead_back_name {
        font-size: 21px;
        line-height: 24px;
    }

    .lead_back_desig {
        font-size: 15px;
        line-height: 18px;
    }

    .lead_back_dtls {
        margin-top: 15px;
        padding-right: 15px;
        max-height: 140px;
    }

    .raja_profile_row {
        margin-left: 15px;
    }
    .raja_profile_row div {
        height: auto;
    }
    .raja_profile_row .col-12:nth-child(1) {
        padding-left: 0px;
    }
    .raja_profile_row .aes_col1 , .raja_profile_row .aes_col1 img {
        height: auto;
    }
    .raja_profile_row .aes_col2 {
        padding:30px 10px 30px 25px;
    }

}