.solution_offer_mob .row {
    margin: 0;
}

.accordion_solution {
    width: 100%;
    margin-top: 35px;
}

.accordion_solution .card {
    border-radius: 0px;
    background: transparent;
    border: 0px;
}

#accordion .card-body {
    margin-top: 25px;
}

.accordion_solution .card-header {
    height: 70px;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
    border-radius: 0px;
}

.solution_offer_mob .icons_span img {
    filter: brightness(0) invert(1);
}

.accordion_solution .card-header button {
    width: 100%;
    height: 100%;
    border: 0px;
    background: transparent;
    padding: 12px 22px;
    float: left;
    text-align: left;
    font-family: 'oms';
}

span.icons_span {
    margin-right: 40px;
}

.solution_name {
    font-weight: 250;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    transition: all 0.5s;
    width: fit-content;
    display: inline-table;
}

span.status_icon {
    position: relative;
    float: right;
}

img.sol_plus_icon {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all 0.5s;
}

img.sol_minus_icon {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all 0.5s;
}

.accordion_solution .card-header button:not(.collapsed) .sol_minus_icon {
    opacity: 1;
}

.accordion_solution .card-header button:not(.collapsed) {
    background: #FF7900;
}

.accordion_solution .card-header button:not(.collapsed) .solution_name {
    color: #101021;
}

.accordion_solution .card-header button:not(.collapsed) .icons_span img {
    filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0%) contrast(98%);
}

.accordion_solution .card-header button.collapsed .sol_plus_icon {
    opacity: 1;
}

.accordion_solution .card-body {
    display: inline-block;
    padding: 20px 0px 40px 0;
}

.solution_offer_mob .accordion_solution .card-body {
    width: 95%;
}
.sol_animate1  {
    font-weight: 400;
}

.accordion_solution .card-body {
    padding-top: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .accordion_solution .card-header button {
        padding: 12px !important;
    }
    .accordion_solution .card-header {
        height: 70px;
    }    
    .sol_animate1 {
        font-size: 27px;
        line-height: 24px;
        font-weight: 400;
    }

    .solution_offer_mob .icons_span img {
        filter: brightness(0) invert(1);
        width: 23px;
    }
    .solution_name {
        font-size: 12px;
    }

}