.freight_frwd_pg .customised_servc_ware {
  display: none;
}


.Headd {
  opacity: 0;
  pointer-events: none;
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background: #fff;
  width: 100%;
  height: 100%;
  opacity: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-screen video {
  width: 100px;
}

.animate_logo_dv {
  width: 200px;
  height: auto;
  position: relative;
  overflow: hidden;
}

.animate_logo_dv img {
  width: 97%;
}

.animate_logo_sld {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 0.8s;
}

p#thnk_box1 {
  text-align: left;
}


.animate_logo_dv svg {
  width: 100%;
  height: 144px;
  stroke-width: .5px;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;

}

.animate_logo_dv .logo_bar {
  fill: #fff;
  stroke: #fff;
  fill-opacity: 0;
  animation: logo-draw 1s linear forwards, logo-fade 0.8s linear 0.5s forwards;
}
.animate_logo_dv .logo_sub_ttl{
  fill:#FF7900;
  stroke:#FF7900;
  fill-opacity: 0;
  animation: logo-draw 1s linear forwards, logo-fade 0.8s linear 0.5s forwards;
}
.animate_logo_dv .logo_tip {
  fill: #101041;
  stroke: #101041;
}
.asc_loader {
  width:200px;
  height:150px;
}
.asc_loader img {
  margin-top: 0px;
}
.asc_loader div {
  background-color: transparent !important;
}
@keyframes logo-draw {
  from {
    stroke-dashoffset: 300;
  }

  to {
    stroke-dashoffset: 0;
    stroke-width: 0px;
  }
}

@keyframes logo-fade {
  to {
    fill-opacity: 1;
  }
}


/* heading Text animation */
.cssanimation, .cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
  text-transform: inherit;
}

.cssanimation span { display: inline-block }

.leFadeInRight span { animation-name: leFadeInRight }
@keyframes leFadeInRight {
  from {
      opacity: 0;
      transform: translateX(60px);
  }
  to { opacity: 1 }
}


/*------------------- Global Forms Style Start  */


.input_fld {
  height: 24px;
  border: 0px;
  border-bottom: 1px solid rgb(75 75 75 / 20%);
  margin-bottom: 40px;
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #2727A3;
  width:100%;
  background: transparent;
}
.input_fld:focus {
  outline: none;
}

.input_fld::placeholder {
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #2727A3;
}
.input_btn {
  padding: 0;
  position: relative;
  border-radius: 0px;
}
.input_btn input{
  background-color: transparent;
  padding: 19px;
  border: 0px;
  color: #2727A3;
  font-family: 'Red Hat Display';
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  position: relative;
}
.input_btn:hover input {
  color: #FF7900;
} 

/*------------------- Global Forms Style End  */


/*---------------------------------------------------------------- Mobile CSS */
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {

  .animate_logo_dv {
    width: 120px;
    height: auto;
  }

}