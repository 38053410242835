.event_sec .bf_bx_ttl {
  font-weight: 250;
  font-size: 17px;
  line-height: 28px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 0;
}
#event_row h2 {
  font-weight: 300;
}
.event_sec .bf_col {

  margin-bottom: 50px;
}
.event_bx .fb_arrow {
  position: absolute;
  right: 13px;
  bottom: 39px;
  width: 29px;
}
div#nav-news , div#nav-event {
  margin-top: 50px;
}
div#event_row {
  margin: 0;
  justify-content: center;
}
.event_bx a {
  text-decoration: none;
}
.pagination {
  justify-content: center;
}
.pagination ol li a.active {
  color: #FFF34A;
  background: #101041;
}
.pagination ol li a.active:hover {
  color: #FFF34A;
  background: #101041;
}
.pagination ol {
  list-style: none;
  display: flex;
  box-shadow: 0px 0px 5px -3px #000;
  padding: 0;
  border-radius: 5px;
  margin-top: 30px;
}

.pagination ol li a{
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color:#101041;
  border-right: 1px solid #dbdcdd;
  /* transition: all 0.5s; */
}
.pagination ol li:last-child a {
  border-right: 0px;
  border-top-right-radius:5px;
  border-bottom-right-radius: 5px;
}
.pagination ol li:first-child a{
  border-top-left-radius:5px;
  border-bottom-left-radius: 5px;
}
.pagination ol li a:hover,
.pagination-button:not(.disabled):hover {
  /* background: #101041; */
  color:#2727A3;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .event_bx  .fb_arrow {
      right: 15px;
      bottom: 20px;
      width: 25px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .event_bx .fb_arrow {
      right: 15px;
      bottom: 20px;
      width: 25px;
  }

}