.plant_warehouse {
    background: #FFFFFF;
    padding-top: 108px;
    padding-bottom: 108px;
}
.in_plant_sub_hd , .yard_sub_hd {
    font-weight: 900 !important;
}
.so_col_title {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #101021;
    margin-bottom: 21px;

}

.so_col_title_1 {
    font-family: 'oms';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    color: #2727A3;
}

.so_col1_main {
    width: 100%;
    color: #101021;
    padding-right: 136px;

}

.so_col1.img_planting {
    padding-left: 0px;
}

.plant_col_para,
.agile_content .plant_col_para {
    color: #101021 !important;
}

.plant_col_para_1 {

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
}

.col_img_plantyard {
    /* margin-bottom: 21px; */
    width: 100%;
    margin-bottom: 20px;
}

.col_img_agileyard {
    max-width: 470px;
    width: 100%;

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .plnt_inner_col1 {
        margin-bottom: 50px;
    }

    .plant_warehouse {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .col_img_plantyard {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .col_img_agileyard {
        margin-bottom: 20px;
    }

    .so_col_title {
        font-size: 22px;
        line-height: 30px;
    }

    .so_col_title_1 {
        font-size: 14px;
    }

    .so_col1_main {
        padding-right: 0px;
    }

    .so_col1.img_planting {
        margin-top: 0px;
    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .so_col1_main {

        padding-right: 0px;
    }

    .so_col1.img_planting {
        padding-left: 0px;
        margin-top: 15px;
    }

    .col_img_plantyard {

        margin-bottom: 30px;
        max-width: 470px;
    }

    .col_img_agileyard {

        margin-bottom: 30px;
    }

    .plant_warehouse {
        padding-top: 50px;
        padding-bottom: 50px;
    }



}


@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .so_col1_main {
        padding-right: 0px;
    }

    .plant_warehouse {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .so_col1_main {

        padding-right: 51px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}