.advantage_control_sec {
    padding-top: 115px;
    padding-bottom: 50px;
    position: relative;
    /* background-image: url("../img/advantage_control.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    height: 471px;
    width: 100%;
}

.adv_para {
    font-family: 'oms';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #FFFFFF;

}

.adv_listing {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;

    color: #FFFFFF;

}

ul.global_list li {
    position: relative;
    padding-top: 12px;
    padding-left: 30px !important;
}

.info_para_cust {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: -80px;

}

.info_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: 30px;

}

.inside_dv_img {
    padding-top: 30px;
}

.info_para_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-left: auto;
    margin-right: auto;
    max-width: 1319px;
    margin-bottom: 55px;
}


.info_title {
    font-family: 'oms';
    font-style: normal;
    font-weight: 250;
    font-size: 28px;
    line-height: 36.4px;
    color: #2727A3;
    /* margin-left: 82px; */

}

.ware_para {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
    padding-bottom: 8px;
}

.warehouse_bdr {
    border-bottom: 1px solid #A1AEC7;
    margin-bottom: 16px;
}

.info_title_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36.4px;
    color: #2727A3;
    padding-top: 8px;

}

.ware_para_1 {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
}

.info_ware_col_one {
    width: 78%;
}





@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .info_para_1 {

        font-size: 22px;
        line-height: 30px;
    }

    .info_title {

        font-size: 22px;
        line-height: 25.4px;
    }

    .info_title_1 {

        font-size: 22px;
        line-height: 25.4px;

    }

    .adv_para {

        font-size: 26px;

    }

    ul.global_list li {

        font-size: 14px;
        line-height: 22px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .info_ware_col_one {
        width: 100%;
    }

    .info_para_cust {
        font-size: 20px;
        line-height: 30px;
    }

    .info_para {
        font-size: 20px !important;
        line-height: 30px;
    }

    .info_para_1 {
        font-size: 18px;
        line-height: 25px;
    }

    .info_title {
        font-size: 20px;
        line-height: 25.4px;
    }

    .info_title_1 {
        font-size: 20px;
        line-height: 25.4px;
    }

    .advantage_control_sec {
        height: auto;
        padding-top: 50px;
    }

    .adv_para {
        font-size: 22px;
    }

    ul.global_list li {
        font-size: 14px;
        padding-top: 5px;
        margin-bottom: 7px;

    }

}