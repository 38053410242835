.apollo_edge_accordion {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding: 80px 0;
}

.ae_accord_para {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
}

.ae_accord_tab {}

.ae_accord_link {
    width: 20%;
    padding-bottom: 20px;
    appearance: unset;
    -webkit-appearance: unset;
}

.apollo_edge_accordion .ae_accord_link .cfs_tb_ttl1 {
    font-weight: 250;
    font-size: 18px;
    line-height: 24px;
}

.ae_accord_tab .tab-indicator {
    width: calc(100% / 5);
}

.ae_accord_para1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}

.ae_acord_img {
    text-align: center;
}

.ae_acord_img img {
    width: auto;
}

.accord_ul_list {
    width: 100%;
    float: left;
}

.accord_ul_list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 12px;
}
.desktop_asc_edge_feature {

}
.mob_asc_edge_feature {
    display: none;
}





@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .ae_accord_link .cfs_tb_ttl1 {
        font-size: 19px;
        line-height: 25px;
    }

    .apollo_edge_accordion {
      
        padding: 50px 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .ae_accord_link {
        width: 20%;
        padding-bottom: 0px;
    }
    .cfs_tab_div {
        padding-bottom:10px;
    }

    .ae_accord_link .cfs_tb_ttl1 {
        font-size: 17px;
        line-height: 25px;
    }

    .ae_accord_tab .cfs_col2 {
        margin-top: 0px;
    }

    .ae_accord_para {
        font-size: 18px;
        line-height: 32px;
    }

    .ae_accord_para1 {
        font-size: 15px;
    }

    .accord_ul_list {
        width: 100%;
    }

    .accord_ul_list li {
        margin-bottom: 0px;
    }

    .ae_acord_img img {
        width: 50%;
    }

    .apollo_edge_accordion {
    
        padding: 50px 0;
    }
    .apollo_edge_accordion .ae_accord_link .cfs_tb_ttl1 {
        font-size: 12px;
        line-height: 21px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .apollo_edge_accordion {
        padding: 50px 0;
    }

    .ae_accord_link {
        width: 100%;
        padding-bottom: 20px;
    }

   
    

    .ae_accord_link .cfs_tb_ttl1 {
        font-weight: 500;
        font-size: 20px;
    }

   
    

    .ae_accord_para {
        font-size: 18px;
        line-height: 32px;
    }

    .ae_accord_para1 {
        font-size: 15px;
    }

    .accord_ul_list {
        width: 100%;
    }

    .accord_ul_list li {
        margin-bottom: 0px;
    }

    .ae_acord_img img {
        width: 50%;
    }
    .apollo_edge_accordion .cfs_tab_div {
        max-width:100%;
    }
    .apollo_edge_accordion .ae_accord_link .cfs_tb_ttl1 {
        font-size: 16px;
    }

    .desktop_asc_edge_feature {
        display: none;
    }
    .mob_asc_edge_feature {
        display: block;
    }
    #accordion_feature span.solution_name {
        width: 100%;
        font-size: 13px;
    }
    #accordion_feature .sol_cnt_desc {
        color: #fff;
    }
    #accordion_feature .f_img img {
        width: 70px;
    }
   
}