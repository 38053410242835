.solutions_offer {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 110px;
    padding-bottom: 100px;
}

.sol_col_pd {
    /* padding: 0 45px; */
}
.solutions_offer .heading {
    margin-bottom: 0px;
}
.tb_bx {
    width: 50%;
    padding: 22px;
    float: left;
    height: 170px;
    position: relative;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.6s;
    cursor: pointer;
}

.tb_full_bx {
    width: 100%;
    height: 170px;
    float: left;
    padding: 0 40px;
}

.tb_prt1 {
    width: 100%;
}

.tb_prt2 {
    position: absolute;
    width: 69%;
    top: auto;
    bottom: 20px;
}
h2.sol_animate {
    font-weight: 400;
}
.sol_name {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 0px;
    transition: all 0.6s;
    font-weight: 250;
}

img.sol_arrow {
    float: right;
    margin-right: -6px;
    transform: translate3d(-10px, 5px, 0px);
    transition: all 0.6s;
    opacity: 0;
}

.sol_icon {
    transition: all 0.2s;
    filter: brightness(0) invert(1);
}

.tb_bx.active {
    background: #FF7900;
}

.tb_bx.active .sol_name {
    color: #101021;
}

.tb_bx.active .sol_icon {
    filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0%) contrast(98%);
}

.tb_bx.active .sol_arrow {
    opacity: 1;
    transform: translate3d(0px, 0, 0px);
}

.tb_bx:hover {
    background: #FF7900;
}

.tb_bx:hover .sol_name {
    color: #101021;
}

.tb_bx:hover .sol_icon {
    filter: invert(0) sepia(14%) saturate(2421%) hue-rotate(203deg) brightness(0%) contrast(98%);
}

.tb_bx:hover .sol_arrow {
    opacity: 1;
    transform: translate3d(0px, 0, 0px);
}



.solutions_tab_content_dv {
    position: relative;
}
.solutions_tab_content_dv #div1 {
    display: block;
}
.sol_contnt {
    display:none;
}
.sol_contnt {
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 30px;
}

.sol_cnt_img {
    width: 100%;
    margin-bottom: 40px;
}

.sol_cnt_ttl {
    font-size: 30px;
    line-height: 39px;
    font-weight: 300;
    color: #FFFFFF;
}
.sol_cnt_desc {
    color: rgb(255 255 255 / 50%);
    margin:26px 0;
}
.solution_offer_mob {
    display: none;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .tb_bx {
        padding: 15px;
        height: 150px;
    }
    .tb_full_bx {
        height: 150px;
    }
    .sol_name {
        font-size: 14px;
        line-height: 20px;
    }
    .solutions_offer {
        padding-bottom: 160px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .solution_offer_desktop {
        display:none;
    }
    .solution_offer_mob {
        display: block;
    }
    .solution_offer_mob .icons_span img {
        filter: brightness(0) invert(1);
        width: 27px;
    }
    .solution_name {
        width: fit-content;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  
    .solutions_offer {
   
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .solutions_offer .heading {
        margin-bottom: 10px;
    }
    
    .sol_col_pd {
        order: 2;
    }
    .tb_full_bx {
        height: 55px;
        padding: 0 0px;
    }
    .tb_full_bx h2 br {
        display: none;
    }
    .tb_bx {
        height: 162px;
    }
    .sol_name {
        font-size: 14px;
        line-height: 20px;
    }
    .solutions_tab_content_dv {
        height: 400px;
    }
    .solution_offer_desktop {
        display:none
    }
    .solution_offer_mob {
        display: block;
    }
    img.minus_icon {
        top: 4px !important;
    }

    .sol_cnt_img {
        margin-bottom: 20px;
    }
    .sol_cnt_ttl {
        font-size: 20px; 
        line-height: 28px;
    }
    .sol_cnt_desc {
        margin: 0px 0 20px 0;
    }

}
