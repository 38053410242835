.ph_col1 {
    display: flex;
    align-self: center;
    padding-left:50px;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .ph_col1 {
        padding-left: 14px;
    }
    
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .ph_col1 {
        padding-left: 14px;
    }
    .pharma_sec p.apollo_tech_para {
        margin-top: 10px;
    }
    .ph_inner_dv {
        margin-top: 20px;
    }
    
    

}



