.journay_growth_sec {
    background: linear-gradient(289.26deg, rgba(202, 98, 4, 1) -8.51%, rgba(47, 47, 109, 1) 48.45%);
    width: 100%;
    padding: 100px 0 110px 0;
    position: relative;
}


.graph_div {
    position: relative;
    margin-top: 90px;
}

.stage {
    position: relative;
    overflow: hidden;
}

.heading.jg_head {
    margin-bottom: 24px !important;
}

.stage .hero {

    position: absolute;
    width: 40px;
    height: 40px;
    offset-path: var(--d);
    offset-rotate: 90deg auto;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50px;
    transition: 1s;
    offset-distance: 7.2%;
    /* animation: pulse 1.5s linear infinite; */
    animation: pulse 1.5s infinite;
}

.stage .hero::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    background: var(--danger-color);
    border-radius: 50%;
}

.stage .hero.check-1 {
    offset-distance: 16.4%;
}

.stage .hero.check-2 {
    offset-distance: 26.6%;
}

.stage .hero.check-3 {
    offset-distance: 37.4%;
}

.stage .hero.check-4 {
    offset-distance: 47.3%;
}

.stage .hero.check-5 {
    offset-distance: 57.7%;
}

.stage .hero.check-6 {
    offset-distance: 68%;
}

.stage .hero.check-7 {
    offset-distance: 79%;
}

.stage .hero.check-8 {
    offset-distance: 91%;
}

/* .stage .hero.check-9 {
    offset-distance: 91%;
} */
.stage .checkpoints {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    background-image: url('../img/graph.png');
    background-repeat: no-repeat;
    background-size: cover;
}

ul.global_list {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.global_list li {
    position: relative;
    padding-left: 30px !important;
}

ul.scaling_list li::before {
    content: '';
    position: absolute;
    top: 22px;
    left: 0;
    background-color: #01FF84;
    background-repeat: no-repeat;
    background-size: contain;
    width: 9px;
    height: 1px;
}

.scaling_list.adv_listing {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #FFFFFF;
    letter-spacing: .5px;
}

ul.scaling_list li {
    position: relative;
    padding-top: 12px;
    padding-left: 18px !important;
}

.journay_growth_sec .hd_dv {
    margin: auto;
}

/* .ck_1 {
    transform: translateY(-5px);
}
.ck_2 {
    transform: translateY(-6px);
}
.ck_3 {
    transform: translateY(-6px);
}
.ck_4 {
    transform: translateY(-6px);
}
.ck_5 {
    transform: translateY(-7px);
}
.ck_6 {
    transform: translateY(-9px);
}
.ck_7 {
    transform: translateY(-11px);
}
.ck_8 {
    transform: translateY(-12px);
}
.ck_9 {
    transform: translateY(-16px);
} */
.stage .checkpoints .checkpoint {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    offset-path: var(--d);
}

.stage .checkpoints .checkpoint::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    /* animation: pulse 2s ease-out infinite; */
}

.stage .checkpoints .checkpoint:nth-child(1) {
    offset-distance: 7.2%;
}

.stage .checkpoints .checkpoint:nth-child(2) {
    offset-distance: 16.4%;
}

.stage .checkpoints .checkpoint:nth-child(3) {
    offset-distance: 26.6%;
}

.stage .checkpoints .checkpoint:nth-child(4) {
    offset-distance: 37.4%;
}

.stage .checkpoints .checkpoint:nth-child(5) {
    offset-distance: 47.3%;
}

.stage .checkpoints .checkpoint:nth-child(6) {
    offset-distance: 57.7%;
}

.stage .checkpoints .checkpoint:nth-child(7) {
    offset-distance: 68%;
}

.stage .checkpoints .checkpoint:nth-child(8) {
    offset-distance: 79%;
}

.stage .checkpoints .checkpoint:nth-child(9) {
    offset-distance: 91%;
}


.journay_growth_sec button {
    margin-top: 36px;
    width: 40%;
    float: left;
    margin-right: 20px;
}

/* @keyframes pulse {
    50%, 75% {
        transform: scale(1.2);
    }
    80%, 100% {
        opacity: 0;
    }
} */

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #fff;
    }

    100% {
        box-shadow: 0 0 0 15px transparent;
    }
}

.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
    opacity: 0.6;
}

.graph_arrow_dv {
    position: absolute;
    top: auto;
    bottom: 180px;
    left: 30px;
}

.left_gp_arrow {
    border-radius: 50px;
    width: 60px;
    height: 60px;
    margin: 0;
    margin-right: 15px;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.left_gp_arrow:hover,
.right_gp_arrow:hover {
    /* background-color: #2727A3;
    border-color:#2727A3; */
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.left_gp_arrow:focus,
.right_gp_arrow:focus,
.left_gp_arrow:active,
.right_gp_arrow:active {
    box-shadow: unset;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.right_gp_arrow {
    border-radius: 50px;
    width: 60px;
    height: 60px;
    margin: 0;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.year_number_dv {
    margin-top: 30px;
}

.year_chart {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    font-family: 'Red Hat Display';
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 3px;
}

.year_chart:hover {
    color: #01FF84;
    font-weight: 700;
    border-bottom: 2px solid #01FF84;
    text-decoration: none;
}

.yr_1 {
    margin-left: 45px;
}

.yr_2 {
    margin-left: 15px;
}

.yr_3 {
    margin-left: 30px;
}

.yr_4 {
    margin-left: 40px;
}

.yr_5 {
    margin-left: 15px;
}

.yr_6 {
    margin-left: 27px;
}

.yr_7 {
    margin-left: 10px;
}

.yr_8 {
    margin-left: 15px;
}

.yr_9 {
    margin-left: 15px;
}

.year_chart_slider .swiper-slide {
    text-align: center;
}

.year_chart_slider .swiper-slide.swiper-slide-thumb-active .year_chart {
    color: #01FF84;
    border-bottom: 2px solid #01FF84;
    text-decoration: none;
    font-weight: 700;
}

.graph_slider {
    position: absolute;
    width: 50%;
    height: 100%;
}

.graph_slider .swiper-button-next {
    top: auto;
    bottom: 100px;
    left: 107px;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.graph_slider .swiper-button-prev {
    top: auto;
    bottom: 100px;
    left: 32px;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.graph_slider .swiper-slide {
    opacity: 0 !important;
}

.graph_slider .swiper-slide.swiper-slide-visible.swiper-slide-active {
    opacity: 1 !important;
}

.journy_content {
    padding: 40px 0 0 40px;
    width: 100%;
    max-width: 400px;
    max-width: 550px;
}

.journy_content .jrny_yr {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #01FF84;
}

.journy_content .jrny_ttl {
    font-weight: 250;
    font-size: 40px;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.journy_content .jrny_dscrb {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;

}

.point_hghlight {
    position: absolute;
    top: auto;
    bottom: 0;
    width: 23px;
    background: linear-gradient(180deg, rgba(1, 255, 132, 0) 10%, #01FF84 100%);
    opacity: 0.66;
    transition: all 0.5s;
    transition-delay: 0.15s;
}

.ph1 {
    left: 83px;
    height: 30px;
}

.ph2 {
    left: 212px;
    height: 0px;
}

.ph3 {
    left: 351px;
    height: 0px;
}

.ph4 {
    left: 500px;
    height: 0px;
}

.ph5 {
    left: 634px;
    height: 0px;
}

.ph6 {
    left: 772px;
    height: 0px;
}

.ph7 {
    left: 908px;
    height: 0px;
}

.ph8 {
    left: 1047px;
    height: 0px;
}

.ph9 {
    left: 1190px;
    height: 0px;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .graph_div svg {
        width: 100%;
    }

    .stage .checkpoints {
        transform: translateY(-96%);
        background-size: contain;
    }

    .stage .checkpoints .checkpoint:nth-child(1) {
        offset-distance: 6.6%;
        top: -35px;
    }

    .stage .checkpoints .checkpoint:nth-child(2) {
        offset-distance: 15%;
        top: -35px;
    }

    .stage .checkpoints .checkpoint:nth-child(3) {
        offset-distance: 24.3%;
        top: -37px;
    }

    .stage .checkpoints .checkpoint:nth-child(4) {
        offset-distance: 34.1%;
        top: -39px;
    }

    .stage .checkpoints .checkpoint:nth-child(5) {
        offset-distance: 43.1%;
        top: -41px;
    }

    .stage .checkpoints .checkpoint:nth-child(6) {
        offset-distance: 52.5%;
        top: -46px;
    }

    .stage .checkpoints .checkpoint:nth-child(7) {
        offset-distance: 61.8%;
        top: -50px;
    }

    .stage .checkpoints .checkpoint:nth-child(8) {
        offset-distance: 71.6%;
        top: -58px;
    }

    .stage .checkpoints .checkpoint:nth-child(9) {
        offset-distance: 81.9%;
        top: -71px;
    }

    .point_hghlight {
        bottom: 40px;
    }

    .ph1 {
        left: 75px;
    }

    .ph2 {
        left: 191px;
    }

    .ph3 {
        left: 319px;
    }

    .ph4 {
        left: 454px;
    }

    .ph5 {
        left: 577px;
    }

    .ph6 {
        left: 705px;
    }

    .ph7 {
        left: 827px;
    }

    .ph8 {
        left: 953px;
    }

    .ph9 {
        left: 1082px;
    }




    .stage .hero {
        offset-distance: 6.6%;
        top: -16px;
    }

    .stage .hero.check-1 {
        offset-distance: 15%;
        top: -18px;
    }

    .stage .hero.check-2 {
        offset-distance: 24.3%;
        top: -19px;
    }

    .stage .hero.check-3 {
        offset-distance: 34.1%;
        top: -21px;
    }

    .stage .hero.check-4 {
        offset-distance: 43.1%;
        top: -23px;
    }

    .stage .hero.check-5 {
        offset-distance: 52.5%;
        top: -28px;
    }

    .stage .hero.check-6 {
        offset-distance: 61.8%;
        top: -32px;
    }

    .stage .hero.check-7 {
        top: -39px;
        offset-distance: 71.6%;
    }

    .stage .hero.check-8 {
        offset-distance: 81.9%;
        top: -53px;
    }

    .year_number_dv {
        margin-top: 0;
    }

    .yr_1 {
        margin-left: 40px;
    }

    .yr_3 {
        margin-left: 0px;
    }

    .yr_4 {
        margin-left: 10px;
    }

    .yr_5 {
        margin-left: -10px;
    }

    .yr_6 {
        margin-left: -15px;
    }

    .yr_7 {
        margin-left: -34px;
    }

    .yr_8 {
        margin-left: -45px;
    }

    .yr_9 {
        margin-left: -48px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}


@media only screen and (min-device-width: 707px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .journay_growth_sec .row {
        margin: 0px !important;
    }

    .stage .checkpoints {
        background-image: url('../img/graph.png') !important;
        background-position: bottom;
    }
    .hero , .checkpoint , .point_hghlight{
        display: block !important;
    }

    .heading.jg_head {
        margin-bottom: 8px !important;
    }

    .journay_growth_sec {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .graph_div svg {
        width: 100% !important;
        height: 340px !important;
        visibility: visible !important;
    }

    .stage .checkpoints {
        background-size: contain !important;
    }

    .stage .checkpoints .checkpoint:nth-child(1) {
        offset-distance: 6.3% !important;
        top: -105px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(2) {
        offset-distance: 13.2% !important;
        top: -105px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(3) {
        offset-distance: 20.9% !important;
        top: -108px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(4) {
        offset-distance: 29% !important;
        top: -112px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(5) {
        offset-distance: 36.3% !important;
        top: -117px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(6) {
        offset-distance: 44% !important;
        top: -126px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(7) {
        offset-distance: 51.5% !important;
        top: -136px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(8) {
        offset-distance: 59.5% !important;
        top: -151px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(9) {
        offset-distance: 67.5% !important;
        top: -174px !important;
    }

    .stage .hero {
        offset-distance: 6.3% !important;
        top: -105px !important;
    }

    .stage .hero.check-1 {
        offset-distance: 13.2% !important;
        top: -105px !important;
    }

    .stage .hero.check-2 {
        offset-distance: 20.9% !important;
        top: -108px !important;
    }

    .stage .hero.check-3 {
        offset-distance: 29% !important;
        top: -112px !important;
    }

    .stage .hero.check-4 {
        offset-distance: 36.3% !important;
        top: -117px !important;
    }

    .stage .hero.check-5 {
        offset-distance: 44% !important;
        top: -126px !important;
    }

    .stage .hero.check-6 {
        offset-distance: 51.5% !important;
        top: -136px !important;
    }

    .stage .hero.check-7 {
        offset-distance: 59.5% !important;
        top: -151px !important;
    }

    .stage .hero.check-8 {
        offset-distance: 67.5% !important;
        top: -174px !important;
    }

    .point_hghlight {
        background: linear-gradient(180deg, rgba(1, 255, 132, 0) 30%, #01FF84 100%) !important;
    }

    .ph1 {
        left: 64px !important;
    }

    .ph2 {
        left: 160px !important;
    }

    .ph3 {
        left: 266px !important;
    }

    .ph4 {
        left: 379px !important;
    }

    .ph5 {
        left: 481px !important;
    }

    .ph6 {
        left: 590px !important;
    }

    .ph7 {
        left: 693px !important;
    }

    .ph8 {
        left: 797px !important;
    }

    .ph9 {
        left: 905px !important;
    }

    .year_number_dv {
        margin-top: 10px !important;
    }

    .year_chart {
        font-size: 16px !important;
    }

    .ph1 {
        left: 70px !important;
    }

    .ph2 {
        left: 166px !important;
    }

    .ph3 {
        left: 273px !important;
    }

    .ph4 {
        left: 385px !important;
    }

    .ph5 {
        left: 483px !important;
    }

    .ph6 {
        left: 590px !important;
    }

    .ph7 {
        left: 693px !important;
    }

    .ph8 {
        left: 797px !important;
    }

    .ph9 {
        left: 905px !important;
    }

    .journy_content .jrny_ttl {
        font-size: 30px !important;
    }

    .journy_content .jrny_yr {
        font-size: 17px !important;
        margin-bottom: 5px !important;
    }

    .journy_content .jrny_dscrb {
        font-size: 13px !important;
        line-height: 23px !important;
    }

    .graph_slider .swiper-button-prev {
        top: auto !important;
        bottom: 55px !important;
        left: 32px !important;
    }

    .graph_slider .swiper-button-next {
        top: auto !important;
        bottom: 55px !important;
        left: 93px !important;
    }

    .ath_heading {
        margin-bottom: -57px !important;
    }


}

@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .journay_growth_sec .row {
        margin: 0px !important;
    }

    .stage .checkpoints {
        background-image: url('../img/graph.png') !important;
        background-position: bottom;
    }
    .hero , .checkpoint , .point_hghlight{
        display: block !important;
    }

    .heading.jg_head {
        margin-bottom: 8px !important;
    }

    .journay_growth_sec {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .graph_div svg {
        width: 100% !important;
        height: 340px !important;
        visibility: visible !important;
    }

    .stage .checkpoints {
        background-size: contain !important;
    }

    .stage .checkpoints .checkpoint:nth-child(1) {
        offset-distance: 6.3% !important;
        top: -105px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(2) {
        offset-distance: 13.2% !important;
        top: -105px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(3) {
        offset-distance: 20.9% !important;
        top: -108px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(4) {
        offset-distance: 29% !important;
        top: -112px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(5) {
        offset-distance: 36.3% !important;
        top: -117px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(6) {
        offset-distance: 44% !important;
        top: -126px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(7) {
        offset-distance: 51.5% !important;
        top: -136px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(8) {
        offset-distance: 59.5% !important;
        top: -151px !important;
    }

    .stage .checkpoints .checkpoint:nth-child(9) {
        offset-distance: 67.5% !important;
        top: -174px !important;
    }

    .stage .hero {
        offset-distance: 6.3% !important;
        top: -105px !important;
    }

    .stage .hero.check-1 {
        offset-distance: 13.2% !important;
        top: -105px !important;
    }

    .stage .hero.check-2 {
        offset-distance: 20.9% !important;
        top: -108px !important;
    }

    .stage .hero.check-3 {
        offset-distance: 29% !important;
        top: -112px !important;
    }

    .stage .hero.check-4 {
        offset-distance: 36.3% !important;
        top: -117px !important;
    }

    .stage .hero.check-5 {
        offset-distance: 44% !important;
        top: -126px !important;
    }

    .stage .hero.check-6 {
        offset-distance: 51.5% !important;
        top: -136px !important;
    }

    .stage .hero.check-7 {
        offset-distance: 59.5% !important;
        top: -151px !important;
    }

    .stage .hero.check-8 {
        offset-distance: 67.5% !important;
        top: -174px !important;
    }

    .point_hghlight {
        background: linear-gradient(180deg, rgba(1, 255, 132, 0) 30%, #01FF84 100%) !important;
    }

    .ph1 {
        left: 70px !important;
    }

    .ph2 {
        left: 166px !important;
    }

    .ph3 {
        left: 273px !important;
    }

    .ph4 {
        left: 385px !important;
    }

    .ph5 {
        left: 483px !important;
    }

    .ph6 {
        left: 590px !important;
    }

    .ph7 {
        left: 693px !important;
    }

    .ph8 {
        left: 797px !important;
    }

    .ph9 {
        left: 905px !important;
    }

    .year_number_dv {
        margin-top: 10px !important;
    }

    .year_chart {
        font-size: 16px !important;
    }

    .yr_1 {
        margin-left: 35px !important;
    }

    .yr_2 {
        margin-left: 5px !important;
    }

    .yr_3 {
        margin-left: 0 !important;
    }

    .yr_4 {
        margin-left: 10px !important;
    }

    .yr_5 {
        margin-left: -10px !important;
    }

    .yr_6 {
        margin-left: -15px !important;
    }

    .yr_7 {
        margin-left: -25px !important;
    }

    .yr_8 {
        margin-left: -39px !important;
    }

    .yr_9 {
        margin-left: -45px !important;
    }

    .journy_content .jrny_ttl {
        font-size: 30px !important;
    }

    .journy_content .jrny_yr {
        font-size: 17px !important;
        margin-bottom: 5px !important;
    }

    .journy_content .jrny_dscrb {
        font-size: 13px !important;
        line-height: 23px !important;
    }

    .graph_slider .swiper-button-prev {
        top: auto !important;
        bottom: 55px !important;
        left: 32px !important;
    }

    .graph_slider .swiper-button-next {
        top: auto !important;
        bottom: 55px !important;
        left: 93px !important;
    }

    .ath_heading {
        margin-bottom: -57px !important;
    }


}

@media only screen and (min-device-width: 707px) and (max-device-width: 740px) { 
    .journay_growth_sec .row {
        margin: 0px !important;
    }
    .heading.jg_head {
        margin-bottom: 10px !important;
    }
    .graph_slider {
        width: 75% !important;
    }

    .graph_div svg {
        width: 100% !important;
        height: 390px !important;
        visibility: hidden !important;
    }
    .stage .checkpoints {
        background-image: url('../img/graph_tab.png') !important;
        background-position: bottom !important;
    }
    .hero , .checkpoint , .point_hghlight{
        display: none !important;
    }
    .year_chart {
        font-size: 15px !important  ;
    }
    ul.scaling_list li {
        margin-top: -2px;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .journay_growth_sec .row {
        margin: 0px;
    }

    .heading.jg_head {
        margin-bottom: 8px !important;
    }

    .journay_growth_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .graph_div svg {
        width: 100%;
        height: 350px;
    }

    .stage .checkpoints {
        background-size: contain;
    }

    .stage .checkpoints .checkpoint:nth-child(1) {
        offset-distance: 5.6%;
        top: -96px;
    }

    .stage .checkpoints .checkpoint:nth-child(2) {
        offset-distance: 12.7%;
        top: -97px;
    }

    .stage .checkpoints .checkpoint:nth-child(3) {
        offset-distance: 20.5%;
        top: -101px;
    }

    .stage .checkpoints .checkpoint:nth-child(4) {
        offset-distance: 28.6%;
        top: -105px;
    }

    .stage .checkpoints .checkpoint:nth-child(5) {
        offset-distance: 36.1%;
        top: -110px;
    }

    .stage .checkpoints .checkpoint:nth-child(6) {
        offset-distance: 44%;
        top: -119px;
    }

    .stage .checkpoints .checkpoint:nth-child(7) {
        offset-distance: 51.7%;
        top: -129px;
    }

    .stage .checkpoints .checkpoint:nth-child(8) {
        offset-distance: 59.7%;
        top: -145px;
    }

    .stage .checkpoints .checkpoint:nth-child(9) {
        offset-distance: 68%;
        top: -170px;
    }

    .stage .hero {
        offset-distance: 5.6%;
        top: -96px;
    }

    .stage .hero.check-1 {
        offset-distance: 12.7%;
        top: -97px;
    }

    .stage .hero.check-2 {
        offset-distance: 20.5%;
        top: -101px;
    }

    .stage .hero.check-3 {
        offset-distance: 28.6%;
        top: -105px;
    }

    .stage .hero.check-4 {
        offset-distance: 36.1%;
        top: -110px;
    }

    .stage .hero.check-5 {
        offset-distance: 44%;
        top: -119px;
    }

    .stage .hero.check-6 {
        offset-distance: 51.7%;
        top: -129px;
    }

    .stage .hero.check-7 {
        top: -145px;
        offset-distance: 59.7%;
    }

    .stage .hero.check-8 {
        offset-distance: 68%;
        top: -170px;
    }

    .point_hghlight {
        background: linear-gradient(180deg, rgba(1, 255, 132, 0) 30%, #01FF84 100%);
    }

    .ph1 {
        left: 61px;
    }

    .ph2 {
        left: 160px;
    }

    .ph3 {
        left: 266px;
    }

    .ph4 {
        left: 379px;
    }

    .ph5 {
        left: 481px;
    }

    .ph6 {
        left: 590px;
    }

    .ph7 {
        left: 693px;
    }

    .ph8 {
        left: 797px;
    }

    .ph9 {
        left: 905px;
    }

    .year_number_dv {
        margin-top: 10px;
    }

    .year_chart {
        font-size: 16px;
    }

    .yr_1 {
        margin-left: 35px;
    }

    .yr_2 {
        margin-left: 5px;
    }

    .yr_3 {
        margin-left: 0;
    }

    .yr_4 {
        margin-left: 10px;
    }

    .yr_5 {
        margin-left: -10px;
    }

    .yr_6 {
        margin-left: -15px;
    }

    .yr_7 {
        margin-left: -25px;
    }

    .yr_8 {
        margin-left: -39px;
    }

    .yr_9 {
        margin-left: -45px;
    }

    .journy_content .jrny_ttl {
        font-size: 30px;
    }

    .journy_content .jrny_yr {
        font-size: 17px;
        margin-bottom: 0px;
    }

    .journy_content .jrny_dscrb {
        font-size: 13px;
        line-height: 23px;
    }

    .graph_slider .swiper-button-prev {
        top: auto;
        bottom: 45px;
        left: 32px;
    }

    .graph_slider .swiper-button-next {
        top: auto;
        bottom: 45px;
        left: 93px;
    }

    .ath_heading {
        margin-bottom: -57px !important;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .journay_growth_sec .row {
        margin: 0px;
    }
    .heading.jg_head {
        margin-bottom: 10px !important;
    }
    .graph_slider {
        width: 60%;
    }

    .graph_div svg {
        width: 100%;
        height: 390px;
        visibility: hidden;
    }
    .stage .checkpoints {
        background-image: url('../img/graph_tab.png');
        background-position: bottom;
    }
    .hero , .checkpoint , .point_hghlight{
        display: none;
    }
    .year_chart {
        font-size: 15px;
    }

    /* .stage .checkpoints {
        background-size: contain;
        transform: translateY(0);
        top: 0;
        background-position: bottom;
    }
    .stage .checkpoints .checkpoint {
        width: 7px;
        height: 7px;
    }
    .stage .checkpoints .checkpoint:nth-child(1) {
        offset-distance: 4.2%;
        top: -181px;
    }
    .stage .checkpoints .checkpoint:nth-child(2) {
        offset-distance: 9.5%;
        top: -183px;
    }
    .stage .checkpoints .checkpoint:nth-child(3) {
        offset-distance: 15.2%;
        top: -187px;
    }
    .stage .checkpoints .checkpoint:nth-child(4) {
        offset-distance: 21.3%;
        top: -193px;
    }
    .stage .checkpoints .checkpoint:nth-child(5) {
        offset-distance: 26.9%;
        top: -201px;
    }
    .stage .checkpoints .checkpoint:nth-child(6) {
        offset-distance: 32.6%;
        top: -211px;
    }
    .stage .checkpoints .checkpoint:nth-child(7) {
        offset-distance: 38.2%;
        top: -224px;
    }
    .stage .checkpoints .checkpoint:nth-child(8) {
        offset-distance: 44%;
        top: -243px;
    }
    .stage .checkpoints .checkpoint:nth-child(9) {
        offset-distance: 50%;
        top: -269px;
    }
    .stage .hero {
        offset-distance: 4.2%;
        top: -181px;
        width: 28px;
        height: 28px;
        border: 1px solid #fff;
    }
    .stage .hero.check-1 {
        offset-distance: 9.5%;
        top: -183px;
    }
    .stage .hero.check-2 {
        offset-distance: 15.2%;
        top: -187px;
    }
    .stage .hero.check-3 {
        offset-distance: 21.3%;
        top: -193px;
    }
    .stage .hero.check-4 {
        offset-distance: 26.9%;
        top: -201px;
    }
    .stage .hero.check-5 {
        offset-distance: 32.6%;
        top: -211px;
    }
    .stage .hero.check-6 {
        offset-distance: 38.2%;
        top: -224px;
    }
    .stage .hero.check-7 {
        offset-distance: 44%;
        top: -243px;
    }
    .stage .hero.check-8 {
        offset-distance: 50%;
        top: -269px;
    }

    .point_hghlight {
        width: 18px;
        background: linear-gradient(180deg, rgba(1, 255, 132, 0) 50%, #01FF84 100%);
    }
    .ph1 {
        left: 45px;
    }
    .ph2 {
        left: 118px;
    }
    .ph3 {
        left: 197px;
    }
    .ph4 {
        left: 281px;
    }
    .ph5 {
        left: 358px;
    }
    .ph6 {
        left: 436px;
    }
    .ph7 {
        left: 514px;
    }
    .ph8 {
        left: 591px;
    }
    .ph9 {
        left: 671px;
    }
    .year_number_dv {
        margin-top: 5px;
    }
    .year_chart {
        font-size: 15px;
    }
    .yr_1 {
        margin-left: 25px;
    }
    .yr_2 {
        margin-left: 7px;
    }
    .yr_3 {
        margin-left: 0px;
    }
    .yr_4 {
        margin-left: 6px;
    }
    .yr_5 {
        margin-left: -3px;
    }
    .yr_6 {
        margin-left: -10px;
    }
    .yr_7 {
        margin-left: -18px;
    }
    .yr_8 {
        margin-left: -25px;
    }
    .yr_9 {
        margin-left: -32px;
    } */

    .journy_content {
        padding: 25px 0 0 25px;
        max-width: 100%;
    }

    .journy_content .jrny_ttl {
        font-size: 25px;
        line-height: 25px;
    }

    .journy_content .jrny_yr {
        font-size: 17px;
        margin-bottom: 5px;
    }

    .journy_content .jrny_dscrb {
        font-size: 13px;
        line-height: 22px;
    }

    .graph_slider .swiper-button-next {
        top: auto;
        bottom: 45px;
        left: 85px;
    }

    .graph_slider .swiper-button-prev {
        top: auto;
        bottom: 45px;
        left: 25px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .journay_growth_sec {
        padding: 50px 0 50px 0;
    }
    .heading.jg_head {
        margin-bottom: 10px !important;
    }
    .ath_heading {
        margin-bottom: -57px;
    }

    .journay_growth_sec .row {
        margin: 0px;
    }

    .stage .checkpoints {
        background-image: url('../img/graph_mob.png');
        background-position: bottom;
    }

    .graph_slider {
        width: 85%;
    }

    .graph_div svg {
        width: 100%;
        height: 430px;
        visibility: hidden;
    }
    
    .hero {
        display: none;
    }

    .stage .checkpoints .checkpoint {
        display: none;
    }

    .point_hghlight {
        display: none;
    }

    .journy_content {
        padding: 30px 0 0 20px;
        max-width: 100%;
    }

    .journy_content .jrny_yr {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .journy_content .jrny_ttl {
        font-size: 23px;
        line-height: 25px;
        margin-bottom: 10px;
    }

    .journy_content .jrny_dscrb {
        font-size: 13px;
    }
    ul.scaling_list li {
        font-size: 13px !important;
        margin-bottom: 0px !important;
        padding-left: 25px !important;
    }

    .graph_slider .swiper-button-prev {
        top: auto;
        bottom: 90px;
        left: 21px;
    }

    .graph_slider .swiper-button-next {
        top: auto;
        bottom: 90px;
        left: 80px;
    }

    .year_number_dv {
        margin-top: 0px;
    }

    .year_chart {
        font-size: 11px;
    }

    .yr_1,
    .yr_2,
    .yr_3,
    .yr_4,
    .yr_5,
    .yr_6,
    .yr_7,
    .yr_8,
    .yr_9 {
        margin-left: 0px;
    }
}