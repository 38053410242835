.connect_service {
    padding-top:50px;
    border-top: 1px solid rgb(161 174 199 / 40%);
}

.connect_col {
    padding-top: 50px;
    padding-bottom: 50px;
    border: 1px solid #A1AEC7;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
}

.service_form_bx_hide {
    display: none;
}

.connect_form_sub_ttl {
    color: #101041;
    padding-bottom: 5px;
    
    border-bottom: 2px solid hsl(219deg 25% 71% / 40%);
}

.connect_form {
    width: 100%;
    max-width: 670px;
    margin: auto;
    text-align: center;
}
/* .mr_rght {
    margin-right:35px;
}
.input_fld {
    height: 24px;
    border: 0px;
    border-bottom: 1px solid rgb(75 75 75 / 20%);
    margin-bottom: 40px;
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #2727A3;

}
.input_fld:focus {
    outline: none;
}
.fld_half {
    width:46% !important;
    float: left;
}
.fld_full {
    width:98%;
}
.input_fld::placeholder {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #2727A3;
}
.input_btn {
    padding: 0;
    position: relative;
    border-radius: 0px;
}
.input_btn input{
    background-color: transparent;
    padding: 19px;
    border: 0px;
    color: #2727A3;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    position: relative;
}
.input_btn:hover input {
    color: #FFF34A;
}  */

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .input_btn input {
        padding:15px;
    }
    .mr_rght {
        margin-right: 30px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .connect_col {
        padding: 50px;
    }
    .mr_rght {
        margin-right: 12px;
    }
    .input_btn input {
        padding:15px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .connect_service {
        padding-bottom: 50px;
    }
    img.quote_img {
        width: 50px;
    }
    .connect_col {
        border-top: 1px solid rgb(161 174 199 / 40%);
        padding:30px 20px;
        margin-top: 20px;
    }
    .fld_half {
        width:100% !important;
    }
    .input_btn input {
        padding: 10px 15px;
    }
}



