.sector_spotlight {
    height: 450px;
}
.sector_spotlight .spot_contnt {
    height:200px;
}
.sector_spotlight .spot_col1 {
    width: 50%;
}
.event_spotlight .spot_col1{
    width: 100%;
}
.heading.wht_txt.consume_head{
    font-size: 52px;
    line-height: 62px;
}





@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .sector_spotlight .spot_contnt {
        height: 140px;
    }
    .sector_spotlight .spot_col1 {
        width: 65%;
    }

      
    .heading.wht_txt.consume_head{
      
        font-size: 30px;
        line-height: 44px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .sector_spotlight .spot_contnt {
        height:auto;
    }

    .heading.wht_txt.consume_head{
        font-size: 27px;
        line-height: 37px;
    }
    .sector_spotlight {
        height: 260px;
    }
    
   
}



