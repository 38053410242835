.sol_automo {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 71px;
    padding-bottom: 140px;
}


.solution_cstm {
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 71px;
    padding-bottom: 140px;
}


.solution_cstm_dv {
    margin-top: 70px;
    position: relative;
    /* background: rgba(39, 39, 162, 0.26); */
    background: #fff;
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    padding:45px 44px;
}

.solution_box {
    border-bottom: 1px solid rgba(161, 174, 199, 0.5);
    float: left;
    padding-bottom: 35px;
    margin-bottom: 35px;
}
.solution_inner {
   float: left;
   display: flex;
   gap:12px;
}
.solution_icon_dv {
    float: left;
}
.solution_txt_dv {
    float: left;
}
.solution_txt_dv .solution_bx_hd {
    font-weight: 700 ;
    font-size: 20px;
    line-height: 26px;
    color: #2f2f6d;
    margin-bottom: 10px;
}
.solution_txt_dv p{
    margin-bottom:0px;
}

.sol_line_img {
    position: absolute;
    left: 50%;
    top: -45px;
    height: 950px;

}

.sol_bx {
    padding: 18px 67px 18px 86px;
    background: rgba(39, 39, 162, 0.26);
    background-blend-mode: multiply;
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 30px;
}

.sol_bx p {
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    position: relative;
    color: #FFFFFF;
    margin-bottom: 0;
}

.sol_bx p::before {
    content: '';
    position: absolute;
    top: 10px;
    margin-left: -25px;
    background-image: url('../img/svg/green_tick.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
}

/* Automotive CSS */
.sol_automo .sol_line_img {
    height: 450px;
}

/* Automotive CSS */

/* E-Commerce CSS */
.sol_automo .sol_line_img {
    height: 500px;
}

/* E-Commerce CSS */





@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .sol_bx p {
        font-size: 16px;
    }

    .solution_cstm {

        padding-top: 50px;
        padding-bottom: 100px;
    }
    .solution_icon_dv img {
        width: 25px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .sol_line_img {
        top: -30px;
        height: 980px;
    }

    .sol_bx p {
        font-size: 15px;
        line-height: 26px;
    }

    .solution_cstm {
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .solution_cstm_dv {
        padding: 30px 25px;
    }
    .solution_icon_dv img {
        width:21px;
    }
    .solution_txt_dv .solution_bx_hd {
        font-size: 18px;
        line-height: 24px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .sol_line_img {
        height: 1480px;
    }

    .sol_bx {
        padding: 30px;
    }

    .sol_bx p {
        font-size: 14px;
        line-height: 25px;
    }

    /* Automotive CSS */
    .sol_automo .sol_line_img {
        height: 650px;
    }

    /* Automotive CSS */


    /* E-Commerce CSS */
    .sol_automo .sol_line_img {
        height: 750px;
    }

    /* E-Commerce CSS */

    .solution_cstm {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .sol_automo {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .ecommerce_solution {
        padding-top: 50px;
        padding-bottom: 67px;
    }


    .solution_box {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .solution_cstm_dv {
        padding: 30px 15px;
    }
    .solution_icon_dv img {
        width: 21px;
    }
    .solution_txt_dv .solution_bx_hd {
        font-size: 18px;
    }
    .solution_cstm_dv {
        margin-top: 15px;
    }
}