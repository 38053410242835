.case_study {
    padding: 1px 0;
    background: #101021;
}
.servc_sec_blue {
    background: #101021;
    padding-top: 100px;
    padding-bottom: 100px;
}
.servc_sec_white {
    background: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
}
.cstm_cs_col1 {
    height: 100%;
}
.cstm_cs_col1 .img_dv {
    height:100%;
}
.cstm_cs_col1 img {
    width: 100%;
    height: 100%;
    object-position:bottom;
    object-fit: cover;
}

.cstm_cs_col2 {
    background: #101021;
    padding:68px 88px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.sector_srvc_col2 {
    background-color: #fff;
}
.srvc_sec li{
    color: #101021 !important;
}
.cstm_cs {
    background: #101021 !important; 
}
.cs_cstm_inner {

}
.cs_ttl {
    font-weight: 250;
    font-size: 22px;
    line-height: 36px;
    color: #FF7900;
}

.cs_para {
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    color: #A1AEC7;
    margin-bottom: 0%;
    width:100%;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .cstm_cs_col2 {
        padding: 40px;
    }
    .cs_para {
        font-size: 22px;
        line-height: 39px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .cstm_cs_col2 {
        padding: 40px;
    }
    .cs_para {
        font-size: 22px;
        line-height: 39px;
    }
    .servc_sec_blue .so_col1_optimizatio{
        margin-top: 40px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .cstm_cs_col2 {
        padding: 40px 30px;
    }
    .cs_para {
        font-size: 20px;
        line-height: 35px;
    }
    .servc_sec_blue .so_col1_optimizatio{
        margin-top: 40px;
    }

    .servc_sec_blue, .servc_sec_white {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}