.case_studies_Sec {
    padding:105px 0 140px 0;
    
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 67%, rgba(16,16,33,1) 67%);
}

.cs_hd_col {
    margin-bottom: 37px;
}

.cs_sld_Content {
    padding-top: 122px;
}
.cs_col1 {
    overflow: hidden;
    position: relative;
}

.case_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111021;
    z-index:2;
    transform: translateX(0%);
    transition: all cubic-bezier(.17,.67,.7,.98) 0.3s;
}
.cs_col2 {
    max-width: 460px;
    width: 100%;
    padding-left: 21px;
}
h4.CS_ttl {
    font-style: normal;
    font-size: 23px;
    line-height: 32.5px;
    color: #101041;
    margin-bottom: 50px;
    font-weight:250;
}
.cs_para {
    margin-bottom:46px;
}

.cs_txt_slider .swiper-button-next {
    top: auto;
    bottom: 0;
    right:0px;
}
.cs_txt_slider .swiper-button-prev {
    top: auto;
    bottom: 0;
    right: 75px;
    left: auto;
}

.cs_txt_slider .swiper-slide {
    /* opacity: 0 !important; */
    visibility: hidden;
}
.cs_txt_slider  .swiper-slide.swiper-slide-active {
    /* opacity: 1 !important; */
    visibility: visible;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}


@media only screen and (min-device-width: 707px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .cs_sld_Content {
        padding-top: 80px !important;
    }
}


@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .cs_sld_Content {
        padding-top: 80px !important;
    }
}




@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .cs_sld_Content {
        padding-top: 55px;
    }
    .cs_img_dv img {
        width: 100%;
    }
    .cs_txt_slider .swiper-button-prev {
        right: 60px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .cs_img_dv img {
        width: 100%;
    }
    .cs_sld_Content {
        padding-top: 30px;
    }
    .cs_col2 {
        max-width: 100%;
        width: 100%;
        padding-left: 0;
    }
    .cs_txt_slider .swiper-button-prev {
        right: 65px;
    }
}



@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .case_studies_Sec {
        padding: 60px 0 60px 0;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 78%, rgba(16,16,33,1) 67%);
    }
    .cs_hd_col {
        margin-bottom: 15px;
    }
    .cs_img_dv img {
        width: 100%;
    }
    .cs_sld_Content {
        padding-top: 20px;
    }
    h4.CS_ttl {
        font-size: 15px;
        line-height: 24.5px;
    }
    .cs_col2 {
        padding-left: 0px;
    }
    .cs_txt_slider .swiper-button-prev {
        right: 56px;
    }
}