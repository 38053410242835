.achieve_sec {
    padding-top:110px;
    padding-bottom:100px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
}
.achv_slider {
    margin-top:80px;
}
.achv_slider .swiper-slide {
    /* max-width: 347px;
    width: 100%;
    margin: 0 110px; */
}
.achv_slider .swiper-wrapper {
    margin-bottom: 125px;
}
.achieve_item { 
    text-align: left;
    width:100%;
    position: relative;
    padding-right: 100px;
}
.achieve_item::before {
    content: '';
    position: absolute;
    top: 11px;
    left: auto;
    right: 110%;
    background-image:url('../img/achieve_sld_line.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 71%;
    height: 8px;
 
}
.achv_slider  .swiper-wrapper .swiper-slide:first-child .achieve_item:before {
    display: none;
}
.achv_img_flag {
    height: 55px;
    width: 24px;
    transition: all 0.8s;
}
.achv_year {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #101041;
    transition: all 0.8s;
    margin-top: 15px;
}
.achv_desc {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #101041;
    transition: all 0.8s;
}

.achv_slider .swiper-slide.swiper-slide-active .achieve_item .achv_img_flag {
    width: 40px;
}
.achv_slider .swiper-slide.swiper-slide-active .achieve_item .achv_year {
    color: #2727A3;
}
/* .achv_slider .swiper-slide.swiper-slide-active .achieve_item .achv_desc {
    font-size: 26px;
} */

.achv_slider .swiper-button-prev {
    top: auto;
    bottom:0;
    left: 46%;
}
.achv_slider .swiper-button-next {
    top: auto;
    bottom:0;
    right: 46%;
}


@media only screen 
  and (min-device-width: 707px) 
  and (max-device-width: 1080px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .achv_slider .swiper-button-next {
        right:44% !important;
    }
    .achv_slider .swiper-button-prev {
        left:44.5% !important;
    }
}
@media only screen and (min-device-width: 707px)  and (max-device-width: 767px)  {

    .achv_slider .swiper-button-next {
        right:42% !important;
    }
    .achv_slider .swiper-button-prev {
        left:42% !important;
    }
}

@media only screen 
  and (min-device-width: 740px) 
  and (max-device-width: 1080px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .achv_slider .swiper-button-next {
        right:44% !important;
    }
    .achv_slider .swiper-button-prev {
        left:44.5% !important;
    }
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
    .achieve_item {
        padding-right: 50px;
    }
    .achv_slider .swiper-button-prev {
        left: 44.5%;
    }
    .achv_slider .swiper-button-next {
        right: 44.5%;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {

    .achieve_item {
        padding-right: 50px;
    }
    .achv_slider .swiper-button-prev {
        left: 44.5%;
    }
    .achv_slider .swiper-button-next {
        right: 44.5%;
    }
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
    .achieve_item {
        padding-right: 50px;
    }
    .achv_slider .swiper-button-prev {
        left: 45%;
    }
    .achv_slider .swiper-button-next {
        right: 45%;
    }
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
    .achv_slider .swiper-button-prev {
        left: 45.5%;
    }
    .achv_slider .swiper-button-next {
        right: 45.5%;
    }
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
    .achv_slider .swiper-button-prev {
        left: 45.5%;
    }
    .achv_slider .swiper-button-next {
        right: 45.5%;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .achv_slider .swiper-button-prev {
        left: 44.5%;
    }
    .achv_slider .swiper-button-next {
        right: 44.5%;
    }

    .achv_desc {
        font-size: 20px;
        line-height: 32px;
    }

    .mile_heading{
        padding-left:35px !important;
    }
    

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .achieve_sec {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .achieve_item {
        padding-right: 50px;
    }
    .achv_desc {
        font-weight: 500;
        font-size: 19px;
        line-height: 33px;
    }
    .achv_slider .swiper-slide.swiper-slide-active .achieve_item .achv_desc {
        font-size: 22px;
    }
    .achv_slider .swiper-button-prev {
        left: 43%;
    }
    .achv_slider .swiper-button-next {
        right: 43%;
    }
    .achieve_hd {
        padding-left: 40px;
    }
    .achv_slider .swiper-wrapper {
        margin-bottom: 80px;
    }

   
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .achv_slider .swiper-slide {
        margin-left: 20px;
    }
    .achieve_hd {
        padding-left:25px;
    }
    .achv_desc {
        font-size: 18px !important;
        line-height: 25px;
    }
    .achv_slider .swiper-wrapper {
        margin-bottom: 90px;
    }
    .achv_slider .swiper-button-next {
        right: 36%;
    }
    .achv_slider .swiper-button-prev {
        left: 36%;
    }
    .achv_slider {
        margin-top: 50px;
    }
    .achieve_sec {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
