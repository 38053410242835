.apollo_edge_tech {
    padding-top: 80px;
    padding-bottom: 93px;
}
.ap_ed_col1 {
    padding-right: 30px;
}
.apollo_tech_para {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #101021;
    margin-top: 32px;
}
.tech_img {
    width:100%;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .apollo_tech_para {
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
    }

    .apollo_edge_tech {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .apollo_tech_para {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 30px;
        margin-top: 0px !important;
    }

    .apollo_edge_tech {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .apollo_edge_tech {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .apollo_tech_para {
        font-size: 18px;
        line-height: 30px;
    }

    .apollo_tech_para {
       
       margin-top: 10px;
    }
}



