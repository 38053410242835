.pmv_sec {
    width:100%;
    height:100vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    position: relative;
}
.purpose_sec {
    background: url('../img/Purpose_bg.jpg'), #000;
}
.purpose_sec::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(289.26deg, hsl(28deg 96% 40% / 70%) -8.51%, hsl(240deg 40% 31% / 70%) 48.45%); */
    background: linear-gradient(289.26deg, hsl(28deg 96% 40% / 90%) -8.51%, hsl(240deg 40% 31% / 90%) 48.45%);
}
.mission_sec {
    background:url('../img/Mission_bg.jpg'), #000;
}
.value_sec {
    background:url('../img/Values_bg.jpg'), #000;
    padding:100px 0px 50px 0px;
    height: auto !important;
}
.value_sec::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(289.26deg, hsl(28deg 96% 40% / 90%) -8.51%, hsl(240deg 40% 31% / 90%) 48.45%);
}
.value_col .pd_ttl {
    margin-bottom: 0px;
}
.value_col .so_sub_ttl {
    margin-top: 10px;
}
#vlu1_accordion .card-body , #vlu2_accordion .card-body{
    margin-top: 20px;
}
.purpose_col {
    max-width:800px;
    width:100%;
}
.value_col {
    max-width:950px;
    width:100%;
}
.pd_ttl {
    margin-bottom: 30px;
}
.fix-sec {
    background: #130f40;
}
.vlu_innr_col {
    margin-bottom: 37px;
    float: left;
}
.vlu_point_row {
    margin-top: 0px;
}
.vlu_point {

}
.vlu_img {
    width: 50px;
    margin-right: 20px;
    margin-top: 5px;
    float: left;
}
.vlu_inner {
    float: left;
    padding-top: 13px;
}
.vlu_dsc {
    font-size:14px;
    display: none;
}
.vlu_name {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #eeeeee;
}
#vlu1_accordion .solution_name , #vlu2_accordion .solution_name {
    width:fit-content;
}
#vlu1_accordion  .sol_cnt_desc , #vlu2_accordion .sol_cnt_desc  {
    margin: 0;
}
#vlu1_accordion img.sol_plus_icon {
    top: 4px;
}
#vlu1_accordion img.sol_minus_icon {
    top: 4px;
}

#vlu_mob {
    display: none;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .vlu_name {
        font-size: 15px;
    }
    .value_stikcy .sticky {
        position: relative !important;
    }
    .value_stikcy span.icons_span {
        margin-right: 15px;
    }
    .value_stikcy .solution_name {
        font-size: 14px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .vlu_img {
        width: 35px;
        margin-right: 10px;
    }
    .vlu_name {
        font-size: 15px;
    }
    .vlu_innr_col {
        margin-bottom: 30px;
    }

    .value_stikcy .sticky {
        position: relative !important;
    }
    .value_sec {
        height: auto;
        padding-top:60px;
        padding-bottom: 60px;
    }

    #vlu_desktop {
        display: none;
    }
    #vlu_mob {
        display: block;
    }

    #vlu3_accordion .solution_name {
        width:fit-content;
    }
    #vlu3_accordion  .sol_cnt_desc  {
        margin: 0;
    }
    .vlu_sub_ttl {
        margin-bottom: 10px !important;
    }
    
}



