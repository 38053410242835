.apollo_edge_sec {
    /* height:100vh; */
    width: 100%;
    padding-top: 100px;
    padding-bottom: 150px;
    position: relative;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 3.8%, #E4EAFF 74.63%);
}

.ap_edge {
    background: #fff;
    position: relative;
}
.ils_bx {
    width:100% !important;
}
.apolo_txtsvg {
    z-index: 2;
    overflow: hidden;
}
.apolo_txtsvg h3 {
    color: #EF7F1A;
    text-transform: uppercase;
    font-weight: 250;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
}
.apolo_txtsvg svg {
    width: 100%;
    height: 270px;
    margin-top: -45px;
}

.asc_bx_hvr:hover {
    background-color:#2F2F6D;
}
.asc_bx_hvr:hover .inner_dv_svg_bx h4 {
    color: #fff;
}
.asc_bx_hvr:hover .inner_dv_svg_bx img {
    filter: brightness(0) invert(1);
}
.apollo_edge_sec svg text {
    font-size: 168px;
    fill: #2F2F6D;
    stroke: #EF7F1A;
    text-shadow: 0 0 15px rgb(255 255 255 / 40%);
    stroke-width: 1.5px;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    animation: animate 5s linear infinite;
    font-family: 'oms';
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}
.asc_bx_hvr:hover .svg_bx_para {
    color: #fff;
}
text.backtext {
    stroke: #2F2F6D !important;
    animation-play-state: paused !important;
    stroke-dasharray: none !important;
    stroke-width: 5px !important;
}

@keyframes animate {
    100% {
        stroke-dashoffset: 200;
    }
}

.apolo_edge_row2 {
    margin-top: -105px;
    padding: 0 70px;
}

.edge_col1 {
    z-index: 1;
}

.asc_svg_col {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(3px);
    padding: 40px 20px;
}

.asc_svg_prt1 {
    width: 75%;
    height: 100%;
    float: left;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    margin-right: 22px;
}

.asc_svg_prt2 {
    width: 37%;
    height: 100%;
    float: left;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    margin-right: 22px;
}

.asc_svg_box {
    width: 25%;
    height:300px;
    float: left;
    padding: 20px;
    border-right: 1px solid rgb(217 217 217 / 40%);
}

.asg_bx1 {
    height: 130px;
}

.inner_dv_svg_bx {
    width: 100%;
}
.svg_bx_para {
    font-size: 14px;
    line-height: 23px;
}
.inner_dv_svg_bx h3 {
    color: #101041;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
    font-weight: 300;
}

.inner_dv_svg_bx img {
    margin-right: 11px;
}

.inner_dv_svg_bx h4 {
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #2A2AB0;
    margin-top: 20px;
    font-weight: 250;
}

.asc_svg_prt3 {
    width: 22%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.inner_dv_svg_bx1 {
    float: left;
}

.asc_svg_prt3 h2 {
    margin-top: -10px;
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 20px;
}

.aplo_edge_col1 {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 50px 40px;
    float: left;
}

.aplo_edge_col2 {
    margin-top: 60px;
    padding-left: 15px;
}

.edge_ttl {
    font-size: 26px;
    line-height: 33px;
    color: #101041;
    margin-bottom: 40px;
    font-weight: 300;
}

.edge_ttl span {
    color: #2727A3;
}

.edge_bg {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.edge_list {
    padding-left: 0px;
    list-style: none;
    margin: 30px 0;
    margin-top: 0;
}

.edge_list li {
    height: 55px;
    width: 100%;
    border-top: 1px solid #d9d9d966;
}

.edge_list li:nth-child(1) {
    border-top: 0px;
}

.edge_list li a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-transform: capitalize;
    color: #3F3FFE !important;
}

.edge_list li a span {
    background-color: #01FF84;
    width: 9px;
    height: 2px;
    margin-right: 15px;
}


img.edge_vctr_1 {
    position: absolute;
    bottom: 45%;
    left: auto;
    right: 27%;
}

img.edge_vctr_2 {
    position: absolute;
    right: 2.8%;
    bottom: 27%;
}

img.edge_vctr_4 {
    position: absolute;
    right: 41.5%;
    bottom: 39.5%;
}

img.edge_vctr_5 {
    position: absolute;
    right: 15%;
    bottom: 33.5%;
}

img.edge_vctr_6 {
    position: absolute;
    bottom: 50%;
    left: 20%;
}

img.edge_vctr_7 {
    position: absolute;
    bottom: 35%;
    left: 17%;
}

.p_opacity {
    opacity: 0;
}


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .edge_list li a {
        font-size: 18px;
    }

    .aplo_edge_col1 {
        padding: 45px;
    }

    .aplo_edge_col2 {
        margin-top: 35px;
    }

    .asc_svg_prt1,
    .asc_svg_prt2 {
        margin-right: 15px;
    }

    .asc_svg_box {
        height: 325px;
    }

    .asc_svg_prt3 h2 {
        font-size: 30px;
        line-height: 37px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {

    .aplo_edge_col2 {
        margin-top: 35px;
    }
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {


}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {


}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .apollo_edge_sec {
        padding-top:100px;
    }
    .apolo_txtsvg h3 {
        font-size: 37px;
    }
    .apolo_txtsvg svg {
        margin-top: -90px;
    }
    .apolo_edge_row2 {
        padding: 0px 0px 0px 70px;
    }

    .apollo_edge_sec svg text {
        font-size: 110px;
    }

    .asc_svg_prt1,
    .asc_svg_prt2 {
        margin-right: 15px;
    }

    .asc_svg_box {
        float: left;
        padding: 10px;
        height: 350px;    
    }

    .asc_svg_prt3 h2 {
        font-size: 23px;
        line-height: 35px;
    }

    .aplo_edge_col1 {
        padding: 35px;
        height: auto;
        display: inline-block;
    }

    .aplo_edge_col2 {
        margin-top: 45px;
        padding-left: 5px;
    }

    .edge_col2 {
        padding: 0;
    }

    .edge_list li a {
        font-size: 15px;
        line-height: 24px;
    }

    .edge_ttl {
        font-size: 25px;
        line-height: 37px;
    }

    .edge_bg {
        left: 0;
    }

    .edge_list {
        margin: 0 0 20px 0px;
    }

    .edge_list li {
        height: 45px;
    }

    img.edge_vctr_1 {
        bottom: 25%;
    }

    img.edge_vctr_2 {
        right: 2.8%;
        bottom: 15%;
        width: 45px;
    }

    img.edge_vctr_4 {
        bottom: 26.5%;
    }

    img.edge_vctr_5 {
        bottom: 20.5%;
        width: 35px;
    }

    img.edge_vctr_6 {
        left: 32px;
        bottom: 30%;
    }

    img.edge_vctr_7 {
        bottom: 116px;
        left: 29px;
        width: 40px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {


    .apollo_edge_sec {
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-bottom: 70px;
        position: relative;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 3.8%, #E4EAFF 74.63%);
    }
    .apolo_txtsvg svg {
        margin-top: -115px;
    }
    .apolo_txtsvg h3 {
        font-size:30px;
    }
    .apolo_edge_row2 {
        padding: 0px 0px 0px 30px;
    }

    .apollo_edge_sec svg text {
        font-size: 85px;
    }

    .asc_svg_prt1,
    .asc_svg_prt2 {
        width: 100%;
        height: 350px;
    }

    .asc_svg_prt1 {
        margin-right: 15px;
    }

    .asc_svg_prt2 {
        margin-right: 0px;
    }

    .asc_svg_box {
        padding: 13px;
        height: 100%;
    }

    .inner_dv_svg_bx h4 {

    }

    .asc_svg_prt3 {
        width: 100%;
        margin-top: 30px;
        height: auto;
    }

    .aplo_edge_col1 {
        padding: 35px;
        height: auto;
        display: inline-block;
    }

    .inner_dv_svg_bx h3 {
        font-size: 17px;
    }

    .inner_dv_svg_bx img {
        margin-right: 10px;
        width: 37px;
    }

    .aplo_edge_col2 {
        margin-top: 45px;
        padding-left: 5px;
    }

    .edge_col2 {
        padding: 0;
    }

    .edge_list li a {
        font-size: 14px;
        line-height: 19px;
    }

    .edge_ttl {
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 20px;
    }

    .edge_bg {
        left: 0;
    }

    .edge_list {
        margin: 0 0 20px 0px;
    }

    .edge_list li {
        height: 45px;
    }

    img.edge_vctr_1 {
        bottom: 24%;
    }

    img.edge_vctr_2 {
        right: 2.8%;
        bottom: 12%;
        width: 35px;
    }

    img.edge_vctr_4 {
        right: 41.5%;
        bottom: 19.5%;
        width: 26px;
    }

    img.edge_vctr_5 {
        right: 15%;
        bottom: 16.5%;
        width: 32px;
    }

    img.edge_vctr_6 {
        left: 32px;
        bottom: 30%;
    }

    img.edge_vctr_7 {
        bottom: 116px;
        left: 29px;
        width: 40px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .apollo_edge_sec {
        height: auto;
        padding-top: 60px;
        padding-bottom: 62px;
        overflow: hidden;
    }
    .apolo_txtsvg h3 {
        font-size: 20px;
    }
    .apolo_txtsvg svg {
        width: 100%;
        height: 105px;
    }

    .apollo_edge_sec svg text {
        font-size: 55px;
    }

    text.backtext {
        stroke-width: 4px !important;
    }

    .apollo_edge_sec svg text {
        stroke-width: 0.5px;
    }

    .edge_bg {
        left: 0;
    }

    .edge_col1 {
        padding-top: 65px;
        z-index: 1;
    }

    .inner_dv_svg_bx h3 {
        font-size: 16px;
    }

    .asc_svg_prt1,
    .asc_svg_prt2,
    .asc_svg_prt3 {
        width: 100%;
        height: auto;
        border-bottom: 0px;
    }

    .asc_svg_box {
        width: 100%;
        padding: 30px 15px 15px 15px;
        height: auto;
        border-bottom: 1px solid rgb(217 217 217 / 40%);
        border-right: 0px;
    }

    .inner_dv_svg_bx img {
        margin-right: 15px;
    }

 

    .asg_bx1 {
        height: auto;
    }

    .apolo_edge_row2 {
        margin-top: -105px;
        padding: 0 0px;
        margin-bottom: 30px;
    }

    .asc_svg_prt2 .asg_bx1 {
        border-top: 0;
    }

    .asc_svg_prt2 {
        margin-top: 20px;
    }

    .asc_svg_prt3 {
        margin-top: 35px;
    }

    .asc_svg_prt3 h2 {
        margin-top: -10px;
        font-size: 26px;
        line-height: 37px;
        margin-bottom: 15px;
    }

    .aplo_edge_col1 {
        padding: 30px;
        margin-top: 74px;
    }

    .edge_list li a {
        font-size: 15px;
        line-height: 19px;
    }

    .aplo_edge_col2 {
        margin-top: 20px;
        padding-left: 0px;
    }

    .edge_ttl {
        font-size: 22px;
        margin-bottom: 20px;
    }

}