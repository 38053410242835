.ap_edge_advantage {
    background: linear-gradient(90deg, rgba(16, 16, 33, 1) 50%, rgba(244, 244, 244, 1) 50%);
    padding: 10px 0px;
}

.advntg_col1 {
    background: #101021;
    padding: 84px 50px 55px 15px;
}
.apollo_advantage_nav {
    width: 100%;
    max-width: 520px;
}
.apollo_advantage_nav .slick-track {
    height: auto !important;
}
.slick-slider.advantg_apollo_sld {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text !important;
}
.advantg_apollo_sld .slick-slide.slick-active.slick-current {
    z-index: 1;
}
.advantg_apollo_sld .slick-slide {
    z-index: 0;
}
.adv_apollo_outer_nav {
    height: 72px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex !important;
    align-items: center;
    cursor: pointer;
}
.lst_nav {
    border-bottom: 0px solid !important;
}
.ad_apollo_inner_nav p {
    margin-bottom: 0px;
    font-weight: 250;
    font-size: 20px;
    line-height: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'oms';
}
.ad_apollo_inner_nav {
    width:100%;
    position: relative;
}
.advtg_plus {
    position: absolute;
    right: 0;
    top: 8px;
    width:17px;
    height:17px;
    background-image: url('../img/svg/plus_wht_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    transition:all 0.5s;
}
.advtg_minus {
    position: absolute;
    right: 0;
    top:15px;
    width:17px;
    height:17px;
    background-image: url('../img/svg/minus_wht_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    opacity:0;
    transition:all 0.5s;
}
.ad_apollo_inner_nav p:hover {
    color: #FF7900;
}
.apollo_advantage_nav  .slick-slide.slick-active.slick-center.slick-current .ad_apollo_inner_nav p {
    color: #FF7900;
}
.apollo_advantage_nav  .slick-slide.slick-active.slick-center.slick-current .ad_apollo_inner_nav .advtg_plus {
    opacity: 0;
}
.apollo_advantage_nav  .slick-slide.slick-active.slick-center.slick-current .ad_apollo_inner_nav .advtg_minus {
    opacity: 1;
}



.advntg_col2 {
    background: #F4F4F4;
    padding: 115px 46px;
}
.ad_apollo_inner img {
    margin-bottom: 30px;
}
.adv_sld_hd {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-transform: capitalize;
    color: #101021;
}
.adv_sld_para {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101021;
}
.advntg_col1 button , .advntg_col2 button {
    display: none !important;
}
.advantage_desk {
    display: block;
}
.ae_advntg_mob  {
    display:none;
} 

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .advantage_desk {
        display: block;
    }
    .ae_advntg_mob  {
        display:none;
    } 

    .ad_apollo_inner_nav p {
        font-size: 17px;
    }
    .adv_apollo_outer_nav {
        height: 65px;
    }
    .advtg_plus {
        top: 8px;
    }
    .advtg_minus {
        top: 16px;
    }
    .adv_sld_hd {
        line-height:36px;
        font-size: 28px;
    }
    .adv_sld_para {
        font-size: 16px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .advantage_desk {
        display: block;
    }
    .ae_advntg_mob  {
        display:none !important;
    } 
    .ad_apollo_inner_nav {
        width: 98%;
    }
    .ad_apollo_inner_nav p {
        font-size: 15px;
        line-height: 24px;
    }
    .adv_apollo_outer_nav {
        height: 65px;
    }
    .advtg_plus {
        top: 8px;
    }
    .advtg_minus {
        top: 14px;
        width: 14px;
        height: 14px;
    }
    .adv_sld_hd {
        line-height: 29px;
        font-size: 22px;
    }
    .ad_apollo_inner img {
        margin-bottom: 20px;
        width: 90px;
    }
    .adv_sld_para {
        font-size: 16px;
    }
    .advtg_plus {
        width: 14px;
        height: 14px;
    }
    .advntg_col2 {
        padding-right: 0;
        padding-left: 20px;
        padding-bottom: 0;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .advantage_desk {
        display: none;
    }
    .ae_advntg_mob  {
        display:block;
    } 
    .ae_advntg_mob {
        background: #101021;
        padding-top:50px;
        padding-bottom: 50px;
    }
    .ae_advntg_mob_accord .card-header {
        background-color: transparent;
    }
    .accordion_solution .card-header button {
        padding: 12px 20px;
    }
    span.icons_span {
        margin-right: 15px;
    }
    .ae_advntg_mob .sol_cnt_desc {
        color: #fff;
        margin: 0;
    }
    .ae_advntg_mob img.sol_plus_icon {
        position: absolute;
        top: 4px;
        right: 4px;
    }
    .ae_advntg_mob .solution_name {
        font-size: 13px;
        line-height: 19px;
        width: 235px;
    }
}


