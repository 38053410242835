.our_network_sec {
    background: #101021;
    padding-top: 100px;
    padding-bottom: 20px;
} 
.netwrk_sub_ttl{
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height:45px;
    color: #A1AEC7;
}
.ntwrk_list {
    padding:0;
    width: 100%;
    max-width: 180px;
    margin-top: 51px;
    list-style: none;
}
.ntwrk_list li {
    height:57px;
    border-bottom: 1px solid rgba(75, 75, 75, 0.31);
    display: flex;
    align-items: center;
}
.ntwrk_list li:last-child  {
    border-bottom: 0px;
}
.ntwrk_list li a{
    display: block;
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #A8A8A8;
    width: 100%;
    float: left;
}
.ntwrk_list li a span{
    background-color: #01FF84;
    width: 9px;
    height: 1px;
    margin-right: 15px;
    margin-top: 13px;
    float: left;
}

.network_col2 img {
    width: 100%;
    margin-top: -100px;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
    .our_network_sec {
        padding-bottom: 50px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .netwrk_sub_ttl {
        font-size: 30px;
        line-height: 45px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .netwrk_sub_ttl {
        font-size: 25px;
        line-height: 38px;
    }
    .ntwrk_list li {
        height: 50px;
    }
    .network_col2 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .network_col2 img {
        width: 100%;
        margin-top: 0;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .our_network_sec {
        padding-top: 60px;
    }
    .netwrk_sub_ttl {
        font-size: 23px;
        line-height: 33px;
        margin-top: 20px;
    }  
    .ntwrk_list li {
        height: 50px;
    } 
    .ntwrk_list li a {
        font-size: 14px;
    }
    .network_col2 img {
        margin-top: -50px;
    }
}