.result_cstm {
    /* background-image: url('../img/sectors/consumer_durables_high-tech_industries/the-result/bg/the_result_bg.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 90px 0 130px 0;
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
}

.ecom_rsult {
    background-position: bottom;
}

.ecommerce_result {
    /* background-image: url('../img/sectors/e-commerce_and_fmcg/the-result/bg/the_result_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    padding: 90px 0 130px 0;
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
}

.result_automo {

    /* background-image: url('../img/sectors/automotive_and_industrial_sector/the-result/bg/the_result_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    padding: 90px 0 130px 0;
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
}

.rslt_ttl {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #2F2F6D;
}

.reslt_para {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 80px;
}

.result_box {
    /* background-image: url('../img/result_box_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    background-color:#fff;
    border: 1px solid #A1AEC7;
    height: 230px;
    padding: 40px 25px;
    -webkit-backdrop-filter: blur(12.5px);
    backdrop-filter: blur(12.5px);
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rslt_inner {
    text-align: center;
}

.rslt_inner img {
    margin-bottom: 20px;
    filter: invert(47%) sepia(30%) saturate(2871%) hue-rotate(1deg) brightness(104%) contrast(103%);
}

.rslt_bx_p {
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #757575;
    width: 100%;
    max-width: 215px;
    margin: auto;
}

.rslt_bx_p span {
    color: #FFF34A;
    font-weight: 700;
}


/*------------- Automotive css*/
.result_automo .result_box {
    width: 100%;
    max-width: 430px;
    margin-bottom: 60px;
}

.auto_rslt_1 {
    height: 310px !important;
}

.result_automo .rslt_bx_p {
    max-width: 100%;
}

/*------------- Automotive css*/


@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .rslt_bx_p {
        font-size: 16px;
    }

    .reslt_para {
        margin-top: 0px;
    }
    .rslt_ttl {
        font-size: 18px;
        line-height: 25px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .result_box {

        padding: 10px;
    }

    .rslt_bx_p {
        font-size: 15px;
    }

    .reslt_para {
        font-size: 24px;
    }
    .rslt_ttl {
        font-size: 16px;
        line-height: 23px;
    }

    /*------------- Automotive css*/
    .result_automo .result_box {
        padding: 20px;
    }

    /*------------- Automotive css*/

    .reslt_para {
        margin-top: 0px;
    }


}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .result_box {
        margin-bottom: 35px;
    }

    .reslt_para {
        font-size: 22px;
        line-height: 32px;
    }
    .rslt_ttl {
        font-size: 18px;
        line-height: 20px;
    }

    /*------------- Automotive css*/
    .result_automo .auto_rslt_1 {
        height: auto !important;
    }

    .result_automo .result_box {
        padding: 30px;
    }

    /*------------- Automotive css*/

    .result_cstm {
        padding: 50px 0 50px 0;
    }

    .result_automo {
        padding: 50px 0 10px 0;
    }
    .reslt_para {
        margin-bottom: 30px;
    }
}