.sustain_sec {
    padding: 104px 0 88px 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    position: relative;
    height: 930px;
    background: #fff;
}

/* .tab1_img {
    background: url('../img/Environment_bg.jpg'), #111021;
}

.tab2_img {
    background: url('../img/Social_bg.jpg'), #111021;
}

.tab3_img {
    background: url('../img/Governance_bg.jpg'), #111021;
} */

.why_us_subhead {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    padding-right: 170px;

}

.sustain_tab_para {

    font-style: normal;
    font-family: 'oms';
    font-weight: 250;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #101021 !important;
}
.esg_tab {
    background-color: #2F2F6D;
}
.tabs_dv {
    margin-top: 60px;
}

.tabs_dv .nav-tabs {
    border: none;
}

.tabs_dv .tab-pane {
    margin-top: 30px;
}

.tabs_link {
    height: 200px;
    width: 200px;
    border-radius: 0px !important;
    border: 1px solid #666666 !important;
    font-family: 'oms';
    font-weight: 250;
    font-size: 20px;
    line-height: 27px;
    text-transform: capitalize;
    color: #fff;
    display: flex;
    align-items: end;
    padding-left: 22px;
    padding-bottom: 22px;
    transition: all 0.5s;
    -webkit-appearance: initial !important;
    appearance: initial !important;
}

_::-webkit-full-page-media,
_:future,
:root .tabs_link {
    border-radius: 0px !important;
}

.tab_link2 {
    border-left: 0px !important;
    border-right: 0px !important;
}

.tabs_dv .nav-tabs .nav-link.active {
    color: #101021;
    background: #FF7900;
    border: none;
}

.tabs_dv .nav-tabs .nav-link:hover {
    background: #FF7900;
    color: #101021;
}

.tabs_dv .nav-tabs .nav-link:focus,
.tabs_dv .nav-tabs .nav-link:hover {
    border: none;
}

.tab_ttl {
    font-weight: 400;
    font-size: 31px;
    line-height: 43px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.csmt_tab_dv {
    width: 100%;
    max-width: 700px;
}

.tabs_dv .tab-pane p {
    margin-bottom: 19px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .sustain_sec {
        padding: 60px 0 60px 0;
        background-position: 67%;
        height: auto;
    }

    .sustain_tab_para {
        font-size: 17px;
        line-height: 28px;
    }

    .tabs_link {
        height: 50px;
        width: 100%;
        font-size: 17px;
        padding: 9px;
        line-height: 18px;
        padding-bottom: 14px;
        border: 1px solid #666666 !important;
    }

    .tabs_dv .tab-pane {
        margin-top: 30px;
    }

    .tab_ttl {
        font-size: 25px;
        line-height: 35px;
    }

    .why_us_subhead {
        font-size: 20px;
        padding-right: 0px;
    }

    .sustain_sub_p {
        margin-bottom: 10px !important;
    }

}