.media_div {
    padding: 80px 0 160px 0;
    background-color: #f5f5f5;
}

.blog_container {
    max-width: 1050px;
    margin: auto;
    width: 100%;
    position: relative;
    background-color: #fff;
}
.blg_in_sec_p1 a {
    color: #2A2AB0;
    font-family: 'Red Hat Display';
}
.blog_frst_Sec_hd {
    font-weight: 250;
    font-size: 27px;
    line-height: 37.5px;
    margin-bottom: 10px;
}

.blog_frst_Sec_para {
    font-size: 18px;
    line-height: 28.6px;
}

.media_row {
    margin: 0;
    padding: 65px 50px;
}

.blog_inside_slug {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #FF7900;
    margin-bottom: 0;
}

.media_hd_img {
    width: 100%;
    margin-bottom: 46px;
}

.media_ctn_box {
    margin-top: 60px;
}

.media_col1 {
    position: sticky;
    top: 150px;
    display: grid;
}

.media_col1 button {
    margin-bottom: 15px;
}

.media_ttl {
    margin-bottom: 10px;
    font-weight: 250;
    font-size: 25px;
    line-height: 39px;
    color: #101021;
}

a.socl_img {
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
    margin-bottom: 15px;
}

.socl_img img {
    width: 100%;
}


.blg_in_sec_p1 table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
}

.blg_in_sec_p1 td,
.blg_in_sec_p1 th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

ul.global_list.blog_list {
    margin-top: -16px;
}

ul.global_list.blog_list li {
    color: #757575;
}

.blg_glst {
    margin-bottom: 35px !important;
}

.blg_back_btn {
    margin-top: 50px;
    display: block;
    color: #2A2AB0;
    font-weight: 300;
    text-decoration: underline;
}
.blg_back_btn:hover {
    color: #2A2AB0;
}
.Inside_blog_pg .blog_featured{
    background-color: #fff;
}
.Inside_blog_pg .blog_featured .bf_prt_2 {
    background-color: #f4f4f4;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .blog_frst_Sec_hd {
        font-size: 31px;
        line-height: 42px;
    }


}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .blog_frst_Sec_hd {
        font-size: 27px;
        line-height: 35px;
    }

    .blog_frst_Sec_para {
        font-size: 17px;
        line-height: 26.6px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {


    .media_div {
        padding: 50px 0 50px 0;
    }
    .media_row {
        padding: 20px;
    }

    .media_row .col-1 {
        padding: 0;
    }

    .media_hd_img {
        margin-bottom: 30px;
    }

    .media_ctn_box {
        margin-top: 35px;
    }

    .media_ttl {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .media_row .blog_img_col {
        padding: 0;
    }

    .media_col1 button {
        margin-bottom: 5px;
    }

    .media_col1 button svg {
        width: 28px;
    }

    .blog_frst_Sec_hd {
        font-size: 17px;
        line-height: 28px;
    }

    .blog_frst_Sec_para {
        font-size: 14px;
        line-height: 22.6px;    
    }
}