.about_apollo_sec {
    width: 100%;
    /* height:100vh; */
    padding: 70px 0px;
    border-bottom: 1px solid rgb(161 174 199 / 40%);
    overflow: hidden;
}

.abt_apl_col1 {
    padding-right: 83px;
    padding-top: 50px;
}

.about_apollo_sec .hd_dv:last-of-type {
    margin-bottom: 15px;
}

.about_apollo_sec .button,
.about_apollo_sec .btn {
    margin-top: 50px;
}

.apollo_main_head {
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    text-transform: capitalize;
    color: #101021;
    margin-bottom: 25px;
}

.blnk_col {
    width: 100%;
    max-width: 280px;
}

.heading span {
    display: inline-block;
}

.abt_apl_box {
    background: #E7EAF0;
    padding: 30px;
    position: relative;
    width: 100%;
    max-width: 280px;
    transform: scale(1);
    transition: all 0.5s;
}

.co_box {
    z-index: 0;
}

.cr_box {
    z-index: 1;
}

.cs_box {
    z-index: 0;
}

.abt_apl_box:hover {
    background: #101041;
    transform: scale(1.06);
    z-index: 2;
}

.cube_img {
    top: -42px;
    right: -44px;
}

.abt_apl_box img {
    position: absolute;
}

.circle_img {
    bottom: -42px;
    right: -110px;
    z-index: 1;
}

.arrow_img {
    bottom: -40px;
    right: -41px;
}

.team_img {
    bottom: -95px;
    right: -142px;
}


.abt_apl_box:hover .abt_apl_hd,
.abt_apl_box:hover .abt_apl_p {
    color: #fff;
}

.abt_apl_hd {
    font-weight: 250;
    font-size: 40px;
    line-height: 26px;
    color: #101041;
    margin-bottom: 20px;
    width: fit-content;
    transition: all 0.8s;
}

.abt_apl_p {
    font-weight: 250;
    font-size: 14px;
    line-height: 19px;
    color: #101041;
    width: fit-content;
    margin-bottom: 0;
    transition: all 0.8s;
    padding-right: 80px;
}

.abt_apl_box img {
    position: absolute;
}

/* .abt_apl_box:hover img {
    filter: brightness(0) invert(1);
} */




@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 1100px) {
    .about_apollo_sec .hd_dv:last-of-type {
        margin-bottom: 15px;
    }

    .about_apollo_sec .button,
    .about_apollo_sec .btn {
        margin-top: 20px;
    }

    .abt_apl_col1 {
        padding-right: 50px;
    }

    .apollo_main_head {
        font-size: 27px;
        line-height: 38px;
        margin-bottom: 15px;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .blnk_col {
        max-width: 240px;
    }

    .abt_apl_box {
        max-width: 240px;
    }

    .abt_apl_hd {
        font-size: 32px;
        line-height: 10px;
    }

    .abt_apl_p {
        padding-right: 35px;
    }

    .cube_img {
        top: -42px;
        right: -12px;
        width: 97px;
    }

    .circle_img {
        bottom: -40px;
        right: -79px;
        width: 165px;
    }

    .arrow_img {
        bottom: -22px;
        right: -3px;
        width: 95px;
    }

    .team_img {
        bottom: -69px;
        right: -102px;
        width: 190px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {


    .about_apollo_sec .button {
        margin-top: 35px;
    }

    .abt_apl_col2 {
        margin-top: 50px;
    }

    .abt_apl_col2 .row {
        justify-content: center;
    }

    .cube_img {
        top: -42px;
        right: -44px;
        width: 115px;
    }

    .circle_img {
        bottom: -37px;
        right: -70px;
        width: 174px;
    }

    .arrow_img {
        bottom: -35px;
        right: -30px;
        width: 110px;
    }

    .team_img {
        bottom: -69px;
        right: -88px;
        width: 190px;
    }


}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .about_apollo_sec {
        padding: 50px 0px;

    }

    .abt_apl_hd {
        font-size: 30px;
    }

    .apollo_main_head {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 12px;
    }

    .abt_apl_col1 {
        padding-right: 0;
    }

    .abt_apl_col2 {
        padding-top: 80px;
    }

    .abt_apl_box {
        max-width: 285px;
        margin-bottom: 20px;
    }

    .abt_apl_p {
        padding-right: 35px;
    }

    .cube_img {
        top: -42px;
        right: -34px;
        width: 95px;
    }

    .circle_img {
        bottom: -27px;
        right: -67px;
        width: 145px;
    }

    .arrow_img {
        bottom: -5px;
        right: -22px;
        width: 80px;
    }

    .cs_box {
        z-index: 1;
    }

    .team_img {
        bottom: -61px;
        right: -73px;
        width: 159px;
    }

    .abt_apl_col2 .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .abt_apl_box img {
        z-index: 1;
    }

    .about_apollo_sec .btn {
        margin-top: 15px;
    }

    .about_apollo_sec .hd_dv:last-of-type {
        margin-bottom: 0px;
    }

    .apollo_heading {
        margin-bottom: 20px !important;
    }

}