.openings_list {
    padding: 70px 0;
    background-color: #F4F4F4;
}
.co_list_col {
    background: #fff;
    padding:30px 25px;
    display: flow-root;
    margin-bottom: 30px;
}
.co_list_ttl {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 20px;
    color: #2727A3;
    font-weight: 250;
}
.co_dtl {
    margin-bottom: 5px;
}
.co_list_btn {
    margin-top: 25px;
}
.co_list_btn span.btn_txt {
    padding: 15px;
}
.co_list_btn  span.arrow_btn {
    width: 50px;
    height: 50px;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .co_list_ttl {
        font-size: 18px;
        line-height: 25px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    
    .co_list_ttl {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    h5.iaf_ttl {
        font-size: 17px;
    }
}



