.office_dtls {
    padding-top: 100px;
}

.office_dtls .hd_dv,
.cnt_form_row .hd_dv {
    margin: auto;
}

.office_row {
    background: #101021;
    margin-top: 50px;
    padding: 83px 40px 65px 40px;
}

.ofc_inner_dv {
    float: left;
    padding-right: 45px;
}

.ofc_inner_dv img {
    float: left;
    width: 24px;
    margin-right: 5px;
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(85%);
    margin-top: 4px;
}

.ofc_location {
    font-weight: 250;
    font-size: 28px;
    line-height: 36px;
    float: left;
    width: fit-content;
}

.ofc_add {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    width: 100%;
    float: left;
    margin-top: 20px;
}



@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .ofc_location {
        font-size: 25px;
    }    
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .ofc_add {
        margin-top: 0px;
    }

    .ofc_location {
        font-size: 24px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .ofc_add {
        margin-top: 0px;
    }
    .ofc_location {
        font-size: 25px;
    }
    .ofc_inner_dv {
        padding-right: 60px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .office_row {
        padding: 40px 20px;
        padding-bottom: 10px;
    }

    .ofc_inner_dv img {
        width: 17px;
        margin-top: 7px;
        margin-right: 8px;
    }
    
    .ofc_location {
        font-size: 22px;
    }

    .ofc_add {
        margin-top: 10px;
    }

    .ofc_inner_dv {
        margin-bottom: 20px;
    }
    .ofc_inner_dv {
        padding-right: 20px;
    }

}