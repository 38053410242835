.customised_servc_ware {
    /* background-image: url('../img/customised_bg.jpg'); */
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    background: linear-gradient(289.26deg, #CA6204 -8.51%, #2F2F6D 48.45%);
    padding-top: 68px;
    padding-bottom: 55px;
    padding-right: 150px;
    overflow: hidden;
}
.customised_contain {
    width:100%;
    max-width:83.2%;
    margin-left: auto;
}
.customised_slider {
    margin-top: 45px;
    padding-bottom: 100px;
}
.cstm_item {
    /* background-image: url('../img/cstmoised_item_bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px;
    border: 1px solid #A1AEC7;
    height:512px;
}

.cstm_sld_img {
    margin-bottom: 15px;
    filter: invert(52%) sepia(60%) saturate(3305%) hue-rotate(1deg) brightness(85%) contrast(105%);
}

.cstm_sld_hd {
    font-family: 'oms';
    font-weight: 200;
    font-size: 22px;
    line-height: 36px;
    color: #FFFFFF;
}

.cstm_sld_list {
    list-style: none;
    margin-top: 35px;
    padding: 0px;
}
.cstm_sld_list li:last-child {
    border-bottom: 0px;
}
.cstm_sld_list li {
    border-bottom:1px solid rgb(217 217 217 / 20%);
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 12px 0px;
    display: flex;
    align-items: baseline;
    
}

.cstm_sld_list li img {
    margin-right: 13px;
}
.customised_slider .swiper-button-prev {
    top: auto;
    bottom: 0;
    left: 0;
}
.customised_slider .swiper-button-next {
    top: auto;
    left: 75px;
    bottom: 0;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .customised_contain {
        max-width: 96%;
    }

    .customised_servc_ware {
        padding-right: 60px;
    }

}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {

    .customised_contain {
        max-width: 96.4%;
    }
    .customised_servc_ware {
        padding-right: 60px;
    }

}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {

    .customised_contain {
        max-width: 93.9%;
    }
    .customised_servc_ware {
        padding-right: 60px;
    }
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {

    .customised_contain {
        max-width: 91.2%;
    }
    .customised_servc_ware {
        padding-right: 60px;
    }
}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {

    .customised_contain {
        max-width: 89.5%;
    }

    .customised_servc_ware {
        padding-right: 60px;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

    .customised_contain {
        width: 100%;
        max-width: 96%;
    }

    .cstm_item {
        height:580px;
    }

}

@media only screen and (min-device-width: 992px) and (max-device-width: 1024px) {

    .customised_contain {
        width: 100%;
        max-width: 100%;
    }
    .customised_slider .swiper-button-next {
        left: 65px;
    }
    .customised_slider {
        padding-bottom: 75px;
    }
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .customised_contain {
        max-width: 100%;
    }
    .customised_slider .swiper-button-next {
        left: 65px;
    }
    .customised_slider {
        padding-bottom: 75px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

    .customised_servc_ware {

        padding-top: 50px;
        padding-bottom: 50px;
       
    }
    .customised_slider .cstm_item {
        height:auto;
    }
    .customised_contain {
        width: 100%;
        max-width: 100%;
    }
    .cstm_item {
        padding: 20px;
    }
    .cstm_sld_hd {
        font-size: 20px;
    }
    .cstm_sld_list li {
        font-size: 14px;
        line-height: 21px;
    }
    .customised_slider .swiper-button-next {
        left: 57px;
    }
    .customised_slider {
        padding-bottom: 65px;
    }
}