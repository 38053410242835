.about_sec {
    padding-top: 127px;
    padding-bottom: 162px;
    position: relative;
}

.about_sec_col1 {
    width: 100%;
    max-width: 740px;
    margin: auto;
}

.about_hd_para {
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-transform: capitalize;
    color: #101021;
    font-family: 'oms';
}
.video_div {
    max-width: 865px;
    width: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;

}
.video_div video {
    width:100%;
}
.about_img {
    width: 100%;
    margin: 100px 0px;
}

.mdl_img {
    padding: 0 7px;
}

.about_sec_col2 {
    max-width: 866px;
    width: 100%;
    margin: auto;
}

.abt_col2_para1 {
    max-width: 376px;
    width: 100%;
    float: left;
    margin-right: 100px;
}

.abt_col2_para2 {
    max-width: 376px;
    width: 100%;
    float: left;
}

.about_sub_hd_para {
    margin-top: 57px;
    font-weight: 400;
    font-size: 32px;
    line-height: 43px;
    color: #101021;
    width: 100%;
    float: left;
}

.about_svg {
    position: absolute;
    left: auto;
    right: 85px;
    top: auto;
    bottom: 0;
}

.img_scroll_dv {
    position: relative;
    height: 400px;
    margin-top: 100px;
    overflow: hidden;
}

.inside_dv_img {
    width: max-content;
    position: absolute;
    top: 0;
}

img.scroll_img {
    margin-right: 20px;
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {

    .video_div{
        margin-bottom: 50px;
    }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .video_div{
        margin-bottom: 50px;
    }
}

@media only screen and (min-device-width: 1370px) and (max-device-width: 1440px) {}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1536px) {}

@media only screen and (min-device-width: 1537px) and (max-device-width: 1600px) {}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

    .about_sec {
        padding-bottom: 175px;
        padding-top: 80px;
    }

    .about_hd_para {
        font-size: 31px;
        line-height: 44px;
    }
    .img_scroll_dv {
        margin-top: 50px;
    }
    .abt_col2_para1,
    .abt_col2_para2 {
        max-width: 100%;
    }

    .about_sub_hd_para {
        margin-top: 40px;
        font-size: 27px;
        line-height: 42px;
    }

    .about_svg {
        right: 31px;
        width: 250px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .about_sec {
        padding-top: 50px;
        padding-bottom: 160px;
    }

    .about_hd_para {
        font-size: 21px;
        line-height: 32px;
    }

    .about_img {
        margin: 30px 0;
    }

    .about_sub_hd_para {
        margin-top: 20px;
        font-size: 20px;
        line-height: 34px;
    }

    .img_scroll_dv {
        height: 365px;
    }

    .about_svg {
        right: 20px;
        width: 42%;
    }

    .img_scroll_dv.abt_scroll {
        margin-top: 0px;
    }

    .video_div {
        margin:20px 0px 0px;
    }
}